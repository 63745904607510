.notifications {
  border-radius: 10px 10px 8px 8px;
  box-shadow: $shadow-big;
  background: $neutral-0;
  max-width: 400px;

  &.popover {
    @include media-breakpoint-down(sm) {
      transform: none !important;
      left: 0 !important;
      right: 0 !important;
      height: 100dvh !important;
      max-width: 100vw;
      top: 0;
      position: fixed !important;
    }
  }

  &-header {
    @include flexbox(row,center,space-between);
    padding: $padding-default;
  }

  &-list {
    border-radius: 0 0 $border-radius-lg $border-radius-lg;
    padding: 0 0 $padding-default;
    height: calc(80vh - 60px);
    overflow: auto;
    width: 400px;

    @include media-breakpoint-down(sm) {
      height: calc(100dvh - 68px);
      max-width: 100vw;
      width: 100vw;
    }
  }

  &-order {
    .card-notification {
      width: 100%;
      order: 2;

      &.unread {
        order: 0;

        ~ .txt-hint.new {
          display: block;
        }
      }
    }

    .txt-hint {
      &.new {
        display: none;
        order: -1;
      }
      &.earlier {
        order: 1;
      }
    }
  }
}
