.toasted-container {
  &.top-right {
    right: 16px;
    top: 60px;
  }
  &.bottom-right {
    align-items: flex-end;
    right: 16px !important;
    bottom: 16px !important;
  }
  &.bottom-center {
    @media only screen and (max-width: 600px) { padding: 12px;}
  }
}


.toasted.searchie-toast {
  padding: 20px 56px 16px 46px !important;

  // Sizes
  &-small { max-width: 380px !important; width: 100% !important;}
  &-bottom { padding: $padding-sm 0 0; margin-left: -27px; @include flexbox(row,initial,initial);}

  // Templates
  &.rounded { border-radius: 24px;}

  // Bubble - Searchie
  &.bubble {
    @include flexbox(row,initial,initial);
    background-color: $neutral-6;
    box-shadow: $shadow-tooltip;
    border-radius: $border-radius-lg;
    font-weight: 400 !important;
    font-size: $txt-size-1;
    line-height: 1.58;
    color: $neutral-1;
    min-height: 64px;
    flex-wrap: wrap;

    b { margin: 0 6px;}
    .txt-body { width: 100%;}

    &.default {
      background: $neutral-6;
      .icon {
        background-image: url($assetUrl + '/images/toast/icon-default.svg');
      }
    }

    &.success {
      background: $neutral-6;
      .icon {
        background-image: url($assetUrl + '/images/toast/icon-success.svg');
      }
    }

    &.error {
      background: $neutral-6;
      .icon {
        background-image: url($assetUrl + '/images/toast/icon-error.svg');
      }
    }

    &.info {
      background: $neutral-6;
      .icon {
        background-image: url($assetUrl + '/images/toast/icon-info.svg');
      }
    }

    &.warning {
      background: $neutral-6;
      .icon {
        background-image: url($assetUrl + '/images/toast/icon-warning.svg');
      }
    }

    .action {
      text-decoration: none !important;
      text-transform: capitalize;
      margin: 0 $margin-lg 0 0;
      font-size: $txt-size-0;
      font-family: Circular;
      display: inline-block;
      padding: $padding 0;
      color: $neutral-0;
      font-weight: 700;

      &:not(.close):first-of-type { margin-top: auto;}

      @include transition(.15s);
      filter: brightness(1);

      @include hover() { filter: brightness(.8);}

      &.close {
        background: url($assetUrl + '/images/toast/icon-close.svg') no-repeat center;
        background-color: transparent;
        border-radius: $border-radius-sm;
        right: $padding-default;
        top: $padding-default;
        display: inline-block;
        position: absolute;
        font-size: 0;
        height: 32px;
        width: 32px;
        margin: 0;

        @include hover() { background-color: rgba(255,255,255,.2);}
      }
    }

    .icon {
      background-repeat: no-repeat;
      background-position: center;
      margin-right: $margin;
      border-radius: 50%;
      position: absolute;
      min-width: 19px;
      height: 19px;
      width: 19px;
      left: 19px;
      top: 22px;
    }
  }
}
