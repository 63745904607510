// Base class


//
// Tabs
//
// Tabs first variant ============================ //

.nav-tabs {
  @include flexbox(row,center,flex-start);
  border-radius: $border-radius-lg;
  background: $neutral-1;
  padding: 6px;

  .form-control-tabs-small & { border-radius: 18px;}

  .nav-item {
    flex: 1 0 0;
  }

  .nav-link {
    min-height: 48px;
    @include flexbox(row,center,center);
    border-radius: $border-radius-md;
    @include transition(.15s);
    --corner-smoothing: 60%;
    text-align: center;
    color: $neutral-6;
    font-weight: 700;
    font-size: 1.4rem;
    margin: -1px;
    padding: 0 $padding-md;
    cursor: pointer;

    .form-control-tabs-small & { min-height: 36px; border-radius: $border-radius-lg;}

    @include hover() {
      color: $primary-3;
    }

    &.disabled { pointer-events: none; color: $neutral-3;}

    > span { font-weight: 700; }

    &.active {
      box-shadow: $shadow-small;
      background: $neutral-0;
      color: $primary-3;

      &.disabled {
        background: $neutral-2;
        pointer-events: none;
        color: $neutral-3;
        box-shadow: none;
        .btn-left { color: inherit;}
      }

      .btn-left {
        color: $primary-4;
        pointer-events: none;
        transform: translateX(0);
        margin-right: 8px;
        opacity: 1;
      }
    }

    .btn-left {
      transition: opacity .2s linear, margin-right .2s linear, transform .3s cubic-bezier(0.170, 0.895, 0.295, 1.850);
      transform: translateX(70%);
      margin-right: -24px;
      opacity: 0;
    }

    .btn-left:not(:only-child) {
      margin-right: 0;
    }

    .btn-right:not(:only-child) {
      margin-left: 0;
    }

    //.btn-left + span {
    //  margin-left: $margin;
    //}
    //span + .btn-right {
    //  margin-left: $margin;
    //}
  }
}


// Tabs second variant ============================ //

.nav-tabs-lined {
  @include flexbox(row,initial,initial);
  //border-bottom: 1px solid $neutral-2;
  position: relative;

  .btn-tab-nav {
    position: relative;
    color: $neutral-6;
    font-weight: 700;
    flex: 1 0 0;

    @include hover() { color: $primary-3;}

    &.disabled {
      pointer-events: none;
      color: $neutral-3;
    }

    &.active {
      color: $primary-3;

      @for $tabCountB from 1 through 20 {

        &:nth-child(#{$tabCountB}) {
          ~ .nav-tabs-line {
            @for $tabLineIndex from $tabCountB through 20 {
              $widthB: percentage(1 / ($tabLineIndex - 1));

              &:nth-child(#{$tabLineIndex}) { left: $widthB * ($tabCountB - 1); width: $widthB;}
            }
          }
        }
      }

      &.disabled {
        color: $neutral-3;
        ~ .nav-tabs-line { background: $neutral-3;}
      }

      .btn-left {
        pointer-events: none;
        transform: translateX(0);
        margin-right: 8px;
        opacity: 1;
      }
    }

    .btn-left {
      transition: opacity .2s linear, margin-right .2s linear, transform .3s cubic-bezier(0.170, 0.895, 0.295, 1.850);
      transform: translateX(70%);
      margin-right: -24px;
      opacity: 0;
    }

    &-count {
      margin-left: 10px;
      color: $neutral-4;
      font-weight: 700;
      border-radius: $border-radius-md;
      padding: 0 5px;
      line-height: 22px;
      background: rgba(#000,.05);
    }
  }

  .nav-tabs-line {
    z-index: 1;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: all .5s cubic-bezier(0.705, -0.200, 0.125, 1.210);;
    border-radius: 3px 3px 0 0;
    background: $primary-3;
    height: 3px;
    --corner-smoothing: 60%;
    overflow: hidden;

    @for $tabCount from 2 through 20 {
      $width: 1 / ($tabCount - 1);
      &:nth-child(#{$tabCount}) { width: percentage($width);}
    }
  }
}

// Tabs third variant (cards) ============================ //

.nav-tabs-cards {
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  column-gap: $padding;
  background: none;
  display: grid;
  padding: 0;

  .nav-item { height: 100%;}

  .nav-link {
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.03), 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
    border-radius: $border-radius-lg;
    justify-content: flex-start;
    align-items: flex-start;
    padding: $padding-default;
    position: relative;
    color: $neutral-6;
    font-weight: 700;
    line-height: 1.6;
    text-align: left;
    height: 100%;
    flex: 1 0 0;

    @include hover() { color: $primary-3;}

    &.disabled {
      pointer-events: none;
      color: $neutral-3;
    }

    &.active {
      box-shadow: 0 0 0 rgba(0,0,0,0);
      background: $primary-1;
      color: $primary-4;

      @include hover() { color: $primary-3;}

      &.disabled {
        color: $neutral-3;
      }
    }
  }
}

// Tabbable tabs
//
// Hide tabbable panes to start, show them when `.active`

.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}

.no-tab-buttons {
  display: none !important;
}
