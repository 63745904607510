@mixin scrollbar-none {
  &::-webkit-scrollbar { display: none;}
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-text-size-adjust: none;
}

@mixin scrollbar-thin {
  &::-webkit-scrollbar-track {
  	border-radius: 0;
  	background-color: rgba(#F0F0F0,0);
  }
  &::-webkit-scrollbar {
  	width: 6px;
  	background-color: rgba(#F0F0F0,0);
    display: block;
  }
  &::-webkit-scrollbar-thumb {
  	border-radius: 0;
    background-color: rgba(#939393,.3);
  }
  scrollbar-color: #dedede #F0F0F0 !important;
  scrollbar-width: thin !important;
  -webkit-text-size-adjust: initial;
}

@mixin scrollbar-fat {
  &::-webkit-scrollbar {
    width: 15px;
    display: block;
  }
  &::-webkit-scrollbar-track {
    background-color: #ffffff;
    border-radius: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(#939393,.5);
    border: 3px solid #ffffff;
    border-radius: 10px;
  }
  scrollbar-width: auto;
  scrollbar-color: #AFC3B5 #ffffff;
}
