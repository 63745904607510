
$thumb-radius: 9px !default;
$thumb-height: 16px !default;
$thumb-width: 16px !default;
$thumb-border-width: 5px !default;

$track-width: 100% !default;
$track-height: 4px !default;
$track-border-width: 0 !default;
$track-border-color: #cfd8dc !default;

$track-radius: 3px !default;
$contrast: 5% !default;

$ie-bottom-track-color: darken($neutral-2, $contrast) !default;

@mixin track {
	cursor: default;
	height: $track-height;
	transition: all .2s ease;
	width: $track-width;
}
@mixin thumb {
	background: $primary-3;
	border: $thumb-border-width solid $primary-3;
	border-radius: $thumb-radius;
  @include transition(.15s);
	box-sizing: border-box;
	cursor: default;
	height: $thumb-height;
	width: $thumb-width;
}

.custom-range {
	-webkit-appearance: none;
	background: transparent;
	margin: $thumb-height / 2 0;
	width: $track-width;

	&:focus {
		border: none !important;
		box-shadow: none !important;
	}

	&::-moz-focus-outer {
		border: 0;
	}

	&:focus {
		outline: 0;

		&::-webkit-slider-runnable-track {
			background: lighten($neutral-2, $contrast);
		}

		&::-ms-fill-lower {
			background: $neutral-2;
		}

		&::-ms-fill-upper {
			background: lighten($neutral-2, $contrast);
		}
	}

	&::-webkit-slider-runnable-track {
		@include track;
		background: $neutral-2;
		border: $track-border-width solid $track-border-color;
		border-radius: $track-radius;
	}

	&::-webkit-slider-thumb {
		display: block;
		overflow: visible;
		@include thumb;
		-webkit-appearance: none;
		margin-top: ((-$track-border-width * 2 + $track-height) / 2 - $thumb-height / 2);
	}

	//&::-webkit-slider-thumb:before {
	//	position: absolute;
	//	content: '';
	//	height: 6px;
	//	width: 500px; /* make this bigger than the widest range input element */
	//	left: -502px; /* this should be -2px - width */
	//	top: 0; /* don't change this */
	//	background: #777;
	//}

	&::-moz-range-track {
		@include track;
		background: $neutral-2;
		border: $track-border-width solid $track-border-color;
		border-radius: $track-radius;
		height: $track-height / 2;
    perspective: 1px;
	}

	&::-moz-range-thumb {
		@include thumb;
	}

	&::-ms-track {
		@include track;
		background: transparent;
		border-color: transparent;
		border-width: ($thumb-height / 2) 0;
		color: transparent;
	}

	&::-ms-fill-lower {
		background: $ie-bottom-track-color;
		border: $track-border-width solid $track-border-color;
		border-radius: ($track-radius * 2);
	}

	&::-ms-fill-upper {
		background: $neutral-2;
		border: $track-border-width solid $track-border-color;
		border-radius: ($track-radius * 2);
	}

	&::-ms-thumb {
		@include thumb;
		margin-top: $track-height / 4;
	}

	&:disabled {
    pointer-events: none;
		&::-webkit-slider-thumb,
		&::-moz-range-thumb,
		&::-ms-thumb,
		&::-webkit-slider-runnable-track,
		&::-ms-fill-lower,
		&::-ms-fill-upper {
			cursor: not-allowed;
		}
    &::-ms-track { background: $neutral-1;}
    &::-webkit-slider-runnable-track { background: $neutral-1;}
    &::-moz-range-track { background: $neutral-1;}
    &::-ms-thumb { background: $neutral-2; border-color: $neutral-2;}
    &::-webkit-slider-thumb { background: $neutral-2; border-color: $neutral-2;}
    &::-moz-range-thumb { background: $neutral-2; border-color: $neutral-2;}

    ~ .form-range-before { background: $neutral-2;}
	}
	&:hover {
    cursor: pointer;
		&::-webkit-slider-thumb,
		&::-moz-range-thumb,
		&::-ms-thumb,
		&::-webkit-slider-runnable-track,
		&::-ms-fill-lower,
		&::-ms-fill-upper {
			cursor: not-allowed;
		}
    ~ .form-range-before {
      background: $primary-4;
    }
    &::-ms-thumb {
      background: $primary-4;
    	border: $thumb-border-width solid $primary-4;
      box-shadow: $shadow-hover;
    }
    &::-webkit-slider-thumb {
      background: $primary-4;
    	border: $thumb-border-width solid $primary-4;
      box-shadow: $shadow-hover;
    }
    &::-moz-range-thumb {
      background: $primary-4;
    	border: $thumb-border-width solid $primary-4;
      box-shadow: $shadow-hover;
    }
	}
	&.vertical {
    transform: translateX(50%) rotate(-90deg);
    transform-origin: left;
	}
}

.form-control {
  &-range {
		display: flex;
		position: relative;
    -webkit-appearance: none;
  	background: transparent;
  	margin: $thumb-height / 2 0;
  	width: $track-width;

    input {
      width: 100%;
    }

  	&:focus {
  		border: none !important;
  		box-shadow: none !important;
  	}

		.form-range-before {
      @include transition(.15s);
			position: absolute;
			top: 8px;
			height: 4px;
			border-radius: 2px;
			background: $primary-3;
			pointer-events: none;
      perspective: 1px;
			left: 0;
		}

    &.disabled {
      pointer-events: none;
      .custom-range {
        &::-ms-track { background: $neutral-1;}
        &::-webkit-slider-runnable-track { background: $neutral-1;}
        &::-moz-range-track { background: $neutral-1;}
        &::-ms-thumb { background: $neutral-2; border-color: $neutral-2;}
        &::-webkit-slider-thumb { background: $neutral-2; border-color: $neutral-2;}
        &::-moz-range-thumb { background: $neutral-2; border-color: $neutral-2;}
      }
      .form-range-before { background: $neutral-2;}
    }
	}
}
.form-range {
  position: relative;

  &-top {
    @include flexbox(row,center,space-between);
    width: 100%;
  }

  .form-range-value {
    @include inline-flexbox(row,center,center);
    border-radius: $border-radius-md;
    border: 1px solid $neutral-2;
    font-family: $app-v2-font;
    font-size: $txt-size-0;
    text-align: center;
    height: 32px;
    width: 50px;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&[type=number] {
			-moz-appearance: textfield;
		}

    &.disabled,&[disabled] {
      border-color: $neutral-2;
      background: $neutral-1;
      pointer-events: none;
      color: $neutral-3;
    }
  }
}
