.card-attribute {
  @include flexbox(column,initial,initial);
  border-radius: $border-radius-lg;
  box-shadow: $shadow-small;
  @include transition(.15s);
  background: $neutral-0;
  min-height: 128px;
  cursor: pointer;
  padding: 12px;
  width: 100%;

  .skeleton-item {
    min-height: 128px;
  }

  .slot-top {
    @include flexbox(row,flex-start,space-between);

    .slot-left {
      margin-right: 60px;
      overflow: hidden;

      .txt-heading-small {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-bottom: 0;
      }
    }

    .slot-right {
      display: inline-flex;
    }
  }

  .slot-bottom {
    @include flexbox(row,center,space-between);
    margin-bottom: -4px;
    color: $neutral-3;

    .handle {
      margin-left: -7px;
      cursor: grab;

      @include hover() { color: $primary-4;}
    }

    hr.vertical { @include transition(.15s);}
  }

  .txt-body {
    margin-right: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-inline-box;
    -webkit-line-clamp: 2;
            line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    margin-bottom: 0;
  }

  &:hover, &.selected {
    box-shadow: $shadow-big;
    background: $primary-1;
    color: $primary-3;
    .slot-top {
      .slot-right {
        opacity: 1;
      }
    }
    .txt-body {
      color: $primary-3;
    }
    .slot-bottom {
      color: $primary-3;
      hr.vertical { border-color: $primary-4; opacity: .1;}
    }
  }
}
