.iframe {

  &-holder {
    border-radius: $border-radius-lg;
    backface-visibility: hidden;
    padding-bottom: 56.25%;
    position: relative;
    overflow: hidden;
    width: 100%;

    .side-panel-body & { perspective: 1px;}

    > iframe {
      @include transition(.15s);
      position: absolute;
      height: 100%;
      width: 100%;
      opacity: 1;
      left: 0;
      top: 0;

      @at-root .iframe-holder.loading > iframe { opacity: 0;}
    }

    &-load {
      background: $neutral-3;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;

      &:before {
        content:'';
        background-image: linear-gradient(90deg, rgba($neutral-1,0) 0px, rgba($neutral-1,.5) 80px, rgba($neutral-1,.5) 120px, rgba($neutral-1,0) 200px);
        position: absolute;
        background-repeat: repeat-x;
        left: -500px;
        width: 200px;
        height: 100%;
        animation: shine-wide 3s infinite;
      }
    }
  }
}

@keyframes shine-wide {
  0% {
    left: -(400px + 200px);
  }
  100% {
    left: 4 * 200px;
  }
}
