.top-sticky-blur {
  background: rgba(255, 255, 255, 1);
  //backdrop-filter: blur(8px);
  position: sticky;
  z-index: 5;
  top: 0;

  &-neutral { background: $neutral-1;}
  &-neutral-2 { background: $neutral-0-5;}
  &-neutral-4 { background: $neutral-6; hr { background: $neutral-0; opacity: .1;}}

  &:after {
    content: "";
    background: $neutral-2;
    pointer-events: none;
    position: absolute;
    opacity: 1;
    width: 100%;
    height: 1px;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .main > & {
    &:first-child {
      margin: -$margin-default (-$margin-default) 0;
      padding: $padding-default;
    }    
  }
  .app:has( > .top-bar) & {
    top: 60px;
    &.library-top { top: 0;}
    &.library-top { top: 0;}
    &:has(.library-accordion-header) { top: 0;}
  }
}