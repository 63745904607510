.input-group-tags {
  @include flexbox(row,flex-start,flex-start);
  padding: 12px 12px 8px 12px;
  flex-wrap: wrap;
  height: auto;

  .form-control {
    padding: 0 $padding;
    flex: auto 0 0;
    min-width: 0;
    height: 32px;
    width: auto;
  }
}
