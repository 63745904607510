.app {
  @include flexbox(row,flex-start,flex-start);
  padding-left: 64px;
  min-width: 1200px;

  &.auth-view { padding-left: 0; min-width: 0;}

  &.no-min-width {
    @include media-breakpoint-down(sm) {
      padding-left: 0;
      min-width: 0;
    }
  }
}

.main {
  background: $neutral-1;
  min-height: 100vh;
  width: 100%;

  &.with-topbar {
    min-height: calc(100vh - 60px);
    margin-top: 60px;

    .side-panel,
    .layout-split-right,
    .audience-header,
    .stats-panel { top: 60px;}

    .table-bulk { top: 148px;}

    .layout-dashboard { min-height: calc(100vh - 60px);}
  }
}

.layout {

  &-dashboard {
    @include flexbox(row,stretch,flex-start);
    max-width: calc(100vw - 64px);
    padding: 0 0 0 350px;
    min-height: 100vh;
  }

  &-split {

    .side-panel-left {
      transform: translateX(-100%);
      @include transition(.2s);
      visibility: hidden;
      opacity: 0;

      &.active {
        transform: translateX(0);
        visibility: visible;
        opacity: 1;

        ~ .layout-split-right { left: 414px;}
        //~ .hub-editor-preview { left: 414px;}
      }
    }

    &-right {
      @include transition(.2s);
      overflow: auto;
      position: fixed;
      bottom: 0;
      left: 64px;
      right: 0;
      top: 0;

      .main.with-topbar & { z-index: 1039;}
    }
  }
}

// Wrappers

.wrapper-md { max-width: 1072px; margin: 0 auto; padding-left: $padding-default; padding-right: $padding-default;}
.wrapper-lg { max-width: 1432px; margin: 0 auto; padding-left: $padding-default; padding-right: $padding-default;}
// .wrapper-xl {}

.inner-wrap { max-width: 600px;}
