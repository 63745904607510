// Sidepanel file editor / extension of .side-panel

&-file {

  &-thumbnail {
    border-radius: $border-radius-lg;
    position: relative;
    overflow: hidden;
    height: 284px;
    width: 100%;

    .thumbnail {
      border-radius: $border-radius-lg;
      object-position: center;
      pointer-events: none;
      object-fit: cover;
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 0;
      top: 0;
      left: 0;

      &-default {
        @include flexbox(row,center,center);
        border-radius: $border-radius-lg;
        background: $neutral-2;
        color: $neutral-3;
        pointer-events: none;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
      }
    }
  }
}
