.card-sitemap {
  padding: 8px;
  border: 1px solid #D7E0D8;
  border-radius: $border-radius-lg;
  
  &-content {
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
  }
}
// fix bg on input validator
.form-control-ghost.no-background {
  background: transparent;
}