&-group {
  margin-bottom: $margin-default;

  &-header {
    @include flexbox(row,center,space-between);
    margin: 0 0 $margin-default;
    min-height: 60px;

    [class*='txt'] { margin: 0;}

    > .txt-heading-small { order: -1;}

    .widget-search {
      overflow: hidden;
      max-width: 50px;
      flex: 50px 0 0;
      width: auto;
      >input { display: none;}

      &.expanded {
        flex: 1 0 0;
        width: auto;
        max-width: 100%;

        .account-settings[data-title="Data"] &, .account-settings[data-title="Team"] & {
          border: 1px solid $neutral-2;
          background: $neutral-1;
          box-shadow: none;
          max-width: 400px;
          ~ .txt-heading-small { display: block;}
        }

        > input { display: block;}
        ~ .txt-heading-small { display: none;}
      }
    }

    &-sm {
      @include flexbox(row,center,space-between);
      margin: 0 0 $margin-default;
      min-height: 48px;

      [class*='txt'] { margin: 0;}

      > .txt-heading-small { order: -1;}

      .side-panel-body &:first-child { margin-top: -8px;}
    }
  }

  &-empty {
    @include flexbox(column,center,center);
    height: calc(100vh - 300px);

    p {
      text-align: center;
    }
  }
  &-footer {
    @include flexbox(row,center,space-between);
    margin: 0 0 $margin-default;
    min-height: 32px;

    [class*='txt'] { margin: 0;}
  }
}
