&-plans {

  &-item {
    @include flexbox(row,initial,space-between);
    border-bottom: 1px solid $neutral-2;
    padding: 12px $padding-default;
    background: $neutral-0;
    min-height: 80px;
    transition: .2s;

    &:first-child:not(:only-child) { border-radius: $border-radius-lg $border-radius-lg 0 0;}
    &:last-child:not(:only-child) { border-bottom: none; border-radius: 0 0 $border-radius-lg $border-radius-lg;}
    .collapse & { border-radius: 0;}

    .draggable-list-item-handle {
      @include inline-flexbox(row,center,center);
      margin: auto 0 auto -12px;
      color: $neutral-3;
      cursor: grab;
      > .btn-left { margin-right: 0;}
    }

    .draggable-list-item-content {
      @include flexbox(row,initial,space-between);
      flex: 1 0 0;
    }

    .slot-left {
      @include flexbox(column,flex-start,initial);
      padding: 0;
    }

    .slot-right {
      @include flexbox(column,flex-end,initial);
      padding: 0;

      .btn.dropdown-toggle,
      .btn.popover-toggle,
      .btn.remove-price {
        margin: -4px -8px 8px 8px;
        @media (hover: hover) { opacity: 0;}
      }
    }

    @include hover() {
      &:not(.hidden) {
        background: $primary-1;
        color: $primary-3;
        .draggable-list-item-handle { color: $primary-3;}
        .txt-muted { color: $primary-3 !important;}
        .slot-right {
          .btn.dropdown-toggle,
          .btn.popover-toggle { opacity: 1;}
        }  
      }

      .slot-right {
        .btn.remove-price {
          @media (hover: hover) { opacity: 1;}
        }
      }
    }

    + .collapse {
      .side-panel-plans-item:first-child {
        position: relative;
        &:before {
          content: "";
          pointer-events: none;
          box-shadow: $shadow-small;
          position: absolute;
          height: 20px;
          width: 100%;
          top: -20px;
          left: 0;
        }
      }
    }
  }

  .price-selector-collapse {
    display: block;
    margin: auto 0;
    width: 100%;

    &-header {
      @include flexbox(row,center,initial);
      padding: 10px 0;
    }
  }
}
