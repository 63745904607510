html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, main, aside, article {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
  font-weight: inherit;

  @include scrollbar-none;
}
input, textarea, button {
  box-sizing: border-box;
  border-radius: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
}
input, textarea {
  &:-webkit-autofill {
    //transition-delay: 600s !important;
	}
  
}
a, input, textarea, button {
  &:active, &:focus {
    outline: none;
	}
}
input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar-track {
	border-radius: 5px;
	background-color: rgba(#F0F0F0,0);
}
::-webkit-scrollbar {
	width: 6px;
	background-color: rgba(#F0F0F0,0);
}
::-webkit-scrollbar-thumb {
	border-radius: 10px;
  background-color: rgba(#939393,.3);
}

button {
  font-family: var(--app-font);
  cursor: pointer;
  padding: 0;
  margin: 0;
}

strong, b {
  font-weight: 700;
}
u {
  text-decoration: underline;
}
em {
  font-style: italic;
}
hr {
  border: none;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a, button {
  text-decoration: none;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
	cursor: pointer;
}
