.skeleton {
  &-item {
    border-radius: $border-radius-lg;
    background: #D7E0D8 !important;
    position: relative;
    text-align: left;
    overflow: hidden;

    &:before {
      content:'';
      transform: skewX(-45deg);
      background-image: linear-gradient(90deg, rgba(#D7E0D8,0) 0px, rgba(#D7E0D8,.6) 80px, rgba(#D7E0D8,.6) 120px, rgba(#D7E0D8,0) 200px);
      position: absolute;
      background-repeat: repeat-x;
      left: -400px;
      width: 200px;
      height: 100%;
      animation: shine 3s infinite;
    }

    &-light {
      background: $neutral-0 !important;

      &:before {
        background-image: linear-gradient(90deg, rgba(#fff,0) 0px, rgba(#fff,.6) 80px, rgba(#fff,.6) 120px, rgba(#fff,0) 200px);
      }
    }

    &-dark {
      background: $neutral-6 !important;

      &:before {
        background-image: linear-gradient(90deg, rgba(#000,0) 0px, rgba(#000,.6) 70px, rgba(#000,.6) 130px, rgba(#000,0) 200px);
        transform: none;
      }
    }

    // Shine sizes
    &-lg { &:before { animation: shineLg 3s infinite; opacity: .5;}}

    &.card-file-figure {
      background: #D7E0D8;
      display: flex;
      &:before { display: none;}
    }
    &.card-file-info {
      border-radius: 0 0 $border-radius-lg $border-radius-lg;
      background: #fff !important;
      padding: 0 !important;
      display: flex;

      &:before {
        background-image: linear-gradient(90deg, rgba(#fff,0) 0px, rgba(#fff,.6) 80px, rgba(#fff,.6) 120px, rgba(#fff,0) 200px);
      }
    }
  }
}
