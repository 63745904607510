.user-avatar {
  position: relative;
  @include flexbox(initial, center, center);
  margin: 0 auto;

  &.size-xl {
    height: 148px;
    width: 148px;
  }
  &.size-md {
    height: 124px!important;
    width: 124px!important;
    .b-avatar {
      height: 124px!important;
      width: 124px!important;
      &:hover {
        box-shadow: none;
        background: rgba($neutral-6,.05);
      }
    }
  }

  img {
    box-shadow: inset 0 0 0 1px rgba($neutral-4,.1);
    border-radius: 40px;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .b-avatar {
    box-shadow: inset 0 0 0 1px rgba($neutral-4,.1);
    background: rgba($neutral-6,.05);
    border-radius: 40px;
    max-height: 148px;
    max-width: 148px;
    overflow: hidden;
    height: 148px;
    width: 148px;

    svg {
      width: 100%;
      height: auto;
    }

    &:hover {
      box-shadow: inset 0 0 0 1px rgba($neutral-4,.1), 0px 0px 0px 4px rgba(#3265EB, .2);
      background-color: $neutral-2;
    }
  }
  &-edit {
    @include flexbox(initial, center, center);
    @include transition(.15s);
    border-radius: 50%;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    left: 0;
    top: 0;

    input {
      cursor: pointer;
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    &-icon {
      @include flexbox(initial, center, center);
      border-radius: $border-radius-md;
      pointer-events: none;
      background: $primary-3;
      position: absolute;
      color: $neutral-0;
      cursor: pointer;
      padding: 6px;
      right: 0;
      top: 0;
    }

    .size-xl > & {
      border-radius: 40px;
    }
  }
  &:hover {

    .user-avatar-edit {
      box-shadow: $shadow-hover-light;
      color: $neutral-0;
      opacity: 1;
    }
    .size-md > & {
      border-radius: none;
    }
  }
}
