.webhooks-grid {
  .ag-webhook-secret {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: copy;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      filter: blur(3px);
      -webkit-filter: blur(3px);
      transition: all .2s linear;
    }
    &:hover {
      span {
        filter: none;
        -webkit-filter: none;
      }
    }
  }
  .ag-webhook-url {
    display: flex;
  }
  .ag-webhook-status {
    transform: translateY(-12px);
  }
}

.webhook-headers {
  table {
    width: 100%;
    th, td {
      padding-right: $padding;
      &:last-of-type {
        width: 50px;
        padding-right: $padding-lg;
      }
      &:first-of-type {
        padding-left: $padding-lg;
      }
    }
    th {
      height: 48px;
      vertical-align: middle;
      &:first-of-type {
        margin-right: $margin-default;
        display: flex;
        align-items: center;
      }
    }
    .sr-only {
      display: none;
    }
    thead {
      tr {
        border-bottom: 1px solid $neutral-2;
        th {
          color: $neutral-3;
          &[aria-sort="ascending"], &[aria-sort="descending"] {
            color: $neutral-4;
          }
        }
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid $neutral-2;
        padding: 0 $padding-lg;
        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }
}