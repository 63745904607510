@mixin flexbox($direction: initial, $align: initial, $justify: initial) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
}

@mixin inline-flexbox($direction: initial, $align: initial, $justify: initial) {
  display: inline-flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
}
