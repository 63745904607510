/*
  <b-btn variant="primary" size="sm">
    <svg viewBox="0 0 24 24" class="btn-left"><use xlink:href="#icon-chevron-left"></use></svg>
    Button text
    <svg viewBox="0 0 24 24" class="btn-right"><use xlink:href="#icon-chevron-right"></use></svg>
  </b-btn>

  Variants: primary, secondary, link
  Sizes: 'default' (no size prop), sm, lg
  Icons Left: if icon goes on the left side make sure it is before text and has class .btn-left
  Icons Right: if icon goes on the right side make sure it is after text and has class .btn-right

*/

.btn {
  @include inline-flexbox(row,center,center);
  border-radius: $border-radius-lg;
  box-shadow: 0 0 0 0 transparent;
  background-color: transparent;
  border: 1px solid transparent;
  font-family: $app-v2-font;
  @include transition(.15s);
  vertical-align: middle;
  --smooth-corners: 60%;
  text-decoration: none;
  white-space: nowrap;
  text-align: center;
  user-select: none;
  font-size: 1.4rem;
  padding: 8px 13px;
  color: $neutral-6;
  font-weight: 700;
  line-height: 1.6;
  min-height: 48px;
  cursor: pointer;

  @include hover() {
    text-decoration: none;
    color: $neutral-6;
  }

  &:focus,
  &.focus {
    box-shadow: none;
    outline: 0;
  }

  &.disabled,
  &:disabled {
    pointer-events: none;
    color: $neutral-3;
    cursor: default;
  }

  // Button icons =============/

  &-left {
    margin-right: 8px;
    height: auto;
    width: 24px;
  }

  &-right {
    margin-left: 8px;
    height: auto;
    width: 24px;
  }

  > svg:only-child:not(.btn-left):not(.btn-right) { margin: 0 -1px;}
}


// Button variants ( primary, secondary, warning, danger, empty-dl (dl=delete)

.btn {
  &-primary {
    border-color: $primary-3;
    background: $primary-3;
    color: $primary-2;

    @include hover() {
      box-shadow: $shadow-hover;
      border-color: $primary-4;
      background: $primary-4;
      color: $primary-2;
    }

    &.disabled,
    &:disabled {
      border-color: $neutral-2;
      background: $neutral-2;
      color: $neutral-3;

      &.loading { color: $neutral-4;}
    }

    &-dark {
      border-color: $neutral-6;
      background: $neutral-6;
      color: #fff;

      @include hover() {
        box-shadow: $shadow-hover-dark;
        border-color: $neutral-0;
        background: $neutral-0;
        color: $neutral-6;
      }

      &.disabled,
      &:disabled {
        border-color: $neutral-2;
        background: $neutral-2;
        color: $neutral-3;

        &.loading { color: $neutral-4;}
      }
    }

    &-white {
      border-color: $neutral-0;
      background: $neutral-0;
      color: $neutral-6;

      @include hover() {
        box-shadow: $shadow-hover-light;
        border-color: $primary-1;
        background: $primary-1;
        color: $neutral-6;
      }

      &.disabled,
      &:disabled {
        border-color: $neutral-2;
        background: $neutral-2;
        color: $neutral-3;

        &.loading { color: $neutral-4;}
      }
    }

    &-success {
      border-color: $green-3;
      background: $green-3;
      color: #fff;

      @include hover() {
        box-shadow: $shadow-hover-success;
        border-color: $green-4;
        background: $green-4;
        color: $neutral-0;
      }

      &.disabled,
      &:disabled {
        border-color: $neutral-2;
        background: $neutral-2;
        color: $neutral-3;

        &.loading { color: $neutral-4;}
      }
    }

    &-success-outlined {
      border-color: $green-3;
      background: $green-1;
      color: $green-3;

      @include hover() {
        box-shadow: $shadow-hover-success;
        background: $neutral-0;
        color: $green-3;
      }
    }
  }

  &-secondary {
    border-color: $primary-2;
    background: $primary-1;
    color: $primary-3;

    @include hover() {
      box-shadow: $shadow-hover;
      border-color: $primary-4;
      background: $primary-2;
      color: $primary-3;
    }

    &.disabled,
    &:disabled {
      border-color: $neutral-3;
      background: rgba($neutral-0,.1);
      color: $neutral-3;
    }
  }

  &-warning {
    border-color: $red-2;
    background: $red-1;
    color: $red-3;

    @include hover() {
      box-shadow: $shadow-hover-red;
      border-color: $red-3;
      background: $neutral-0;
      color: $red-3;
    }

    &.disabled,
    &:disabled {
      border-color: $neutral-3;
      background: $neutral-2;
      color: $neutral-3;
    }
  }

  &-orange {
    border-color: transparent;
    box-shadow: $shadow-small;
    color: $red-4;
    background: $orange-1;

    @include hover() {
      border-color: transparent;
      background: $orange-2;
      color: $red-4;
    }

    &.disabled,
    &:disabled {
      box-shadow: none;
      border-color: $neutral-2;
      background: $neutral-2;
      color: $neutral-3;
    }
  }

  &-danger {
    border-color: $red-3;
    background: $red-3;
    color: #fff;

    @include hover() {
      box-shadow: $shadow-hover-red;
      border-color: $red-4;
      background: $red-4;
      color: #fff;
    }

    &.disabled,
    &:disabled {
      border-color: $neutral-2;
      background: $neutral-2;
      color: $neutral-3;
    }
  }

  &-light {
    box-shadow: $shadow-small;
    border-color: $neutral-0;
    background: $neutral-0;
    color: $primary-3;

    @include hover() {
      box-shadow: none;
      border-color: $primary-1;
      background: $primary-1;
      color: $primary-3;
    }

    &.disabled,
    &:disabled {
      box-shadow: none;
      border-color: $neutral-0;
      background: $neutral-0;
      color: $neutral-3;
    }
  }
  &-icon-light {
    background: rgba(0, 0, 0, 0.5);
    opacity: 0.9;
    backdrop-filter: blur(20px);
    color: $neutral-0;
  }

  // Flat buttons (without shadow)
  &-platform {

    &-primary {
      background: transparent;
      box-shadow: none;
      color: $primary-3;
      border: none;

      @include hover() {
        background: rgba(#568C0B,.2);
        color: $primary-3;
      }

      &:disabled,
      &.disabled {
        pointer-events: none;
        color: $neutral-3;
      }
    }

    &-light {
      background: transparent;
      box-shadow: none;
      color: $neutral-0;
      border: none;

      @include hover() {
        background: rgba($neutral-0,.2);
        color: $neutral-1;
      }

      &:disabled,
      &.disabled {
        pointer-events: none;
        color: $neutral-3;
      }
    }

    &-danger {
      background: transparent;
      box-shadow: none;
      color: $red-3;
      border: none;

      @include hover() {
        background: rgba($red-2,.1);
        color: $red-3;
      }

      &:disabled,
      &.disabled {
        pointer-events: none;
        color: $neutral-3;
      }
    }
  }

  &-primary-light {
    color: $primary-3;
    background: none;
    border: none;

    @include hover() {
      background: rgba(#568C0B,.2);
      box-shadow: none;
      color: $primary-3;
    }

    &.disabled,
    &:disabled {
      border-color: transparent;
      background: transparent;
      color: $neutral-3;
    }
  }
  &.scroll-to-top {
      background-color: rgba(255, 255, 255, 0.4);
      border-radius: 10px 10px 0 0;
      border-color: transparent;
      padding: 12px;
      width: 48px;
      height: 48px;
      backdrop-filter: blur(20px);
      box-shadow: $shadow-big;
      @include flexbox(row,center,center);
      svg {
        rotate: 90deg;
      }
      @include hover() {
        background-color: $primary-1;
        box-shadow: none;
        border-color: transparent;
      }
  }

  &-delete {
    border-color: $neutral-0;
    box-shadow: $shadow-small;
    background: $neutral-0;
    color: $red-3;

    @include hover() {
      border-color: $red-3;
      box-shadow: $shadow-hover-red;
      color: $red-3;
    }

    &.disabled,
    &:disabled {
      border-color: $neutral-2;
      background: $neutral-2;
      color: $neutral-3;
    }
  }

  &-canva {
    background: $canva;
    color: $neutral-0;

    @include hover() {
      box-shadow: $shadow-canva;
      border-color: $canva;
      color: $neutral-0;
    }
    &.disabled,
    &:disabled {
      border-color: $neutral-2;
      background: $neutral-2;
      color: $neutral-3;
    }
  }
  &-tag {
    background: $primary-1;
    font-weight: 700;
    color: $primary-4;
    max-width: 100%;

    @include hover() { background: rgba(#D1EEA9,.5); color: $primary-4; .only-hover { opacity: 1;}}

    .only-hover {
      @include transition(.15);
      opacity: 0;
    }

    .btn-icon {
      &.btn-sm {
        margin-right: -3px;
        min-height: 24px;
        min-width: 24px;
        height: 24px;
        width: 24px;
        padding: 0;
      }
      &.btn-md {
        margin-right: -2px;
        min-height: 32px;
        min-width: 32px;
        height: 32px;
        width: 32px;
        padding: 0;
      }
    }

    &.btn-block { @include inline-flexbox(row,center,space-between);}

    &-label {
      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1;
    }

    &.variant-red {
      background: $neutral-1;
      color: $red-3;

      @include hover() {
        background: $red-1;
        color: $red-3;
      }
    }

    &.variant-primary {
      background: $primary-3;
      color: $neutral-0;

      @include hover() {
        background: $primary-4;
        color: $neutral-0;
      }
    }

    &.disabled {
      color: $primary-4;
    }

    &.suggestion-tag.exist {
      .tag-remove {
        display: none;
      }
      &:hover {
        .tag-remove {
          display: block;
        }
        .tag-icon {
          display: none;
        }
      }
    }
  }

  &-filter {
    background: rgba($primary-3,.1);
    color: $primary-3;

    @include hover() {
      background: rgba($primary-3,.15);
      color: $primary-3;
    }

    &-option {
      background: $primary-1;
      position: relative;
      color: $primary-3;
      min-height: 40px;

      &:after {
        content: "";
        @include transition(.15s);
        background-image: url("data:image/svg+xml,%3Csvg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.59375 9L7.34375 12.75L15.5938 4.5' stroke='%2306211E' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-position: center;
        display: inline-block;
        pointer-events: none;
        min-width: 18px;
        height: 18px;
        width: 18px;
        opacity: 0;
      }

      @include hover() {
        &:after { opacity: 1;}
        color: $primary-3;
      }

      &.active {
        background: $primary-3;
        color: $neutral-0;
        &:after {
          background-image: url("data:image/svg+xml,%3Csvg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.59375 9L7.34375 12.75L15.5938 4.5' stroke='%23fff' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          opacity: 1;
        }

        @include hover() {
          background: $primary-4;
          &:after {
            background-image: url("data:image/svg+xml,%3Csvg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.34375 3.75L14.8438 14.25' stroke='white' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4.34375 14.25L14.8438 3.75' stroke='%23fff' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          }
        }
      }
    }
  }


  // Overlay buttons

  &-overlay {
    border-radius: $border-radius-md;
    min-height: 22px;
    min-width: 22px;

    &-gray {
      color: $neutral-4;

      @include hover() { background: rgba(#000,.05); color: $neutral-4;}
    }

    &-green {
      color: $green-3;

      @include hover() { background: rgba(#23AD8C,.15); color: $green-3;}
    }

    &-red {
      color: $red-3;

      @include hover() { background: rgba(#EC6060,.1); color: $red-3;}
    }
  }
  &-base {
    color: $neutral-3;
    background: none;
    border: 1px solid $neutral-2;

    @include hover() {
      box-shadow: none;
      border-color: $neutral-2;
      color: $neutral-3;
      cursor: initial;
    }
  }
  &-google {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-appearance: none;
    background-color: WHITE;
    background-image: none;
    border: 1px solid #747775;
    -webkit-border-radius: 12px;
    border-radius: 12px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #1f1f1f;
    cursor: pointer;
    font-family: 'Roboto', arial, sans-serif;
    font-size: 14px;
    height: 40px;
    letter-spacing: 0.25px;
    outline: none;
    overflow: hidden;
    padding: 0 12px;
    position: relative;
    text-align: center;
    -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
    transition: background-color .218s, border-color .218s, box-shadow .218s;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    min-width: min-content;
    &:hover {
      -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
      box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
    }
    &.dark {
      background-color: #131314;
      border: 1px solid #747775;
      color: #e3e3e3;
      -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
      transition: background-color .218s, border-color .218s, box-shadow .218s;
      border-color: #8e918f;
      &:hover {
        opacity: 0.9;
      }
    }
  }
}

// Link buttons =========/

.btn-link {
  text-decoration: none;
  border-radius: 0;
  line-height: 1.6;
  background: none;
  color: $primary-3;
  border: none;
  padding: 0;

  @include hover() {
    color: $primary-4;
  }

  .btn-left,
  .btn-right {
    margin: 0;
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
    color: $neutral-3;
  }

  &.btn-sm {
    .btn-left,
    .btn-right {
      margin: 0;
    }
  }

  // Link Text variant =========/
  &-text {
    @include transition(.15s);
    font-size: $txt-size-1;
    color: $neutral-4;
    font-weight: 400;
    min-height: 22px;
    max-width: 100%;
    min-width: 0;
    padding: 0;

    &.disabled { color: $neutral-3; pointer-events: none;}

    u {
      margin-bottom: 2px;
    }

    .btn-label {
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
      min-width: 0;
    }

    @include hover() {
      //text-decoration: underline;
      color: $primary-4 !important;
    }

    .btn-right,
    .btn-left { width: 18px; min-width: 18px;}

    &-cta {
      @include flexbox(row, center, initial);

      .icon-cta {
        cursor: pointer;
      }
    }
  }
}

// Icon alone buttons =========/

.btn-icon {
  border-color: transparent !important;
  text-decoration: none;
  background: none;
  color: $primary-3;

  @include hover() {
    background: rgba(#568C0B,.2);
    color: $primary-3;
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
    color: $neutral-3;
  }

  &.warning {
    color: $red-3;

    @include hover() {
      background: rgba($red-2,.15);
      color: $red-3;
    }
  }

  &.primary {
    @include hover() {
      background: rgba(#568C0B,.2);
      color: $primary-3;
    }
  }

  &.default {
    color: $neutral-6;
    @include hover() {
      background: transparent;
      color: $neutral-6;
    }
  }

  //Copy button

  &.btn-copy {
    color: $neutral-4;
    min-height: 0;
    height: 18px;
    padding: 0;

    @include hover() { color: $primary-3;}

    .btn-link-text-cta & { margin-right: 6px;}
  }
  &.platform {
    color: $neutral-6;
    min-height: 25px;
    min-width: 25px;
    @include hover() {
      background: $neutral-1;
      color: $neutral-4;
    }
  }
}



// Button Sizes ===============/

.btn-xl {
  padding: 20px 24px;
  line-height: 1.5;
  min-height: 70px;
  font-size: 17px;
}

.btn-lg {
  padding: 10px 17px;
  line-height: 1.5;
  min-height: 60px;
  //min-width: 114px;
  font-size: 17px;
}

.btn-sm {
  border-radius: $border-radius-md;
  padding: 3px 7px;
  line-height: 1.6;
  min-height: 32px;
  //min-width: 60px;
  font-size: 11px;

  .btn-left { margin-left: -1px; width: 18px; min-width: 18px;}
  .btn-right { margin-right: -1px; width: 18px; min-width: 18px;}
}

.btn-mid {
  border-radius: $border-radius-md;
  padding: 10px;
  line-height: 1.6;
  min-height: 24px;
  font-size: 11px;

  .btn-left { margin-left: -1px; width: 12px; min-width: 12px; margin-right: 5px;}
  .btn-right { margin-right: -1px; width: 12px; min-width: 12px; margin-left: 5px;}
}

.btn-xs {
  border-radius: $border-radius-md;
  padding: 0 8px;
  line-height: 1.6;
  min-height: 18px;
  //min-width: 60px;
  font-size: 11px;

  .btn-left { margin-left: -1px; width: 12px; min-width: 12px; margin-right: 5px;}
  .btn-right { margin-right: -1px; width: 12px; min-width: 12px; margin-left: 5px;}
}

// Block button

.btn-block {
  width: 100%;

  + .btn-block {
    margin-top: $margin;
  }
}

.btn.is-lock {
  color: $neutral-3;
  cursor: default;
  border-color: transparent;
  box-shadow: unset;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}


// Segment button
.btn-segment {
  color: $primary-3;
  font-size: $txt-size-2;
  font-weight: 700;
  justify-content: center;
  min-height: 64px;
  padding: $padding $padding-lg;
  border-radius: 0;

  @include hover() {
    background: $primary-1;
    color: $primary-4;
  }
}

// Upload button
.btn.input-upload {
  position: relative;
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    appearance: none;
  }
}

// Expander
.btn-expander {
  .icon-regular { margin-left: -4px; color: $neutral-4;}
  @include hover() { color: $primary-3; .icon-regular { color: $primary-3;}}
}
//button fixed
.btn-sticky {
  position: sticky;
  z-index: 9;
  bottom: -25px;
  background-color: #fff;
  width: calc(100% + 50px);
  margin-left: -25px;
  padding: 0 25px;
  & > div {
    margin-bottom: 0 !important;
    padding-bottom: 35px !important;
  }
}
// button favicon
.btn-favicon {
  position: absolute;
  top: -6px;
  right: -6px;
  padding: 0;
  z-index: 1;
  .btn-xs {padding: 5px 6px;}
}