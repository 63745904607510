// Spacing explanation

// auto classes - auto
// 0 classes - 0px
// 1 classes - 4px
// 2 classes - 8px
// 3 classes - 16px
// 4 classes - 24px

// .m - margin all
// .mx - margin left & right
// .my - margin top & bottom
// .mb - margin bottom
// .mt - margin top
// .ml - margin left
// .mr - margin right

// .p - padding all
// .px - padding left & right
// .py - padding top & bottom
// .pb - padding bottom
// .pt - padding top
// .pl - padding left
// .pr - padding right

// .gc - gap column
// .gr - gap row


// Margin helpers

// Margin all
.m-0 { margin: 0 !important;}
.m-1 { margin: $margin-sm !important;}
.m-2 { margin: $margin !important;}
.m-3 { margin: $margin-default !important;}
.m-4 { margin: $margin-lg !important;}

// Margin x-axis
.mx-auto { margin-left: auto !important; margin-right: auto !important;}
.mx-0 { margin-left: 0 !important; margin-right: 0 !important;}
.mx-1 { margin-left: $margin-sm !important; margin-right: $margin-sm !important;}
.mx-2 { margin-left: $margin !important; margin-right: $margin !important;}
.mx-3 { margin-left: $margin-default !important; margin-right: $margin-default !important;}
.mx-4 { margin-left: $margin-lg !important; margin-right: $margin-lg !important;}

.negative-mx-3 { margin-left: -$margin-default !important; margin-right: -$margin-default !important;}
.negative-mx-4 { margin-left: -$margin-lg !important; margin-right: -$margin-lg !important;}
.negative-mx-5 { margin-left: -$margin-lg-extra !important; margin-right: -$margin-lg-extra !important;}

// Margin y-axis
.my-auto { margin-top: auto !important; margin-bottom: auto !important;}
.my-0 { margin-top: 0 !important; margin-bottom: 0 !important;}
.my-1 { margin-top: $margin-sm !important; margin-bottom: $margin-sm !important;}
.my-2 { margin-top: $margin !important; margin-bottom: $margin !important;}
.my-3 { margin-top: $margin-default !important; margin-bottom: $margin-default !important;}
.my-4 { margin-top: $margin-lg !important; margin-bottom: $margin-lg !important;}

// Margin bottom
.mb-auto { margin-bottom: auto !important;}
.mb-0 { margin-bottom: 0 !important;}
.mb-1 { margin-bottom: $margin-sm !important;}
.mb-2 { margin-bottom: $margin !important;}
.mb-3 { margin-bottom: $margin-default !important;}
.mb-4 { margin-bottom: $margin-lg !important;}
.mb-5 { margin-bottom: $margin-lg-extra !important;}

// Margin top
.mt-auto { margin-top: auto !important;}
.mt-0 { margin-top: 0 !important;}
.mt-1 { margin-top: $margin-sm !important;}
.mt-2 { margin-top: $margin !important;}
.mt-3 { margin-top: $margin-default !important;}
.mt-4 { margin-top: $margin-lg !important;}
.negative-mt-3 { margin-top: -$margin-default !important;}

// Margin left
.ml-auto { margin-left: auto !important;}
.ml-0 { margin-left: 0 !important;}
.ml-1 { margin-left: $margin-sm !important;}
.ml-2 { margin-left: $margin !important;}
.ml-3 { margin-left: $margin-default !important;}
.ml-4 { margin-left: $margin-lg !important;}

// Margin right
.mr-auto { margin-right: auto !important;}
.mr-0 { margin-right: 0 !important;}
.mr-1 { margin-right: $margin-sm !important;}
.mr-2 { margin-right: $margin !important;}
.mr-3 { margin-right: $margin-default !important;}
.mr-4 { margin-right: $margin-lg !important;}

// Extra margin right (as required) // should be removed
.mr-5 { margin-right: 32px !important;}
.mr-6 { margin-right: 40px !important;}
.mr-7 { margin-right: 48px !important;}
.mr-8 { margin-right: 56px !important;}

//Padding helpers

// Padding all
.p-0 { padding: 0 !important;}
.p-1 { padding: $padding-sm !important;}
.p-2 { padding: $padding !important;}
.p-3 { padding: $padding-default !important;}
.p-4 { padding: $padding-lg !important;}

// Padding x-axis
.px-auto { padding-left: auto !important; padding-right: auto !important;}
.px-0 { padding-left: 0 !important; padding-right: 0 !important;}
.px-1 { padding-left: $padding-sm !important; padding-right: $padding-sm !important;}
.px-2 { padding-left: $padding !important; padding-right: $padding !important;}
.px-3 { padding-left: $padding-default !important; padding-right: $padding-default !important;}
.px-4 { padding-left: $padding-lg !important; padding-right: $padding-lg !important;}
.px-5 { padding-left: 32px !important; padding-right: 32px !important;}

// Padding y-axis
.py-auto { padding-top: auto !important; padding-bottom: auto !important;}
.py-0 { padding-top: 0 !important; padding-bottom: 0 !important;}
.py-1 { padding-top: $padding-sm !important; padding-bottom: $padding-sm !important;}
.py-2 { padding-top: $padding !important; padding-bottom: $padding !important;}
.py-3 { padding-top: $padding-default !important; padding-bottom: $padding-default !important;}
.py-4 { padding-top: $padding-lg !important; padding-bottom: $padding-lg !important;}


// Padding bottom
.pb-0 { padding-bottom: 0 !important;}
.pb-1 { padding-bottom: $padding-sm !important;}
.pb-2 { padding-bottom: $padding !important;}
.pb-3 { padding-bottom: $padding-default !important;}
.pb-4 { padding-bottom: $padding-lg !important;}

// Padding top
.pt-0 { padding-top: 0 !important;}
.pt-1 { padding-top: $padding-sm !important;}
.pt-2 { padding-top: $padding !important;}
.pt-3 { padding-top: $padding-default !important;}
.pt-4 { padding-top: $padding-lg !important;}

// Padding left
.pl-0 { padding-left: 0 !important;}
.pl-1 { padding-left: $padding-sm !important;}
.pl-2 { padding-left: $padding !important;}
.pl-3 { padding-left: $padding-default !important;}
.pl-4 { padding-left: $padding-lg !important;}

// Padding right
.pr-0 { padding-right: 0 !important;}
.pr-1 { padding-right: $padding-sm !important;}
.pr-2 { padding-right: $padding !important;}
.pr-3 { padding-right: $padding-default !important;}
.pr-4 { padding-right: $padding-lg !important;}


// Grid gap helpers

// Gap column
.gc-0 { column-gap: 0 !important;}
.gc-1 { column-gap: $padding-sm !important;}
.gc-2 { column-gap: $padding !important;}
.gc-3 { column-gap: $padding-default !important;}
.gc-4 { column-gap: $padding-lg !important;}

// Gap row
.gr-0 { row-gap: 0 !important;}
.gr-1 { row-gap: $padding-sm !important;}
.gr-2 { row-gap: $padding !important;}
.gr-3 { row-gap: $padding-default !important;}
.gr-4 { row-gap: $padding-lg !important;}

// To update
.gap-default { gap: $padding-default;} 
