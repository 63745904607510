

.color-picker {
  .vc-sketch {
    position: relative;
    width: 200px;
    padding: 12px 12px 2px;
    box-sizing: initial;
    background: $neutral-0;
    border-radius: $border-radius-lg;
    box-shadow: none;
  }
  .vc-sketch-alpha-wrap { display: none;}
  .vc-sketch-hue-wrap { height: 24px;}
  .vc-hue-picker { height: 22px;}
  .vc-sketch-field {
    > .vc-sketch-field--single {
      &:last-child { display: none;}
    }
  }
  .popper {
    background-color: $neutral-0;
    color: $neutral-6;
    border: none;
    box-shadow: $shadow-big;
    border-radius: $border-radius-lg;
  }
}
