// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and stuff

// Vars
$modal-transition: transform 0.15s ease-out;
$side-modal-width: 568px;
$side-modal-width-sm: 460px;
$side-modal-width-lg: 1000px;

// Temporary

.fade {
  @include transition($transition-fade);

  &:not(.show) {
    opacity: 0;
  }
}

.modal-open {
  // Kill the scroll on the body
  overflow: hidden;

  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

// Container that the modal scrolls within
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-modal;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;
  // We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
  // gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
  // See also https://github.com/twbs/bootstrap/issues/17695
  &.loaded {
    margin-left: 64px;
    & + .modal-backdrop {
      margin-left: 64px;
    }
  }
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  position: relative;
  width: auto;
  margin: $modal-dialog-margin;
  // allow clicks to pass through for custom click handling to close modal
  pointer-events: none;

  // When fading in the modal, animate it to slide down
  .modal.fade & {
    @include transition($modal-transition);
    transform: $modal-fade-transform;
  }
  .modal.show & {
    transform: $modal-show-transform;
  }

  // When trying to close, animate focus to scale
  .modal.modal-static & {
    transform: $modal-scale-transform;
  }
}

.modal-dialog-scrollable {
  display: flex; // IE10/11
  max-height: subtract(100%, $modal-dialog-margin * 2);

  .modal-content {
    max-height: subtract(100vh, $modal-dialog-margin * 2); // IE10/11
    overflow: hidden;
  }

  .modal-header,
  .modal-footer {
    flex-shrink: 0;
  }

  .modal-body {
    overflow-y: auto;
  }
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: subtract(100%, $modal-dialog-margin * 2);

  // Ensure `modal-dialog-centered` extends the full height of the view (IE10/11)
  &::before {
    display: block; // IE10
    height: subtract(100vh, $modal-dialog-margin * 2);
    height: min-content; // Reset height to 0 except on IE
    content: "";
  }

  // Ensure `.modal-body` shows scrollbar (IE10/11)
  &.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .modal-content {
      max-height: none;
    }

    &::before {
      content: none;
    }
  }
}

// For RIGHT side modal add modal-class="modal-right" prop to <b-modal>

.modal-right {
  &.modal.fade {
    &:before {
      content: "";
      @include transition($modal-transition);
      background: linear-gradient(270deg, $neutral-6 0%, rgba($neutral-6, 0) 100%);
      display: block;
      pointer-events: none;
      position: fixed;
      right: $side-modal-width;
      transform: translate(100px, 0);
      width: 50px;
      top: 0;
      bottom: 0;
      opacity: 0.1;
    }
    .modal-dialog { transform: translate(100px, 0);}
    &.show {
      &:before { transform: translate(0, 0);}
      .modal-dialog { transform: none;}
    }
    &.small-shadow:before { right: $side-modal-width-sm !important;}
    &.no-shadow:before { display: none;}
  }

  .modal {
    &-dialog {
      pointer-events: all;
      max-width: $side-modal-width;
      width: $side-modal-width;
      margin: 0 0 0 auto;
      position: fixed;
      overflow: auto;
      bottom: 0;
      right: 0;
      top: 0;

      &.modal-sm {
        max-width: $side-modal-width-sm;
        width: $side-modal-width-sm;
      }

      .copilot-watch-page & {
        @media (max-width: 1921px) { width: 500px; max-width: 500px;}
        @media (max-width: 1441px) { width: 480px; max-width: 480px;}
        @media (max-width: 1200px) { width: 400px; max-width: 400px;}
        @media (max-width: 768px) { width: 100%; max-width: 100%;}
      }
    }
    &-content {
      backface-visibility: hidden;
      width: $side-modal-width;
      background: $neutral-1;
      position: absolute;
      border-radius: 0;
      max-width: 100%;
      overflow: auto;
      height: 100vh;
      right: 0;
      top: 0;

      .with-topbar & { height: 100vh;}

      &.ov-visible {
        > .modal-body {
          overflow: auto;
          height: 100vh;
        }
      }
    }
    &-header {
      padding-left: 32px;
    }
    &-body {
      padding: 0 32px;
      @include media-breakpoint-down(sm) { padding: 0 $padding-lg;}
    }
  }

  .side-panel-template {
    @include flexbox(column,initial,initial);
    min-height: 100vh;

    .side-panel-body {
      @include flexbox(column,initial,initial);
      flex: 1 0 0;
    }
  }
  &-large {
    &.modal.fade {
      &:before {
        right: $side-modal-width-lg;
      }
    }
  }
}

// For LEFT side modal add modal-class="modal-left" prop to <b-modal>

.modal-left {
  &.modal.fade {
    &:before {
      content: "";
      @include transition($modal-transition);
      background: linear-gradient(270deg, rgba($neutral-6, 0) 0%, rgba($neutral-6, 1) 100%);
      display: block;
      pointer-events: none;
      position: fixed;
      left: $side-modal-width;
      transform: translate(-100px, 0);
      width: 50px;
      top: 0;
      bottom: 0;
      opacity: 0.1;
    }
    .modal-dialog { transform: translate(-100px, 0);}
    &.show {
      &:before { transform: translate(0, 0);}
      .modal-dialog { transform: none;}
    }
  }
  .modal {
    &-dialog {
      pointer-events: all;
      max-width: $side-modal-width;
      width: $side-modal-width;
      margin: 0 0 0 auto;
      position: fixed;
      overflow: auto;
      bottom: 0;
      left: 0;
      top: 0;
    }
    &-content {
      width: $side-modal-width;
      background: $neutral-1;
      border-radius: 0;
      max-width: 100%;
    }
    &-body {
      padding: 0 32px 32px;
    }
  }
  &-large {
    &.modal.fade {
      &:before {
        left: $side-modal-width-lg;
      }
    }
  }
}

.modal-right-large, .modal-left-large {
  .modal {
    &-dialog {
      max-width: $side-modal-width-lg;
      width: $side-modal-width-lg;
    }
    &-content {
      width: $side-modal-width-lg;
    }
  }
}

// Actual modal
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
  // counteract the pointer-events: none; in the .modal-dialog
  color: inherit;
  pointer-events: auto;
  background-color: $neutral-0;
  background-clip: padding-box;
  border: none;
  border-radius: $border-radius-lg;
  box-shadow: $shadow-big;
  border-radius: $border-radius-lg;
  outline: 0;
}

// Modal background
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-modal-backdrop;
  width: 100vw;
  height: 100vh;
  background-color: $neutral-6;
  opacity: 0;

  // Fade for backdrop
  //&.fade { opacity: 0; }
  //&.show { opacity: .8; }
  .modal.show + & { opacity: .3;}
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  @include flexbox(row,center,space-between);
  padding: $padding-lg $padding-lg $padding-lg;

  .close {
    margin: -4px -7px 0 auto;

    &.btn {
      margin: 0 0 0 auto;

      //@include hover() {
      //  background: transparent;
      //  color: $primary-4;
      //}

      //&.btn-sm { margin: -4px -7px 0 auto;}
    }
  }

  .txt-heading-small { margin: 0;}
  .txt-body { font-weight: 700;}

  + .modal-body:empty + .modal-footer { padding-top: 0;}
}

// Title text within header
.modal-title {
  margin-bottom: 0;
  line-height: $modal-title-line-height;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  position: relative;
  // Enable `flex-grow: 1` so that the body take up as much space as possible
  // when there should be a fixed height on `.modal-dialog`.
  flex: 1 1 auto;
  padding: 0 $padding-lg;

  // apps modals
  .content {
    &-top {
      @include flexbox(column,initial,initial);
      .content-title {
        @include flexbox(row,center,space-between);
        &-top {
          @include flexbox(row,center,initial);
          h1 {
            margin-bottom: 0;
          }
          .content-icon {
            width: 48px;
            height: 48px;
            background: $neutral-0;
            border-radius: $border-radius-lg;
            @include flexbox(initial,center,center);
          }
        }
      }
    }
    .library-count {
      @include flexbox(row,center,space-between);
      .txt-heading-small {
        margin-bottom: $margin-sm;
      }
    }
  }

  .txt-heading-small {
    strong, b {
      word-break: break-word;
    }
  }
}

// Footer (for actions)
.modal-footer {
  @include flexbox(row,center,flex-start);
  padding: $padding-default $padding-lg - $padding-sm $padding-lg;

  // Place margin between footer elements
  // This solution is far from ideal because of the universal selector usage,
  // but is needed to fix https://github.com/twbs/bootstrap/issues/24800
  > * {
    margin: 0 $margin-sm;
  }
  .btn {
    .modal-sm &, .popover-widget & {
      flex: 1 0 0;
    }
  }
}



// Scale up the modal
@include media-breakpoint-up(sm) {
  // Automatically set modal's width for larger viewports
  .modal-dialog {
    max-width: $modal-md;
    margin: $modal-dialog-margin-y-sm-up auto;
  }

  .modal-dialog-scrollable {
    max-height: subtract(100%, $modal-dialog-margin-y-sm-up * 2);

    .modal-content {
      max-height: subtract(100vh, $modal-dialog-margin-y-sm-up * 2);
    }
  }

  .modal-dialog-centered {
    min-height: subtract(100%, $modal-dialog-margin-y-sm-up * 2);

    &::before {
      height: subtract(100vh, $modal-dialog-margin-y-sm-up * 2);
      height: min-content;
    }
  }

  .modal-content {
    @include box-shadow($modal-content-box-shadow-sm-up);
  }

  .modal-sm { max-width: 320px; }
  .modal-sm-md { max-width: 420px; }
  .modal-full { max-width: none;}
}

@include media-breakpoint-up(lg) {
  .modal-lg,
  .modal-xl {
    max-width: $modal-lg;
  }
}

@include media-breakpoint-up(xl) {
  .modal-xl { max-width: $modal-xl; }
}

// for Subscription

#modal-topup {
  .topup {
    @include flexbox(column,center,initial);
    .topup-buttons {
      width: 100%;
      @include flexbox(initial,center,space-between);
      padding: 0 10px;
      hr {
        width: 100%;
      }
    }
  }
}
// Cancel subscription

#modal-confirm-cancellation {
  .modal-dialog {
    .modal-form-content {
      .custom-control {
        @include flexbox(row-reverse,flex-start,initial);
      }
    }
    .modal-footer {
      .btn-block {
        margin-top: 0;
      }
    }
  }
}

// Change Subscription

#plan-confirmation-dialog {
  .modal-dialog {
    max-width: 420px;
    @at-root #app-subscribe-dialog .modal-dialog { max-width: 420px;}
  }
  .modal-content {
    .modal-body {
      .account-plans-regular {
        h2 {
          display: flex;
          flex-direction: column;
          b {
            i {
              font-weight: 700;
              font-size: 46px;
              line-height: 60px;
              font-style: normal;
            }
          }
        }
      }
    }
    &.basic {
      .modal-header {
        .txt-primary {
          color: $orange-4 !important;
        }
        .btn-icon {
          color: $orange-4;
        }
      }
      .modal-body {
        h3 {
          b {
            color: $orange-4;
          }
        }
      }
      .modal-footer {
        .btn-primary:not(:disabled) {
          background: $orange-4;
          border-color: $orange-4;
        }
      }
    }
    &.pro {
      .modal-header {
        .txt-primary {
          color: $green-3 !important;
        }
        .btn-icon {
          color: $green-3;
        }
      }
      .modal-body {
        h3 {
          b {
            color: $green-3;
          }
        }
      }
      .modal-footer {
        .btn-primary:not(:disabled) {
          background: $green-3;
          border-color: $green-3;
        }
      }
    }
    &.business {
      .modal-header {
        .txt-primary {
          color: $primary-3 !important;
        }
        .btn-icon {
          color: $primary-3;
        }
      }
      .modal-body {
        h3 {
          b {
            color: $primary-3;
          }
        }
      }
      .modal-footer {
        .btn-primary:not(:disabled) {
          background: $primary-3;
          border-color: $primary-3;
        }
      }
    }
  }
  .modal-footer {
    display: block;
    p {
      .btn-link {
        @extend .txt-body-small;
        min-height: 0;
        vertical-align: initial;
      }
    }
  }
}

#addon-modal {

  .modal-content {
    min-height: 402px !important;
  }
}

// downgrade modal dynamic arrow
#downgrade-dialog {
  .arrow {
    height: 1px;
    flex-grow: 1;
    background: #D7E0D8;
    margin: 0 6px;
    position: relative;

    .spear-head-up {
      position: absolute;
      top: -2px;
      right: 0px;
      height: 1px;
      width: 5px;
      background: #D7E0D8;
      transform: rotate(45deg);
    }

    .spear-head-down {
      position: absolute;
      bottom: -2px;
      right: 0px;
      height: 1px;
      width: 5px;
      background: #D7E0D8;
      transform: rotate(-45deg);
    }
  }
}

// Specials for watch page
.app:has(.watch-iframe) ~ .playlist-form-slide-in .modal-right {
  @media (max-width: 1857px) { &:before { right: 500px;} .modal-dialog.modal-md, .modal-content { width: 500px; max-width: 500px;}}
  @media (max-width: 1377px) { &:before { right: 480px;} .modal-dialog.modal-md, .modal-content { width: 480px; max-width: 480px;}}
  @media (max-width: 768px) { &:before { right: 100%;} .modal-dialog.modal-md, .modal-content { width: 100%; max-width: 100%;}}

  .side-panel-body .side-panel-group-header-sm:first-child { margin-top: -42px; margin-right: 50px;}
}
// email template modal
.modal-right-email-template {
  .modal-body {
    display: grid;
    grid-template-rows: auto auto 1fr;
    padding-bottom: 32px;
    .side-panel-widget {
      display: grid;
      grid-template-rows: auto 1fr auto;
      .form-control {
        height: 100%;
        display: grid;
        grid-template-rows: 1fr auto;
      }
    }
  }
}