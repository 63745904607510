.card-file {

  // Default variant =========================/

  transition: box-shadow .15s, background .15s;
  margin: 0 $margin $margin-default;
  border-radius: $border-radius-lg;
  box-shadow: $shadow-small;
  @include transition(.15s);
  background: $neutral-0;
  min-width: 200px;

  @include hover() {
    box-shadow: $shadow-big;
    background: $primary-1;

    .card-file-figure {

      .slot-top {
        .popover-toggle {
          visibility: visible;
          opacity: 1;
        }
      }

      .thumbnail-default {
        background: $neutral-3;
      }
    }
    .lazy-line { background: $primary-2;}
    .card-file-info { color: $primary-4;}
    .card-file-status { color: $primary-4;}
    .card-file-date { color: $primary-3; cursor: default;}
  }

  &-sm {
    .card-file-figure {
      min-height: 92px;
    }
    .card-file-info {
      padding: $padding-sm $padding $padding;
      .card-file-stat:first-child { margin-left: 0;}
      .card-file-date { margin-top: auto;}
    }
    @include hover() {
      box-shadow: $shadow-small;
    }
  }

  &-figure {
    @include inline-flexbox(column,initial,space-between);
    @include transition(.15s);
    position: relative;
    color: $neutral-6;
    width: 100%;
    cursor: pointer;
    background: $neutral-0;
    min-height: 100px;
    overflow: hidden;
    aspect-ratio: 318/179;
    padding-bottom: 56%;
    border-radius: $border-radius-lg $border-radius-lg 0 0;
    box-shadow: none;

    &.default {
      &:before { display: none;}
    }

    &:before {
      pointer-events: none;
      background: linear-gradient(to bottom, $neutral-6 0%, rgba($neutral-6,0) 100%);
      border-radius: $border-radius-lg $border-radius-lg 0 0;
      @include transition(.15s);
      opacity: .4;
      content: "";
      position: absolute;
      height: 47%;
      width: 100%;
      z-index: 1;
      top: 0;
      left: 0;
    }

    &:after {
      pointer-events: none;
      background: $neutral-6;
      @include transition(.15s);
      border-radius: $border-radius-lg $border-radius-lg 0 0;
      opacity: 0;
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 1;
      top: 0;
      left: 0;
    }
  }

  &-bubble {
    padding: 0;

    &-inner {
      > .btn {
        background: transparent;
        box-shadow: none;
        color: inherit;
        border: none;
      }
    }
  }

  &-stat {
    @include inline-flexbox(row,center,center);
    border-radius: $border-radius-md;
    @include transition(.15s);
    background: transparent;
    font-size: $txt-size-0;
    font-weight: 700;
    min-width: 32px;
    height: 32px;
    //width: 32px;

    &:first-child { margin-left: -$margin;}

    @include hover() {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  &.not-playable {
    .card-file-figure {
      cursor: initial;
      &:after {
        background: $neutral-2;
        opacity: .9;
      }
      &.default {
        .thumbnail-default { background: $neutral-2;}
        &:after { display: none;}
      }
    }
    .card-file-info {
      .txt-heading-small { color: $neutral-3; .txt-primary { color: $primary-2 !important;}}
    }

    @include hover() {
      .card-file-info {
        .txt-heading-small { color: $primary-2; .txt-primary { color: $primary-2 !important;}}
      }
    }

    &.selected {
      .card-file-info {
        .txt-heading-small { color: $primary-2;}
      }
    }
  }

  .thumbnail {
    border-radius: $border-radius-lg $border-radius-lg 0 0;
    object-position: center;
    pointer-events: none;
    object-fit: cover;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
    top: 0;
    left: 0;

    &-default {
      @include flexbox(row,center,center);
      @include transition(.15s);
      border-radius: $border-radius-lg $border-radius-lg 0 0;
      background: $neutral-2;
      color: $neutral-4;
      pointer-events: none;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }

    &-image-sm {
      width: 80%;
      height: 90%;
      border-radius: $border-radius-sm;
      object-fit: cover;
    }
  }

  .preview {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    @include transition(.15s);
  }

  &.with-preview {
    .preview {
      opacity: 1;
    }
  }

  .slot-top {
    @include flexbox(row,flex-start,flex-start);
    padding: $padding-default ($padding-default - $padding-sm) $padding-default $padding-default;
    @include transition(.15s);
    min-height: 80px;
    position: absolute;
    color: #fff;
    z-index: 2;
    right: 0;
    left: 0;
    top: 0;

    .popover-toggle {
      opacity: 0;
      visibility: hidden;
      margin: (-$margin-sm) 0 0;
      color: $neutral-0;
    }

    .custom-checkbox .custom-control-input:not(:checked):not(.checked) + .custom-control-label:before { border-color: $neutral-0;}
  }

  .txt-heading-small {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .slot-bottom {
    padding: 0 ($padding-default - $padding-sm) ($padding-default - $padding-sm) $padding-default;
    @include flexbox(row,flex-end,space-between);
    position: absolute;
    color: $neutral-0;
    margin-top: auto;
    z-index: 2;
    width: 100%;
    bottom: 0;
    left: 0;

    .txt-body-small { opacity: 0; color: #fff;}

    .card-file-count {
      pointer-events: none;
      order: 10;
      min-width: 32px;
      margin-left: auto;
    }

    .progress {
      @include flexbox(row,initial,initial);
      background: rgba(#D7E0D8, .75);
      position: absolute;
      height: 4px;
      bottom: 0;
      right: 0;
      left: 0;

      &-bar {
        height: 4px;
      }
    }
  }

  &-info {
    @include flexbox(column,flex-start,flex-start);
    padding: $padding $padding-default $padding;
    height: auto;

    .txt-heading-small, > .txt-body {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden;
      min-height: 25.5px;
    }

    @at-root .card-file:not(.not-playable) .card-file-info { cursor: pointer;}
  }

  &-status {
    @include flexbox(row,center,flex-start);
    color: $neutral-3;
    min-height: 32px;
    width: 100%;
  }

  &-pending {
    width: 100%;
    height: 14px;
    background: #333;
  }

  &-time {
    @include inline-flexbox(row,center,center);
    border-radius: $border-radius-md;
    background: rgba($neutral-6,.5);
    @include transition(.15s);
    font-size: $txt-size-0;
    color: $neutral-0;
    padding: 0 $padding;
    font-weight: 700;
    min-height: 32px;

    .txt-dot { background: $neutral-0; opacity: .25;}

    .btn-sm {
      min-height: 24px;
      min-width: 24px;
      margin: 0 -4px;
      padding: 0;

      @include hover() { background: rgba(#fff,.1);}

      svg { color: $neutral-0;}
    }
  }

  &-cta {
    @include flexbox(row,center,flex-start);

    .btn-group {
      @include inline-flexbox(row,center,center);
    }
  }

  &-stats {
    @include inline-flexbox(row,center,flex-start);
    color: $primary-4;
    height: 32px;
    padding-right: 12px;
    margin-right: $padding-sm;
    border-right: 1px solid rgba($neutral-0,.2);
  }

  /*
  &.select-only {
    .slot-top {
      position: static;
      .custom-checkbox {
        position: static;
        .custom-control-label {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          &:before {
            left: $padding-default;
            top: $padding-default;
          }
          &:after {
            position: absolute;
            left: 21px;
            top: 20px;
          }
        }
      }
    }
    .slot-bottom {
      pointer-events: none;
    }
  }
  */

  &.selected {
    background: $primary-1;
    box-shadow: $shadow-small;
    .card-file-figure {
      color: $primary-4;
      background: $primary-2;

      &:before { opacity: 0;}

      &:after {
        background: $primary-2;
        opacity: .9;
      }

      &.default {
        .thumbnail-default { background: $primary-2; color: $primary-4;}
        &:after { opacity: 0;}
      }
    }

    .card-file-time {
      background: $primary-3;
    }

    .slot-top {
      color: $primary-4;

      .popover-toggle {
        visibility: visible;
        color: $primary-4;
        opacity: 1;
      }
    }

    .slot-bottom {
      color: $primary-4;

      .txt-body-small { opacity: .5; color: $primary-4;}
    }
    .lazy-line { background: rgba(85, 129, 244, 0.2);}
    .card-file-info { color: $primary-4;}
    .card-file-status { color: $primary-4;}
    .card-file-date { color: $primary-3;}
  }

  // Row variant =========================/

  &-row {
    @include flexbox(row,stretch,flex-start);
    border-top: 1px solid $neutral-2;
    padding: $padding-sm + $padding;
    @include transition(.15s);
    background: $neutral-0;

    @include hover() {
      background: $primary-1;
      .card-file-row-index { color: $primary-4;}
      .card-file-row-thumbnail {
        &:before { opacity: 0;}
        &:after { opacity: .4;}
      }
      .slot-top { color: $primary-4;}
      .slot-bottom {
        .txt-body-small { color: $primary-3;}
      }
      .card-file-row-thumbnail .thumbnail-default {
        background: $primary-2;
        color: $primary-3;
      }
    }

    &.selected {
      background: $primary-2;
      .card-file-row-index { color: $primary-4;}
      .card-file-row-thumbnail {
        &:before { opacity: 0;}
        &:after { opacity: .4;}
      }
      .slot-top { color: $primary-4;}
      .slot-bottom {
        .txt-body-small { color: $primary-3;}
      }
    }

    &-index {
      min-width: 24px;
      margin-right: $margin;
      padding-top: $padding;
      color: $neutral-4;
      line-height: 24px;
      text-align: center;

      .handle { cursor: grab;}
    }

    &-thumbnail {
      border-radius: $border-radius-sm;
      margin-right: $margin;
      padding: $padding;
      position: relative;
      overflow: hidden;
      min-width: 160px;
      width: 160px;
      height: 90px;

      &:before {
        pointer-events: none;
        background: linear-gradient(to bottom, $neutral-6 0%, rgba($neutral-6,0) 100%);
        @include transition(.15s);
        opacity: .4;
        content: "";
        position: absolute;
        height: 47%;
        width: 100%;
        z-index: 1;
        top: 0;
        left: 0;
      }

      &:after {
        pointer-events: none;
        background: $neutral-6;
        @include transition(.15s);
        opacity: 0;
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        top: 0;
        left: 0;
      }

      .custom-checkbox {
        position: relative;
        z-index: 2;
      }

      .thumbnail {
        border-radius: $border-radius-sm;
        object-position: center;
        pointer-events: none;
        object-fit: cover;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 0;
        top: 0;
        left: 0;

        &-default {
          @include flexbox(row,center,center);
          border-radius: $border-radius-sm;
          @include transition(.15s);
          background: $neutral-2;
          color: $neutral-4;
          pointer-events: none;
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;

          > svg {
            width: 64px;
            height: auto;
          }
        }
      }
    }

    .slot-right {
      flex: 1 0 0;
      @include flexbox(column,flex-start,flex-start);
    }

    .slot-top {
      @include flexbox(row,flex-start,flex-start);
      @include transition(.15s);
      position: relative;
      color: $neutral-6;
      width: 100%;
      z-index: 2;

      .txt-heading-small {
        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-inline-box;
        -webkit-line-clamp: 2;
                line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height: 51px;
      }
    }

    .slot-bottom {
      @include flexbox(row,flex-end,space-between);
      position: relative;
      color: $primary-4;
      margin-top: auto;
      width: 100%;
      z-index: 2;
      width: 100%;

      .card-file-stats { border-color: rgba($neutral-4,.2);}
      .txt-body-small { opacity: .5; color: $neutral-3;}
    }

    // Sizes Row =========================/

    &.sm {
      min-height: 130px;

      .thumbnail-default {
        svg {
          width: 70px;
          height: auto;
        }
      }
    }
  }

  // List variant =============================/

  &-list {
    @include flexbox(row,initial,initial);
    margin: 0 0 $margin;
    padding: 0;
    height: 90px;

    @include hover() {
      .slot-top {
        .popover-toggle {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .slot-top {
      min-height: 0;
      padding: 12px;
      left: 0;
      right: 0;
      top: 0;

      .popover-toggle {
        color: $primary-3;
        margin: 0;
      }

      + .txt-heading-small { max-width: calc(100% - 80px);}
    }

    .slot-bottom { 
      padding: $padding;
      .card-file-time {
        .btn {display: none;}
      }
    }

    .card-file-figure {
      border-radius: $border-radius-lg 0 0 $border-radius-lg;
      min-height: 90px;
      min-width: 160px;
      height: 90px;
      width: 160px;
      padding: 0;

      &:before { border-radius: $border-radius-lg 0 0 0;}
      &:after { border-radius: $border-radius-lg 0 0 $border-radius-lg;}
      .thumbnail { border-radius: $border-radius-lg 0 0 $border-radius-lg;}
    }

    .card-file-info {
      border-radius: 0 $border-radius-lg $border-radius-lg 0;
      padding: $padding-md $padding-md $padding;
      max-width: calc(100% - 160px);
      position: relative;
      width: 100%;

      &.skeleton-item { width: 100%;}

      .card-file-stat {
        &:first-child { margin-left: 0;}
      }
      .card-file-date { margin-top: $margin;}
    }

    .thumbnail-default {
      border-radius: $border-radius-lg 0 0 $border-radius-lg;
      svg { width: 60px;}
    }
  }
}

.lazy-line {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 4px;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: $neutral-2;
  @include transition(.15s);

  span.anim {
    background: $primary-3;
    position: absolute;
    width: 200%;
    bottom: 0;
    left: 0;
    top: 0;
    animation-duration: 2.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: lazy-line;
    animation-timing-function: ease-in-out;
  }
}
