.card-hub {
  @include flexbox(column,initial,space-between);
  border-radius: $border-radius-lg;
  @include transition(.15s);
  padding: $padding-default;
  box-shadow: $shadow-small;
  width: calc(50% - 8px);
  background: $neutral-0;
  position: relative;
  //z-index: 1;
  //height: 160px;
  cursor: pointer;

  @include media-breakpoint-up(xl) { width: calc(33.3333% - 10.8px);}

  &-list {
    width: 100%;
    @include media-breakpoint-up(lg) { width: 100%;}
    @include media-breakpoint-up(xxxl) { width: 100%;}
  }

  &--simple {
    .card-hub__header {
      .txt-heading {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-height: 41px;
      }
    }
  }

  &-loading {
    pointer-events: none;
    box-shadow: none;
    padding: 0;
    z-index: 0;
  }

  &__header {
    justify-content: space-between;
    position: relative;
    display: flex;

    .txt-heading {
      line-height: 1.4;
      min-height: 61px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-inline-box;
      -webkit-line-clamp: 2;
              line-clamp: 2;
      -webkit-box-orient: vertical;
      color: $neutral-6;
      word-break: break-word;
      margin-bottom: 0;

      .card-hub-list & {
        margin-bottom: 0 !important;
        @extend .txt-no-wrap;
        display: block;
        min-height: 0;
        order: -1;
      }
    }
    &-menu {
      position: absolute;
      min-width: 32px;
      display: flex;
      right: 0;
      top: 0;
    }
    a {
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-inline-box;
        -webkit-line-clamp: 1;
                line-clamp: 1;
        -webkit-box-orient: vertical;
        max-width: 630px;

      }
    }

    &-top {
      max-width: 100%;

      .card-hub-list & {
        @include flexbox(column,initial,initial);
        padding-left: 56px;
        position: relative;
      }
    }
  }
  &__link {
    @include flexbox(initial, center, initial);
    button {
      @include inline-flexbox(initial, center, initial);
    }
    &.disabled {
      opacity: 0;
      pointer-events: none;
    }
  }
  &__button {
    cursor: pointer;
    background: #E6F7D1;
    border: 1px solid #c6d6ff;
    box-sizing: border-box;
    border-radius: $border-radius-sm;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 8px;
    margin-left: 8px;
  }
  &__actions {
    @include flexbox(row,flex-end,flex-start);
    .btn.disabled { background: $neutral-0;}
    .card-hub-list & { margin-top: 29px;}
  }
  &__logo {
    pointer-events: none;
    min-height: 40px;

    .card-hub-list & {
      position: absolute;
      top: 0;
      left: 0;
    }

    span {
      font-size: $txt-size-1;
      font-weight: 700;
      min-width: 40px;
      height: 40px;
      width: 40px;
      line-height: 1;
      background: $primary-1;
      color: $primary-3;
      border-radius: $border-radius-lg;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
    }
    img {
      min-width: 40px;
      max-height: 40px;
      font-size: 0;
      overflow: hidden;
      max-width: 200px;
    }
  }
  &--processing {
    cursor: wait;
    position: relative;
    overflow: hidden;
    .lazy-line {
      top: 0;
      bottom: auto;
    }
    .card-hub__header {
      .txt-heading {
        color: $neutral-3;
      }
      .btn-icon.btn-copy { color: $neutral-3;}
    }
    .card-hub__logo {
      span {
        background: $neutral-1;
        color: $neutral-4;
      }
      img {
        filter: grayscale(1);
        opacity: .8;
      }
    }
  }
  &:not(.card-hub--processing) {
    &.card-hub--hovered {
      background: $primary-1;
      box-shadow: $shadow-big;
  
      .card-hub__logo {
        span {
          background: $neutral-0;
        }
      }
    }
  }

  //Card Hub Template ===================================/

  &-template {
    position: relative;
    min-width: 320px;
    overflow: hidden;
    display: block;

    @include hover() {
      box-shadow: $shadow-big;
      &.blank { .card-hub-template-wrap { background: $primary-1;}}
    }

    &.blank { .card-hub-template-wrap { background: $neutral-0;}}

    .hubs-templates-featured & {
      flex: 338px 0 0;
      width: 338px;
    }

    .hubs-templates-listing & {
      width: calc(50% - 12px);

      .filters-open & { width: calc(50% - 12px);}
    }

    &-illustration {
      pointer-events: none;
      position: absolute;
      max-height: 110%;
      top: 6%;
    }

    &-wrap {
      @include flexbox(column,center,center);
      border-radius: $border-radius-lg;
      background: rgba($neutral-0,.2);
      backface-visibility: hidden;
      box-shadow: $shadow-small;
      @include transition(.15s);
      position: relative;
      overflow: hidden;
      width: 100%;
      aspect-ratio: 16 / 9;

      &.with-overlay {
        &:before {
          content: "";
          background: linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
          pointer-events: none;
          position: absolute;
          display: block;
          opacity: .3;
          height: 40%;
          width: 100%;
          z-index: 2;
          left: 0;
          top: 0;
        }
      }
    }

    .txt-heading-small {
      text-overflow: ellipsis;
      word-break: break-word;
      white-space: nowrap;
      margin-top: $margin;
      min-height: 26px;
      overflow: hidden;
     // display: -webkit-inline-box;
     // -webkit-line-clamp: 2;
     //         line-clamp: 2;
     // -webkit-box-orient: vertical;
    }

    &-bg {
      border-radius: $border-radius-lg;
      --corner-smoothing: 60%;
      object-position: center;
      will-change: transform;
      pointer-events: none;
      transform: scale(1);
      position: absolute;
      object-fit: cover;
      height: 100%;
      width: 100%;
      //z-index: 1;
      left: 0;
      top: 0;

      &.hovered {
        filter: brightness(.5);
        + .card-hub-template-content {
          backdrop-filter: blur(17px);
          background: rgba(#000,.4);
        }
      }
    }

    &-content {
      @include flexbox(column,center,center);
      -webkit-transform:translate3d(0,0,0);
      border-radius: $border-radius-lg;
      padding: $padding-default;
      @include transition(.15s);
      --corner-smoothing: 60%;
      position: absolute;
      color: $neutral-0;
      height: 100%;
      width: 100%;
      z-index: 2;
      left: 0;
      top: 0;

      .btn-primary {
        max-width: 80%;
        > span {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .btn-platform {
        color: $neutral-0;
      }
      .btn-platform-light {
        position: relative;

        @include hover { background: transparent !important;}
      }
    }
  }
}
