&-toggle {
  display: flex;
  justify-content: space-between;
  position: relative;
  &-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
