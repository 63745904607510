&-description {
  @include flexbox(column,flex-start,flex-start);
  position: relative;
  min-height: 40px;
  width: 100%;

  &-wrap {
    @include flexbox(column,flex-start,flex-start);
    width: 100%;
  }

  &-content { width: 100%;}
  &-menu {
    @include inline-flexbox(row,center,initial);
    @include transition(.15s);
    pointer-events: none;
    position: absolute;
    overflow: hidden;
    max-height: 0;
    bottom: 8px;
    z-index: 2;
    opacity: 0;
    left: 6px;

    &.active {
      pointer-events: all;
      max-height: 24px;
      opacity: 1;
    }

    button {
      @include inline-flexbox(row,center,center);
      border-radius: $border-radius-sm;
      @include transition(.15s);
      background: transparent;
      box-sizing: border-box;
      margin-right: $margin;
      color: $neutral-4;
      min-width: 24px;
      height: 24px;
      width: 24px;
      padding: 0;

      @include hover() { background: rgba($neutral-6,.05);}

      &.is-active {
        background: $primary-3;
        color: $neutral-0;

        @include hover() { background: $primary-4;}
      }

      &.disabled, &[disabled] {
        pointer-events: none;
        opacity: .5;
      }
    }
  }

  > .txt-body { width: 100%;}

  &__content, .ProseMirror {
    border-radius: $border-radius-md;
    border: 1px solid transparent;
    @include transition(.15s);
    word-break: break-word;
    padding: 6px 66px 6px 4px;
    position: relative;
    color: $neutral-4;
    min-height: 47px;
    width: 100%;
    z-index: 1;

    a { color: $primary-4;}

    &:focus { outline: none;}
    ::selection { background: $primary-4-transparent;}
    
    @at-root .side-panel-description.focused .ProseMirror, .side-panel-description.focused .side-panel-description__content, .side-panel-description .ProseMirror, .side-panel-description .side-panel-description__content {
      ol,ul {
        padding-left: 15px;
        li {
          list-style-position: inside;
          p {
            display: inline;
          }
        }
      }
      ul {
        li {
          list-style-type: disc; 
        }
      }
      ol {
        li {
          list-style-type: decimal;
        }
      }
    }

    @at-root .side-panel-description.focused .ProseMirror, .side-panel-description.focused .side-panel-description__content {
      padding: 6px 66px 42px 8px;
      border-color: $neutral-2;
      background: $neutral-0;
      color: $neutral-6;
      outline: none;

      p { padding-right: 66px;}
      a { color: rgba(#568C0B,.85);}

      @include hover() {
        border-color: $neutral-2 !important;
        background: $neutral-0 !important;
        color: $neutral-6 !important;
      }
    }

    &:not([contenteditable="false"]) {
      @include hover() {
        background: rgba(#AFC3B5,.2);
        border-color: $neutral-2;
        color: $neutral-6;
        cursor: text;
        a { color: rgba(#568C0B,.85);}
      }
      &:focus {
        // padding: 6px 86px 6px 8px;
        padding: 6px 86px 42px 8px;
        border-color: $neutral-2;
        background: $neutral-0;
        outline: none;
      }
      &.in-focus {
        + .side-panel-description-placeholder {
          padding: 0 8px;
        }
      }

      .side-panel-description__content,.txt-title-small {
        background: transparent !important;
        width: auto !important;
        padding: 0 !important;
        color: inherit;
        border: none !important;
        font-size: inherit !important;
        line-height: inherit !important;
      }
    }
    //&:not([contenteditable="false"],:focus) {
    //  @include hover() {
    //    background: $neutral-2;
    //    color: $neutral-6;
    //  }
    //}

    // strange edge case when copying all and pasting
  }
  &-actions {
    @include flexbox(row,center,flex-end);
    background: $neutral-0;
    flex: 0 0 110px;
    position: absolute;
    right: 5px;
    top: 1px;
    z-index: 2;
    padding-top: 6px;
  }
  & + .btn {
    margin-left: $margin-default !important;
  }
  &-placeholder {
    transition: padding .15s;
    position: absolute;
    padding: 0 4px;
    top: 7px;
    left: 0;

    .uploader-item-title & {
      font-size: 1.7rem;
      opacity: .7;
      left: 8px;
      top: 7px;
    }
  }
}
