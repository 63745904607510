@import "cards/card-hub";
@import "cards/card-account";
@import "cards/card-series";
@import "cards/card-library";
@import "cards/card-file";
@import "cards/card-folder";
@import "cards/card-app";
@import "cards/card-simple";
@import "cards/card-segment";
@import "cards/card-notification";
@import "cards/card-widget";
@import "cards/card-attribute";
@import "cards/card-achievement";
@import "cards/list-card-folder";
@import "cards/list-card-file";
@import "cards/card-sitemap";
@import "cards/card-chart";


// Common card components

.card-indicator {
  border-radius: $border-radius-md;
  @include flexbox(row,center,initial);
  background: rgba(0,0,0,0);
  font-size: $txt-size-0;
  color: $neutral-4;
  cursor: default;
  transition: .2s;
  padding: 7px;
  //gap: 4px;

  // @include hover() { background: rgba(0,0,0,.1)}
  &-primary {
    color: $neutral-3;
    @include hover() { background: rgba(0,0,0,.1); color: $neutral-4;}
  }
}