.filter-switch {
  border: 1px solid $primary-3;
  @include flexbox(row,center,initial);
  box-shadow: none;
  background: $primary-3;
  box-sizing: border-box;
  width: 140px;
  border-radius: 30px;
  transition: .2s;
  min-width: 140px;
  height: 32px;
  padding: 5px;
  &:has(.btn:hover) { box-shadow: $shadow-hover; background: $primary-4; border-color: $primary-4;}

  .account-plans-price & { height: 40px;}

  .btn {
    border: 1px solid transparent;
    background: transparent;
    --corner-smoothing: 60%;
    border-radius: 30px;
    color: $primary-2;
    text-transform: uppercase;
    font-size: $txt-size-0;
    flex: 1 0 0;
    min-height: 20px;
    padding: 0 5px;
    cursor: pointer;

    .account-plans-price & { height: 30px;}

    &.active {
      border: 1px solid $neutral-0;
      box-shadow: $shadow-small;
      background: $primary-2;
      color: $primary-3;

      @include hover() {
        color: $primary-4;
      }
    }
  }

  &.variant-light {
    background: $neutral-0;
    border-color: $neutral-2;
    &:has(.btn:hover) { box-shadow: $shadow-hover; background: $primary-2; border-color: $primary-4;}

    .btn {
      background: transparent;
      color: $neutral-4;

      &.active {
        border: 1px solid $neutral-2;
        background: $neutral-1;
        color: $neutral-6;
      }
    }
  }

  &.disabled { pointer-events: none; opacity: .5;}
}
