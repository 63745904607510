.transcript {

  // Top and bottom editor component ==================/

  &-nav {
    @include flexbox(row, center, space-between);
    box-shadow: $shadow-small;
    @include transition(.2s);
    background: $neutral-0;
    //overflow: hidden;
    height: 72px;
    position: sticky;
    z-index: 1001;
    width: 100%;
    top: 0;

    &-left {
      @include flexbox(row,center,initial);
      position: absolute;
      padding: $padding + $padding-sm;
      height: 100%;
      left: 0;
      top: 0;
      > .btn { margin-right: $margin;}
    }
    &-right {
      @include flexbox(row,center,initial);
      overflow: hidden;
      position: absolute;
      padding: $padding + $padding-sm;
      height: 100%;
      right: 0;
      top: 0;
      > .btn { margin-left: $margin;}
    }
    &-center {
      @include inline-flexbox(row,center,center);
      margin: auto;

      > .txt-heading-small {
        max-width: calc(100vw - 975px);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;

        @media (max-width: 1200px) { max-width: calc(100vw - 820px);}
      }
    }
    &.expanded {
      + .transcript-editor-body { padding-top: 110px;}
    }
  }

  // Editor layout =====================/

  &-editor {
    .layout-split-right {
      box-shadow: $shadow-big;
    }
    &-body {
      min-height: calc(100vh - 208px);
      transition: padding-top .2s;
      background: $neutral-0;
      position: relative;
      padding-top: 0;
    }
    &-actions {
      position: sticky;
      bottom: 0;

      .transcript-nav {
        overflow: initial;
        box-shadow: none;
        position: relative;
        top: auto;
      }
    }
    &-loader {
      .anim-rect {
        position: relative;
        display: block;
        box-sizing: border-box;
        border-radius: $border-radius-md 0 0 $border-radius-md;
        border: 2px solid $neutral-2;
        border-right: none;
        height: 18px;
        width: 31px;
        margin-top: 10px;
        animation: transcript-timeline 4.8s both ease-in-out infinite;

        &:after {
          content: "";
          display: block;
          width: 2px;
          border-radius: 2px;
          perspective: 1px;
          overflow: hidden;
          height: 32px;
          background: $primary-4;
          position: absolute;
          right: 0;
          top: -8px;
        }
      }
    }

    .side-panel-left {
      @include media-breakpoint-down(lg) {
        width: 300px;
        left: 0;

        &.active ~ .layout-split-right { left: 300px;}
      }
    }
  }

  // Editor find & replace ===============/

  &-replace {
    @include flexbox(row,center,initial);
    background: $neutral-0;
    box-shadow: $shadow-mid;
    position: absolute;
    overflow: hidden;
    width: 100%;
    top: 100%;
    left: 0;

    .form-control-text {
      flex: 1 0 0;
    }

    &-cta {
      @include flexbox(row,center,flex-end);
    }

    .form-control-navigation {
      @include flexbox(row,center,center);

    }
  }

  // Editor timeline =====================/

  &-timeline {
    margin: -24px 0 -12px;
    position: relative;
    padding: 0;
    z-index: 100;

    #timeline {
      min-height: 100px;
      > wave {
        @include scrollbar-none;
        height: 100px !important;

        &:before {
          content: "";
          background: $neutral-0;
          pointer-events: none;
          position: absolute;
          display: block;
          bottom: 12px;
          top: 24px;
          left: 0;
          right: 0;
        }

        > canvas {
          bottom: 12px !important;
          height: auto !important;
          top: 24px !important;
          left: 20px !important;
        }

        > wave {
          border-right: 2px solid transparent !important;
          overflow: visible !important;
          left: 20px !important;

          &:before {
            content: "";
            position: absolute;
            display: block;
            border-radius: 2px;
            width: 2px;
            height: 88%;
            background: $primary-4;
            right: -2px;
            top: 6px;
          }

          &:after {
            content: "";
            border: 6px solid $neutral-0;
            box-shadow: $shadow-small;
            box-sizing: border-box;
            border-radius: 20px;
            background: $primary-4;
            position: absolute;
            display: block;
            width: 24px;
            height: 24px;
            right: -13px;
            top: 4px;
          }

          > canvas {
            bottom: 12px !important;
            height: calc(100% - 36px) !important;
            top: 24px !important;
            max-width: 100%;
            object-fit: cover;
            object-position: left center;
          }
        }
      }
    }
  }

  // Transcript object =====================/

  &-object {
    padding: $padding-lg $padding-default;
    display: block;
    max-width: 778px;
    margin: 0 auto;

    .ProseMirror {
      outline: none;
      border: none;
    }

    span.current {
      display: inline-block;
      background-color: #FFC768;
      border-radius: 5px;
      font-weight: 700;
      padding: 0 5px;
      color: #000;
      ~ {
        opacity: .4;
      }
    }
  }

  &-paragraph {
    @include transition(.15s);
    color: $neutral-3;
    font-size: $txt-size-2;
    line-height: 1.5;
    margin-bottom: $margin-lg;

    &.active {
      color: $neutral-4;
    }

    .confidence-low {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        left: 5px;
        right: 0;
        top: 100%;
        background-color: $red-2;
        height: 2px;
      }
    }

    .find {
      color: $primary-4;
      background: $primary-2;
      border-radius: $border-radius-sm;
      padding: 0 1px;
      &.current {
        color: $neutral-0;
        background: $primary-4;
      }
    }
  }

  // Transcript timestamps =====================/

  &-timestamps {
    @include flexbox(row,center,initial);
    margin-bottom: 16px;
    min-height: 32px;
  }

  &-timestamp {
    @include inline-flexbox(row,center,center);
    font-size: $txt-size-1;
    color: $neutral-6;
    font-weight: 700;

    &.end { color: $neutral-4; font-weight: 400;}

    > span { font-weight: inherit;}

    .timings-input {
      &::-webkit-inner-spin-button{ display: none; }
      font-family: Circular;
      -moz-appearance:textfield;
      background-color: transparent;
      border-radius: $border-radius-sm;
      font-weight: inherit;
      @include transition(.15s);
      text-align: center;
      height: 20px;
      width: 20px;
      margin: 0;
      padding: 0;
      border: none;
      color: currentColor;

      @at-root .transcript-timestamp.invalid .timings-input:focus { background-color: $red-1; color: $red-3;}

      &:focus {
        background-color: $primary-1;
      }
    }
  }

  // Proofread ======================== /

  &-proofread {

    &-table {
      width: 100%;
      thead {
        pacity: .5;
        th {
          height: 32px;
          vertical-align: middle;
        }
      }

      tr {
        td {
          height: 32px;
          vertical-align: middle;
          position: relative;
        }
      }
    }

    &-total {
      @include flexbox(row,initial,initial);
      margin-bottom: $margin-default;
      .txt-muted {
        margin-right: auto;
        opacity: .5;
      }
    }

    &-payment {
      @include flexbox(row,center,space-between);
    }
  }
}
