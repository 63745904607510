// Side panel header

&-header {
  .side-panel-left & {
    background: $neutral-1;
    //position: sticky;
    //top: 0;

    &.top-sticky-blur {
      background: #F0F4F3;
      margin: 0 (-$padding-default);
      padding: $padding-default;

      .with-topbar & { top: 0;}

      &:has(.user-avatar.size-xl) {
        position: fixed;
        width: 350px;
        left: 64px;
        margin: 0;
        top: 0;

        .with-topbar & { top: 60px;}
        + .side-panel-body { padding-top: 255px;}
      }

      .nav-tabs { background: transparent;}
    }
  }

  &:has(.inner + .inner:last-child) { padding-bottom: 0.1px;}
  > .inner {
    @include flexbox(row,flex-start,flex-start);
    margin-bottom: $margin-default;

    a {
      @include inline-flexbox(row,center,flex-start);
      svg { margin-right: $margin-sm;}
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      min-height: 32px;
      cursor: pointer;

      .back-label {
        position: relative;
        //text-overflow: ellipsis;
        white-space: nowrap;
        overflow: auto;
        display: flex;

        &:after {
          content: "";
          background: linear-gradient(90deg, rgba(242,242,242,0) 0%, rgba(242,242,242,1) 100%);
          position: sticky;
          min-width: 15px;
          display: block;
          right: 0;

          .modal-body.bg-white & { display: none;}
        }
      }
    }

    + .inner {
      .side-panel-title { margin-top: -4px;}
    }
  }
  .top-label {
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;

    + .side-panel-title.mb-auto { display: none;}
  }

  &.sticky { position: sticky; top: 0; z-index: 2;}
}

&-privacy {
  @include flexbox(row,flex-start,initial);
  padding: $padding-default $padding-default $padding-default $padding-lg;
  max-width: 100%;

  .inner {
    min-width: 0;

    .txt-heading-small {
      @include flexbox(row,flex-start,space-between);
      margin-bottom: $margin-sm;
      word-break: break-word;
      min-height: 50px;
      width: 100%;
    }

    .btn-link-text-cta {
      .btn-icon { background: transparent;}
    }
  }

  .global-sidenav-favicon {
    min-width: 24px;
    height: 24px;
    width: 24px;

    img {
      object-position: center;
      object-fit: contain;
      width: 24px;
      height: 24px;
    }
  }
}
