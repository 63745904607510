.widget {
  &-search {
    @include transition(.2s);
    @include flexbox(row,initial,flex-start);

    border-radius: $border-radius-lg;
    font-size: $txt-size-1;
    position: relative;
    color: $neutral-6;
    flex: 280px 0 0;
    width: 280px;

    //&-icon {
    //  padding: 11px 13px;
    //}

    &.disabled {
      background: transparent;
      box-shadow: none;
    }

    .library-header & {
      //box-shadow: $shadow-small;
      background: $neutral-2;

      &.disabled {
        background: $neutral-2;
        pointer-events: none;
        box-shadow: none;

        .btn { color: $neutral-3 !important;}
        > input { color: $neutral-3 !important; @include placeholder { color: $neutral-3 !important;}}
      }
    }

    input {
      font-family: Circular;
      font-size: $txt-size-1;
      flex: 1 0 0;
    }

    @include hover() {
      background: rgba(58, 102, 217, 0.1);
      color: $primary-3;

      > .btn.btn-icon.default { color: $primary-3;}
      input{
        color: $primary-3;
        @include placeholder() { color: $primary-3; transition: .2s;}
      }
    }

    &.expanded {
      //box-shadow: $shadow-small;
      background: $neutral-2;

      width: 100%;
      flex: 1 0 0;
    }

    &-append {
      @include flexbox(row,center,center);
      font-size: $txt-size-1;
      padding-right: 13px;
      color: $neutral-3;
      position: absolute;
      bottom: 0;
      right: 0;
      top: 0;
    }
  }

  &-nav {
    @include flexbox(row, center, space-between);
    border-bottom: 1px solid $neutral-2;
    @include transition(.2s);
    background: rgba($neutral-0,1);
   //backdrop-filter: blur(8px);
    //overflow: hidden;
    height: 72px;
    position: sticky;
    z-index: 1001;
    width: 100%;
    top: 0;

    &-left {
      @include flexbox(row,center,initial);
      position: absolute;
      padding: $padding + $padding-sm;
      height: 100%;
      left: 0;
      top: 0;
      > .btn { margin-right: $margin;}
    }
    &-right {
      @include flexbox(row,center,initial);
      overflow: hidden;
      position: absolute;
      padding: $padding + $padding-sm;
      height: 100%;
      right: 0;
      top: 0;
      > .btn { margin-left: $margin;}
    }
    &-center {
      @include inline-flexbox(row,center,center);
      margin: auto;
    }
  }
  &-editor {
    .layout-split-right {
      box-shadow: $shadow-big;
    }
    &-preview {
      min-height: calc(100vh - 72px);
      &.preview-mobile {
        overflow: auto;
        padding: $padding-default;
        @include flexbox(initial, center, center);
        iframe {
          border-radius: $border-radius-lg;
          box-shadow: $shadow-small;
          width: 375px;
          height: 612px;
        }
      }
      .btn {
        position: absolute;
        left: 30px;
        top: 30px;
      }
    }
    &-body {
      min-height: calc(100vh - 72px);
      transition: padding-top .2s;
      background: $neutral-0;
      position: relative;
      padding-top: 0;
    }
    &-loader {
      .anim-rect {
        position: relative;
        display: block;
        box-sizing: border-box;
        border-radius: $border-radius-md 0 0 $border-radius-md;
        border: 2px solid $neutral-2;
        border-right: none;
        height: 18px;
        width: 31px;
        margin-top: 10px;
        animation: transcript-timeline 4.8s both ease-in-out infinite;

        &:after {
          content: "";
          display: block;
          width: 2px;
          border-radius: 2px;
          perspective: 1px;
          overflow: hidden;
          height: 32px;
          background: $primary-4;
          position: absolute;
          right: 0;
          top: -8px;
        }
      }
    }
  }
}
#widget-embed-dialog {
  .popup-script {
    textarea {
      height: 185px;
    }
  }
}
