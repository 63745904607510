.chapter-time {
    @include flexbox(row, center, center);
    box-sizing: border-box;
    border-radius: $border-radius-md;
    padding: 7px;
  
    &.muted {
      // pointer-events: none;
      border-color: $neutral-2 !important;
      color: $neutral-2 !important;
      box-shadow: none !important;
  
      > input { color: $neutral-2 !important;}
    }
    span {
      margin: 0 2px;
      flex: 0 !important;
    }
    input {
      max-width: 15px;
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
    input[type="number" i] {
      padding: 0;
      color: $neutral-4 !important;
    }
    &.error {
      box-shadow: $shadow-hover-red;
      border-color: $red-3;
    }
  }