$padding-sm: 4px;
$margin-sm: 4px;

$padding: 8px;
$margin: 8px;

$padding-md: 12px;
$margin-md: 12px;

$padding-default: 16px;
$margin-default: 16px;

$padding-lg: 24px;
$margin-lg: 24px;

$padding-lg-extra: 32px;
$margin-lg-extra: 32px;