.stats {

  &-panel {
    background-color: $neutral-0;
    box-shadow: $shadow-small;
    padding: 0 $padding-default;
    @include transition(.5s);
    overflow: auto;
    position: fixed;
    bottom: 0;
    top: 0;
    right: 0;
    width: 350px;
    z-index: 2;

    &.is-expand {
      width: 607px;
    }
  }

  &-backdrop {
    content: "";
    background: $modal-backdrop-bg;
    transition: opacity .5s, right .5s;
    pointer-events: none;
    pointer-events: none;
    display: block;
    position: fixed;
    top: 0;
    right: 350px;
    bottom: 0;
    width: 0;
    opacity: 0;
    z-index: 1002;

    &.active {
      opacity: $modal-backdrop-opacity;
      pointer-events: all;
      width: auto;
      right: 607px;
      left: 0;
    }
  }

  .side-panel-header {
    padding-top: $padding-default;
    background: $neutral-0;
    position: sticky;
    z-index: 2;
    top: 0;
    .inner {
      justify-content: space-between;
      margin: 0;
    }
  }

  &-graphs {
    margin-top: $margin-default * 2;
    padding-bottom: $padding-default;
    @include flexbox(column,flex-start,flex-start);
    min-height: calc(100% - 122px);

    > .btn-block { margin-top: auto;}
    &__placeholder {
      @include flexbox(column,center,initial);
      margin: 0 auto;
      margin-top: auto;
      text-align: center;
    }
  }

  &-card {
    width: 100%;
    @include flexbox(column,flex-start,flex-start);
    min-height: 160px;
    margin: auto 0;

    .title-and-info {
      @include flexbox(row, center, space-between);
      padding-right: $padding-default;
      width: 100%;

      .title {
        @include flexbox(row, center, initial);
        .icon-box {
          @include flexbox(row, center, center);
          background-color: $neutral-1;
          margin-right: $margin;
          padding: 12px;
          border-radius: $border-radius-lg;
          color: $neutral-3;
        }

        &.is-lock {
          svg,
          span {
            color: $neutral-3;
          }
        }
      }

      .count {
        position: relative;
        .stat-figure {
          @include flexbox(row, center, flex-end);
          .txt-title {
            margin-left: $margin / 2;
            margin-bottom: 0;
          }
          .txt-heading-small { margin: 0;}
        }

        .stats-direction {
          @include inline-flexbox(row,center,initial);
          margin-right: 3px;
        }

        .stat-label {
          display: block;
          text-align: right;
          position: absolute;
          right: 0;
          top: 100%;
          width: max-content;
        }
      }
    }
    &__body {
      position: relative;
      width: 100%;
      &.is-lock {
        .apexcharts-canvas {
          pointer-events: none;
          opacity: .3;
          filter: grayscale(1);
        }
        .stat-chart {
          .tooltip {
            top: 50px !important;
          }
        }
      }
    }
  }
}
