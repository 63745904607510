.tmp-mobile {
  @include flexbox(column,flex-start,flex-start);
  padding: $padding-lg + $padding;
  background: $neutral-0;
  position: fixed;
  overflow: auto;
  z-index: 4000;
  display: none;
  height: 100%;
  width: 100vw;
  left: 0;
  top: 0;

  @include media-breakpoint-down(lg) { display: flex;}
  @include media-breakpoint-down(xs) { padding: $padding-lg;}

  &-logo { min-height: 30px;}
  &-device { min-height: 111px;}

  .txt-title-small { max-width: 380px;}
  .txt-body { max-width: 300px;}
  .btn {
    @include media-breakpoint-up(md) { max-width: 300px;}
  }

  ~ .main, ~ .global-sidenav {
    @include media-breakpoint-down(lg) { display: none;}
  }
}
