.form-control-button-size {
  background: $neutral-1;
  border-radius: $border-radius-lg;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 6px;
  padding: 6px;
  width: 100%;

  .btn-tab-nav {
    padding: $padding-default $padding-default $padding;
    border-radius: $border-radius-md;
    border: 1px solid transparent;
    flex-direction: column;
    color: $neutral-6;

    > [class*='drop-shadow'] {
      @include flexbox(row, center, center);
      border-radius: $border-radius-md;
      background: $neutral-0;
      height: 48px;
      width: 48px;

      &.drop-shadow-small {
        box-shadow: $shadow-small;
      }
      &.drop-shadow-medium {
        box-shadow: $shadow-mid;
      }
      &.drop-shadow-large {
        box-shadow: $shadow-big;
      }
    }

    @include hover() {
      color: $primary-3;
    }
    &.active {
      color: $primary-3;
      border-color: $primary-3;
    }
  }
}
