.upload-image {
  position: relative;
  .thumb {
    margin-bottom: $margin;
    .thumb-preview {
      position: relative;
      height: 152px;
      background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2 2'%3E%3Cpath d='M1 2V0h1v1H0v1z' fill-opacity='.05'/%3E%3C/svg%3E");
      background-color: rgba(242, 242, 242, 0.3);
      @include flexbox(row,center,center);
      background-position: center center;
      border-radius: $border-radius-lg;
      border: 1px solid $neutral-2;
      background-size: 20px auto;
      background-repeat: repeat;
      img {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
        object-position: center;
        z-index: 1;
      }
      &:before {
        content: "";
        position: absolute;
        z-index: 2;
        pointer-events: none;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
        top: 0;
        left: 0;
        right: 0;
        bottom: 50%;
        @include transition(.15s);
        border-radius: $border-radius-sm;
        overflow: hidden;
        opacity: 0;
      }
      &:hover {
        &:before { opacity: 1;}
        .upload-image__remove { opacity: 1;}
      }
    }
    &-change {
      .btn-canva {
        svg {
          fill: $neutral-0 !important;
        }
      }
    }
  }
  &__hint {
    margin-bottom: 8px;
  }
  .btn-upload {
    position: relative !important;
    input {
      appearance: none;
      background: transparent;
      width: 100%;
      position: absolute;
      cursor: pointer;
      opacity: 0;
      bottom: 0;
      right: 0;
      left: 0;
      top: 0;
    }
  }
  &__remove {
    opacity: 0;
    z-index: 3;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .txt-or {
    text-align: center;
    display: block;
    position: relative;
    line-height: 18px;
    font-size: 1.1rem;
    color: $neutral-4;
    overflow: hidden;

    &:before {
      content: "";
      display: block;
      position: absolute;
      right: 50%;
      margin-right: 2rem;
      left: 0;
      top: 8px;
      height: 1px;
      background: $neutral-2;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      margin-left: 2rem;
      right: 0;
      top: 8px;
      height: 1px;
      background: $neutral-2;
    }
  }
}

.favicon-preview {
  margin-bottom: $margin;

  .tab-holder {
    background: #D7E0D8;
    padding: 1px 0 0;
    border-radius: 10px 10px 0 0;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      left: 60%;
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      z-index: 2;
      pointer-events: none;
    }

    &:after {
      content: '';
      display: block;
      height: 37px;
      width: 100%;
      background: rgb(250, 250, 250);
      background: linear-gradient(to bottom, rgba(250, 250, 250, 1) 0%, rgba(255, 255, 255, 1) 100%);
    }
    .tab-content {
      margin: 10px 35px 0 35px;
      width: auto;
      display: flex;
      align-items: center;
      background: #fafafa;
      min-width: 200px;
      width: fit-content;
      padding: 9px 15px;
      border-radius: 10px 10px 0 0;
      position: relative;
      max-width: calc(100% - 70px);

      &:before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 0 0 10px 0;
        overflow: hidden;
        background: #D7E0D8;
        bottom: 0;
        left: -20px;
        box-shadow: 5px 5px 0px 5px #fafafa;
      }
      &:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 0 0 0 10px;
        overflow: hidden;
        background: #D7E0D8;
        bottom: 0;
        right: -20px;
        box-shadow: -5px 5px 0px 5px #fafafa;
      }

      img {
        min-width: 16px;
        width: 16px;
        height: 16px;
        object-fit: contain;
        object-position: center;
        margin-right: 10px;
      }
      span {
        white-space: nowrap;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 400;
        font-size: 1.4rem;
      }
    }
  }
}
