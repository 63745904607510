.custom-switch {
  position: relative;
  min-height: 24px;
  min-width: 48px;

  .toggle-large & {
    min-height: 28px;
  }

  .custom-control-input {
    position: absolute;

    &:not(:disabled) + .custom-control-label {
      @include hover() {
        &:before {
          background: $primary-2;
          border-color: $primary-4;
          box-shadow: $shadow-hover;
        }
        &:after { background: $primary-4;}
      }
    }

    &:checked:not(:disabled) + .custom-control-label, &:checked[readonly] + .custom-control-label {
      &:after {
        background: $primary-2;
        right: 3px;
      }
      &:before {
        background: $primary-3;
        border-color: $primary-3;
      }
      @include hover() {
        &:before {
          background: $primary-4;
          border-color: $primary-4;
        }
      }
    }

    &[readonly] + .custom-control-label {
      cursor: initial;
    }

    &:disabled:not([readonly]) + .custom-control-label {
      pointer-events: none;
      color: $neutral-2;
      cursor: initial;

      &:before {
        background: $neutral-1;
        border-color: $neutral-2;
      }
      &:after {
        background: $neutral-2;
      }
    }
  }

  .custom-control-label {
    cursor: pointer;

    &:before {
      box-shadow: none;
      transition: .2s;
      border-radius: 12px;
      border: 1px solid $neutral-2;
      position: absolute;
      display: block;
      height: 22px;
      width: 46px;
      content: "";
      left: auto;
      right: 0;
      top: -1px;
    }

    &:after {
      transition: .2s;
      border-radius: $border-radius-xl;
      --corner-smoothing: 60%;
      background: $neutral-3;
      position: absolute;
      display: block;
      height: 18px;
      content: "";
      width: 18px;
      right: 26px;
      left: auto;
      top: 2px;
      transform: none;
    }

    &-inner { padding-right: 60px;}
  }


  &.muted {
    cursor: initial;

    .custom-control-label {
      pointer-events: none;
      color: $neutral-2;

      &:before {
        background: $neutral-1;
        border-color: $neutral-2;
      }
      &:after {
        background: $neutral-2;

        .toggle-right & { right: 3px;}
      }
    }
  }
}
