&-collapse {
  @include flexbox(column,initial,initial);
  @include transition(.15s);
  background: $neutral-0;
  border-radius: $border-radius-lg;
  box-shadow: $shadow-small;
  margin: 0 auto $margin-default;
  padding: 12px 12px 12px 16px;
  min-height: 92px;
  overflow: hidden;
  width: 100%;

  &.can-hover {
    @include hover() {
      background: $primary-1;
      box-shadow: $shadow-big;
      color: $primary-4;
      .txt-muted-2 {
        color: $primary-2;
      }
    }
  }

  .slot-middle {
    .alert {
      padding: $padding-default - $padding-sm;
    }
  }

  .slot-top {
    @include flexbox(row,flex-start,space-between);

    .txt-heading-small {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 50px);
    }
  }

  .slot-bottom {
    @include flexbox(row,flex-end,space-between);
    margin-top: auto;

    .txt-body {
      @include flexbox(row,center,center);
    }
  }
}
