.list-card-folder {
  background: $neutral-0;
  box-shadow: $shadow-small;
  border-radius: $border-radius-lg;
  padding: 13px;
  margin-bottom: $margin;
  cursor: pointer;
  @include transition(.15s);

  &:hover {
    background: $primary-1;
    box-shadow: $shadow-big;
    color: $primary-3;
    .txt-heading, .txt-body, .txt-body-small { color: $primary-3;}
  }
  &-content {
    @include flexbox(row,center,initial);
    &-left {
      @include flexbox(row,center,initial);
      min-width: 200px;
      width: 240px;
      .txt-heading-small {
        margin-bottom: 0;
      }
    }
    &-center {
      margin-right: 30px;
    }
    &-right {
      margin-right: 80px;
    }
  }
}