// Sidepanel option folder / extension of .side-panel

&-option-folder {
  @include flexbox(row,center,flex-start);
  border-radius: $border-radius-lg;
  @include transition(.15s);
  box-shadow: $shadow-small;
  background: $neutral-0;
  padding: $padding-sm $padding $padding-sm $padding-default;
  margin: 0 0 1px;
  cursor: pointer;
  position: relative;
  min-height: 48px;

  &.disabled {
    background: rgba($neutral-0, 0.5);
    box-shadow: none;
    color: $neutral-3 !important;
    cursor: initial;

    .slot-left {
      color: $neutral-3;
    }
  }

  &:not(.disabled) {
    @include hover() {
      background: $primary-1;
      color: $primary-4;

      .txt-muted { color: $primary-3;}
      .handle { color: $primary-3;}
    }
  }

  .slot-left {
    @include flexbox(row,center,flex-start);
    margin: 0 $margin 0 0;
    padding: $padding-sm $padding-sm $padding-sm 0;
    color: $neutral-4;
  }

  .slot-right {
    @include inline-flexbox(row,center,flex-end);
    padding: 0 0 0 $margin-default;
    margin: 0 0 0 auto;

    .icon-sm.mx-2 { margin-left: 6px !important;}
    .popover-toggle { order: 5;}
  }

  .option-tooltip {
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 10;
  }

  .txt-heading-small {
    margin-bottom: 0;
    white-space: nowrap;
    display: block;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .skeleton-item { display: flex; }

  // Line indicator =================/

  &:before {
    content: "";
    overflow: hidden;
    border-radius: 0 3px 3px 0;
    background: $primary-3;
    position: absolute;
    height: 26px;
    width: 0;
    top: 11px;
    left: 0;

    @at-root .side-panel-option-folder.active:before { width: 3px;}
  }

  // If can be hovered have dropdown on hover

  @media (hover: hover) {

    .popover-toggle {
      margin-right: -$margin-sm;
      margin-left: $margin-sm;
      display: none;
      order: 2;

      &[aria-describedby] {
        display: inline-flex;

        + svg { display: none;}
      }
    }

    &:hover {
      .popover-toggle {
        display: inline-flex;
        + svg { display: none;}
      }
      .slot-left { color: $primary-4;}
    }
  }

  // Variant muted left icon

  &.variant-secondary {
    .slot-left {
      color: $neutral-3;
    }
  }
  &.active.new-item {
    @at-root .library-accordion-body .side-panel-option-folder.active.new-item {
      animation-name: blink;
      animation-duration: 3s;
      @keyframes blink {
        0% {
          background: $orange-1;
        }
        25% {
          background: $neutral-0;
        }
        50% {
          background: $orange-1;
        }
        75% {
          background: $neutral-0;
        }
        100% {
          background: $orange-1;
        }
      }
    }
  }
}
