.global-topnav {
  @include flexbox(row, center, space-between);
  border-bottom: 1px solid $neutral-2;
  background-blend-mode: multiply;
  background: rgba(#fff,1);
  //backdrop-filter: blur(8px);
  @include transition(.2s);
  padding: 0 $padding;
  position: sticky;
  z-index: 1001;
  height: 64px;
  width: 100%;
  top: 0;

  &-left {
    @include flexbox(row,center,initial);
    position: absolute;
    padding: $padding;
    left: 0;
    top: 0;
    > .btn { margin-right: $margin;}
  }

  &-right {
    @include flexbox(row,center,initial);
    position: absolute;
    padding: $padding;
    right: 0;
    top: 0;
    > .btn { margin-left: $margin;}
    .tooltip {
      .tooltip-inner{
        max-width: 400px;
        white-space: nowrap;
      }
    }
  }
  &-center {
    margin: auto;
    b {
      max-width: 500px;
    }
    .txt-no-wrap {
      
      @at-root .side-panel.side-panel-left ~ .layout-split-right .global-topnav-center .txt-no-wrap { max-width: calc(100vw - 900px);}
      @at-root .side-panel.side-panel-left.active ~ .layout-split-right .global-topnav-center .txt-no-wrap { max-width: calc(100vw - 1200px);}
    }
  }
  &-favicon {
    width: 45px;
    height: 45px;
    object-fit: contain;
  }
}

.pages-selector {
  border-radius: $border-radius-lg;
  margin-top: $margin-default;
  width: 320px;
  left: 50% !important;
  margin-left: -160px !important;

  &-group {
    @include scrollbar-thin;
    max-height: 240px;
    overflow: auto;
  }

  .b-dropdown-text {
    @include flexbox(row,center,space-between);
    padding: $padding-default ($padding-default + $padding);

    [class*="txt"] { margin: 0;}

    &[role="link"] { justify-content: flex-start;}

    .txt-heading-small {
      word-break: break-word;
    }
  }

  .dropdown-item {
    @include transition(.15s);
    min-height: 48px;
    font-size: 1.7rem;
    font-weight: 400;
    color: $neutral-6;
    padding: $padding-md $padding-lg $padding-md 30px;
    position: relative;

    > svg:not(.homepage) {
      margin-right: 12px;
      min-width: 24px;

      &.icon-sm { min-width: 18px;}
    }

    > svg.homepage {
      position: absolute;
      right: 28px;
      top: 50%;
      transform: translateY(-50%);
    }

    .txt-heading-small {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0;
    }

    &:not(.text-warning) {
      @include hover() {
        color: $primary-4;
        background: $primary-1;
        > svg:not(.homepage) { color: $primary-4 !important;}
      }
    }

    &:hover {
      .homepage {
        display: none;
      }
    }

    &.active {
      color: $primary-4;
      background: none;
      > svg:not(.homepage) { color: $primary-4 !important;}
    }

    &.disabled {
      pointer-events: none;
      color: $neutral-3;
    }

    @media (hover: hover) {

      .popover-toggle {
        opacity: 0;
      }

      &:hover {
        .popover-toggle {
          opacity: 1;
        }
      }
    }
  }
}
