.account-settings {
  background: $neutral-0;
  margin: 0 auto;
  height: 100%;
  width: 100%;

  &-template-header {
    .wrapper-md {
      padding-top: $padding-default;
      min-height: 80px;
    }
    &:after { opacity: 1; background: $neutral-2;}

    .account-settings & {
      + .wrapper-md:not(:has(.plans-wrapper)):not(:has(.subscription-grid)) {
        .top-sticky-blur { top: 128px; .with-topbar & { top: 188px;}}

        .ag-header {
          background: rgba(255, 255, 255, 1);
          //backdrop-filter: blur(8px);
          position: sticky;
          z-index: 3;
          top: 196px;
          .with-topbar & { top: 256px;}
        }
      }

      &:has(.txt-body) {
        + .wrapper-md:not(:has(.plans-wrapper)):not(:has(.subscription-grid)) {
          .top-sticky-blur { top: 103px; .with-topbar & { top: 163px;}}
  
          .ag-header {
            top: 171px;
            .with-topbar & { top: 231px;}
          }
        }
      }
    }
  }

  .nav-tabs-lined { max-width: 777px;}

  .slot {
    &-left {
      max-width: 360px;
      width: 100%;
    }
    &-right {
      width: 100%;
      .iframe-holder {
        border-radius: 10px;
      }
    }
  }
  &-template-details {
    a {
      @include inline-flexbox(row,center,initial);
      svg {
        margin-right: 8px;
      }
    }
  }

  // usage

  .my-4 {
    //margin-bottom: 0 !important;
    .limit {
      em {
        font-style: normal;
      }
      .wizard__progress-reverse {
        margin-bottom: 35px;
      }
    }
  }

  // Player
  &[data-title="Player"] {
    .slot-right { top: 191px; position: sticky; .with-topbar & { top: 251px;}}
  }

  &-privacy {
    .form-control-text {
      svg {
        color: $primary-3;
      }
    }
  }
  .top-title {
    h5 {
      margin-bottom: 0;
    }
  }

  &-template-buttons {
    display: flex;
    button {
      margin-left: 5px;
    }
  }

  // Invoice

  &[data-title="Your Invoices"] {

    .side-panel-group-empty p {
      max-width: 600px;
    }
    .nav-tabs-lined { max-width: 250px;}
    table {
      width: 100%;
      tr {
        display: grid;
        grid-template-columns: 1fr 1fr 80px;
      }
      td, th {
        @include inline-flexbox(initial, center, flex-start);
        &:nth-of-type(3) {
          justify-content: center;
        }
        &:first-of-type {
          margin-left: $margin-lg;
        }
      }
      th {
        height: 40px;
      }
      tbody {
        tr {
          width: 100%;
          height: 68px;
          border-bottom: 1px solid $neutral-2;
          td {
            vertical-align: middle;
            h5 {
              margin-bottom: 0;
            }
          }
        }
        tr:first-of-type {
          border-top: 1px solid $neutral-2;
        }
        tr:hover {
          background: $primary-1;
          color: $primary-4;
          td {
            span {
              color: $primary-4;
            }
          }
        }
      }
    }
  }

  // avatar

  &-meta {
    @include flexbox(column,center,center);
    .txt-title-small {
      text-align: center;
    }
  }
  &-avatar {
    width: 140px;
    height: 140px;
    margin: 0 auto;
  }

  // Subscription

  @at-root .plans {
    //display: grid;
    //grid-template-columns: 1fr 1fr 1fr;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    column-gap: 16px;
    //@include media-breakpoint-down(sm) { column-gap: 8px;}

    &-inner {
      @include flexbox(column,initial,flex-start);
      //width: fit-content;
      .plans {flex: 1 0 0;}
    }

    &-scroller {
      margin: 0 -24px;
      padding: 40px $padding-lg $padding-lg;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      @include media-breakpoint-down(sm) { padding-bottom: $padding-default;}

      &-inner {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: auto;
        width: fit-content;
        margin: 0 auto;
      }
    }

    &-wrapper {
      .account-settings.p-4 > & { margin-bottom: -$padding-lg;}

      &-inner {
        position: relative;
      }
      @at-root .plan-card {
        &-full {
          box-shadow: $shadow-small;
          background: $neutral-0;
          padding-bottom: 10px;
          border-radius: $border-radius-lg;
          .account-settings-table {
            margin: 0 48px;
            .ag-header {
              border-bottom: 1px solid $neutral-2;
            }
          }
          [col-id="price"] .ag-header-cell-label {
            margin-left: auto;
          }
          .ag-center-cols-container {
            position: relative;
            .ag-row {
              border-bottom: hidden;
              &:after { display: none;}
              &:only-child {bottom: -8px; &:before { display: none;}};
              &:last-child:before {
                content: ''; 
                position: absolute;
                bottom: -10px;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: $neutral-2;
              }
              &:last-child:after { display: block; bottom: -8px; left: 0; right: 0;}
            }
            .ag-cell-details-text {
              .txt-body {-webkit-line-clamp: initial}
              .txt-heading, .txt-heading-small { 
                margin-bottom: 0;
              }
              .txt-heading-small {color: $neutral-4;}
            }
          }
          &--cancelled {
            .plan-card-highlight {
              background: $red-3;
            }
          }
          .alert {
            margin: 0 48px;
            &::before {
              content: ''; 
              position: absolute;
              top: -25px;
              left: 0;
              width: 100%;
              height: 1px;
              background-color: $neutral-2;
            }
          }
          &--trial {
            .plan-card-left {}
          }
        }
        &-highlight {
          border-radius: $border-radius-lg 0 $border-radius-lg 0;
          background: $green-3;
          padding: $padding $padding-default;
          color: $neutral-0;
          display: inline-flex;
        }
        &-top {
          width: 100%;
          @include flexbox(initial,flex-start,space-between);
          padding: 0 48px;
          .plan-card-left {
            max-width: 460px;
          }
          .plan-card-right {
            background: $neutral-1;
            border-radius: $border-radius-lg;
            margin-right: 0;
            .btn {
              @include flexbox(column,flex-start,initial);
              padding: 4px 9px;
              color: $neutral-4;
              font-weight: 450;
              min-width: 66px;
              font-size: 11px;
              line-height: 17px;
            }
          }
        }
        &-left {
          .txt-heading-small {
            margin-bottom: 0;
          }
        }
        &-right {
          text-align: right;
          margin-right: 48px;
        }
        &-bottom {
          @include flexbox(initial, center, space-between);
          padding: 38px 0 48px;
          .original-price {
            text-decoration: line-through;
          }
        }
        &-table {
          width: 100%;
          tr { border-bottom: 1px solid $neutral-2; text-align: left;}
          th { color: $neutral-3;}
          td { vertical-align: middle;}
        }
      }
    }
    
    &-details {
      @include flexbox(initial, initial, center);
      max-width: 1055px;
      overflow: hidden;
      margin: 0 auto;
      
      button {
        position: relative;
        &::before, &::after {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          height: 1px;
          width: 9999px;
          background: $neutral-2;
        }
        &::before {
          right: calc(100% + 20px);
        }
        &::after {
          left: calc(100% + 20px);
        }
      }
    }

    &-arrows {
      @include media-breakpoint-down(md) { display: none;}
      @include flexbox(row,center,center);
      column-gap: $padding;
      position: absolute;
      right: 0;
      top: 0;

      .plans-wrapper-full & { top: -$margin-lg;}
    }

    &-dots {
      @include media-breakpoint-up(lg) { display: none;}
      @include flexbox(row,center,center);
      margin-bottom: $margin-default;
      position: relative;
      min-height: 34px;
      width: 100%;
      bottom: 0;
      left: 0;
    
      .glider-dot {
        background: $neutral-3;
        transition: .2s;
        opacity: .5;
        margin: 9px;
        height: 8px;
        width: 8px;
  
        &.active {
          background: $primary-3;
          width: 16px;
          height: 16px;
          opacity: 1;
        }
      }
    }
  }


  &[data-title="Cancel Subscription"] {
    // max-width: 1050px;
    width: 100%;
    form {
      .input-group {
        display: initial;
      }
    }
  }

//  Payment

  &[data-title="Payment"] {
    width: 800px ;
    .top-card {
      .icon-card {
        &.active {
          position: relative;
          svg.active {
            position: absolute;
            top: -5px;
            right: -5px;
          }
        }
      }
    }
    .payment-method {
      & > strong {
        @include inline-flexbox(initial, center, initial);
      }
      .input-group {
        .invalid-feedback {
          border-radius: $border-radius-sm;
          padding: 16px;
          border: none;
          color: #c12626;
          background: #ffdada;
          width: 100%;
        }
      }
      .input-group:not(.has-validation) > .form-control:not(:last-child) {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

// Transcripts

  &[data-title="Transcripts"] {
    .pagination-wrap {
      position: relative;
      .pagination {
        border-bottom: none;
      }
      .pagination-counter {
        position: absolute;
        right: 0;
        top: calc(50% - 11px);
      }
    }
    .side-panel-inner-content {
      .side-panel-link-row {
        &:hover {
          box-shadow: $shadow-hover;
          border-color: $primary-4;
          color: $neutral-6;
          outline: 0;
        }
      }
      .form-control-select {
        .multiselect__tags {
          &:hover {
            box-shadow: $shadow-hover;
            border: 0.5px solid $primary-4;
            border-radius: $border-radius-md;
          }
        }
      }
      .with-actions {
        .input-group-append ~ input { padding-right: 75px;}
      }
    }
  }

  // Data

  &[data-title="Data"] {
    position: relative;
    max-width: 100%;

    &::before {
      content: "";
      box-shadow: inset -4px 0px 4px -4px rgba(0, 0, 0, 0.05), inset -16px 0px 16px -16px rgba(0, 0, 0, 0.03);
      pointer-events: none;
      position: absolute;
      display: block;
      height: 100%;
      width: 32px;
      left: -32px;
      z-index: 1;
      top: 0;

      .hub-editor &, .hub-templates &, .transcript-editor &, .widget-editor & { left: 318px;}
      .main-library & { display: none;}
    }

    .nav-tabs-lined { max-width: 470px;}
  }

  // Webhooks

  &[data-title="Webhooks"] {
    max-width: 100%;
    // .nav-tabs-lined { max-width: 170px;}
  }

  // Subscription

  &[data-title="Subscriptions"] {
    background: #f8f8f8;
    .nav-tabs-lined { max-width: 300px;}

    .side-panel-group-empty p {
      max-width: 600px;
    }

    .wrapper-md:has(>.plans-wrapper),
    .wrapper-md:has(.subscription-grid) {
      max-width: 100%;
      .plans-wrapper { max-width: 1072px; margin: 0 auto; padding: 24px 16px;}
      .subscription-grid { max-width: 1072px; margin: 0 auto; padding: 0 16px; .ag-header-cell:first-child { padding-left: 16px;}}
    }
  }
  // Team

  &[data-title="Team"] {
    .ag-row {
      .ag-cell {
        .btn.warning, .btn-icon {opacity: 0;}
        .btn.muted.btn-link {opacity: 0.5;}
      }
      @include hover() { 
        .ag-cell {
          .btn.warning, .btn-icon {opacity: 1;}
        }
      }
    }
    .ag-header-cell:last-child .ag-cell-label-container {
      justify-content: center;
    }
    .account-settings-template-header + .wrapper-md .top-sticky-blur {
      top: 132px !important;
      &::after {
        display: none !important;
      }
    }
    .ag-header {
      top: 180px !important;
    }
    .team {
      &-skeleton {
        @include flexbox(row,center,flex-start);
        border-bottom: 1px solid $neutral-1;
        padding-left: 20px;
        background: $neutral-0;
        position: relative;
        min-height: 84px;
        overflow: hidden;
    
        &:before {
          content:'';
          transform: skewX(-45deg);
          background-image: linear-gradient(90deg, rgba(255,255,255,0) 0px, rgba(255,255,255,.6) 80px, rgba(255,255,255,.6) 120px, rgba(255,255,255,0) 200px);
          position: absolute;
          background-repeat: repeat-x;
          left: -400px;
          width: 200px;
          height: 100%;
          animation: shine 3s infinite;
        }
    
        .skeleton {
          &-lines-date { flex: 1 1 0;}
          &-avatar {
            margin-right: $margin;
            background: $neutral-2;
            border-radius: 50%;
            min-width: 48px;
            display: block;
            height: 48px;
          }
          &-title {
            background: $neutral-2;
            margin-bottom: $margin-default;
            border-radius: 2px;
            display: block;
            width: 300px;
            height: 8px;
          }
          &-subtitle {
            background: $neutral-2;
            border-radius: 2px;
            display: block;
            width: 180px;
            height: 8px;
          }
          &-date {
            background: $neutral-2;
            border-radius: 2px;
            display: block;
            width: 70px;
            height: 8px;
            margin: 0 auto;
          }
        }
      }
    }
  }
}
.wrapper-bottom {
  @include flexbox(row, center, flex-end);
  margin: 0 auto 55px;
  transition: .3s;
  width: 100%;

  &.condensed { width: 200px;}

}
.card-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  a {
    @include inline-flexbox(initial, center, initial)
  }
}


.account-settings-table {
  // margin-left: -$margin-lg;
  // margin-right: -$margin-lg;
  .ag {
    // &-header-cell:first-of-type {
    //   padding-left: $padding-lg;
    // }
    &-cell {
      overflow: visible;
      padding: 0;
    }
    &-header-cell-inner {
      padding-left: 0;
      .ag-header-cell-label {
        flex: initial;
      }
      .customSortDownLabel {
        margin-top: 5px;
      }
    }
    &-row {
      padding: 0 $margin-lg;
    }
    &-root {
      &-wrapper {
        overflow: visible;
      }
      &.ag-layout-auto-height,
      &.ag-layout-normal { overflow: visible;}
    }
    &-body-viewport { overflow: visible;}
    &-center-cols-clipper { overflow: visible;}
    &-center-cols-viewport { overflow: visible;}
  }
}

.account-settings-table {
  &-skeleton {
    @include flexbox(row,center,flex-start);
    border-bottom: 1px solid $neutral-1;
    background: $neutral-0;
    position: relative;
    min-height: 80px;
    overflow: hidden;

    &:before {
      content:'';
      transform: skewX(-45deg);
      background-image: linear-gradient(90deg, rgba(255,255,255,0) 0px, rgba(255,255,255,.6) 80px, rgba(255,255,255,.6) 120px, rgba(255,255,255,0) 200px);
      position: absolute;
      background-repeat: repeat-x;
      left: -400px;
      width: 200px;
      height: 100%;
      animation: shine 3s infinite;
    }

    .skeleton {
      &-title {
        background: $neutral-2;
        margin-bottom: 18px;
        border-radius: 2px;
        display: block;
        width: 168px;
        height: 8px;
      }
      &-subtitle {
        background: $neutral-2;
        margin-bottom: 12px;
        border-radius: 2px;
        display: block;
        width: 275px;
        height: 8px;
      }
      &-subtitle-small {
        background: $neutral-2;
        border-radius: 2px;
        display: block;
        width: 215px;
        height: 8px;
      }
      &-date {
        background: $neutral-2;
        border-radius: 2px;
        display: block;
        width: 76px;
        height: 8px;
      }
    }
  }
}

.account-plans {
  &-item {
    @include flexbox(column, initial, space-between);
    box-shadow: $shadow-small;
    background: $neutral-0;
    border-radius: $border-radius-lg;
    position: relative;
    min-width: 340px;
    padding: 48px;
    height: 100%;
    @include media-breakpoint-down(xs) {
      min-width: calc(100vw - 52px);
      .nav.nav-tabs.no-events { display: none !important;}
    }
    .auth-page-content & { box-shadow: $shadow-big;}

    &-inner {
      @include flexbox(column, initial, flex-start);
      flex: 1 0 0;
    }
    
    &-cta {
      .account-plans-item-lg & { min-width: 300px; max-width: 50%;}

      > .btn {
        text-transform: capitalize;
        margin-top: 40px;
        color: $neutral-0;
        background: var(--background-color);
        min-height: 49px;
        line-height: 1.2;
        padding: 6px $padding-default;
        white-space: normal;

        @include hover() { box-shadow: var(--shadow);}

        .account-plans-item-lg & { margin-top: 32px;}
      }
    }

    &.recommended-plan {
      .account-plans-highlight { background: var(--color-light); color: var(--color);}
    }

    &.active-plan {
      border-radius: 0 0 $border-radius-lg $border-radius-lg;
      position: relative;

      .account-plans-item-inner {
        position: relative;
        z-index: 1;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        border: 2px solid var(--color);
        border-top: 0;
        width: calc(100% - 4px);
        height: calc(100% - 2px);
        border-radius: 0 0 $border-radius-lg $border-radius-lg;
        z-index: 0;
      }

      .account-plans-item-cta {
        > .btn {
          background: $neutral-1;
          color: $neutral-4;
          border: 1px solid $neutral-2;
          span { display: flex; align-items: center;}
        }
      }
      .account-plans-highlight { background: var(--background-color); color: $neutral-0;}
    }

    // Large variant ============ /
    &-lg {
      flex-direction: row;
      .nav-tabs { max-width: 240px;}
      .account-plans-highlight { display: none;}

      &.active-plan {
        box-shadow: $shadow-small !important;
        .account-plans-highlight { display: none !important;}
      }
    }
  }

  &-highlight {
    font-size: 15px;
    font-weight: 700;
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius-lg $border-radius-lg 0 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    text-transform: uppercase;
    &--simple {
      background-color: var(--background-color);
      min-height: 10px;
    }
    &--trial {
      background-color: var(--background-color);
      color: $neutral-0;
    }
  }

  &-price {
    height: 100%;

    .account-plans-item-lg & {
      max-width: 730px;  
      height: auto;
      width: 70%;
    }

    .account-plans-item:not(.account-plans-item-lg) & {
      @include flexbox(column,initial,flex-start);
    }
  }

  &-info {    
    ul { margin-top: 45px;

      li {
        @include flexbox(initial, flex-start, initial);
        margin-bottom: 12px;
        hr {
          background-color: $neutral-4;
          width: 100%;
        }
        span {
          font-weight: 500;
          font-size: 17px;
          line-height: 1.41;
        }
        svg {
          color: var(--color);
          margin-top: 3px;
        }
      }
    }
  }
}

.account-plans-regular, .account-plans-trial {
  //@include flexbox(column,initial,);
  
  h2 {
    @include flexbox(column,flex-start,initial);
    //min-height: 105px;
    b {
      @include flexbox(initial,flex-start,initial);
      span {
        font-size: 14px;
        line-height: 160%;
      }
      s {
        font-size: 14px;
        line-height: 22.4px;
      }
    }

    > .txt-body {
      display: block;
      min-height: 45px;
    }
    
    i {
      font-weight: 700;
      font-size: 46px;
      line-height: 130%;
      font-style: normal;
    }
  }
}

.account-plans-trial {
  h2 {
    b {
      i {
        font-size: 26px;
      }
    }
  }
}

.payment-method {
  .top-card {
    display: flex;

    .icon-card {
      border-radius: $border-radius-lg;
      justify-content: center;
      background: $neutral-1;
      align-items: center;
      position: relative;
      margin-right: 8px;
      display: flex;
      height: 48px;
      width: 68px;

      &:last-child {
        margin-right: 0;
      }

      .active {
        transform: translate(-70%, 70%);
        position: absolute;
        bottom: 100%;
        left: 100%;
      }
    }
  }
}


.cancelation {

  &-title {
    transition: .3s;
  }

  &-top {
    .alert-warning {
      br { @media (max-width: 1377px) { display: none;}}
    }
  }
}