$assetUrl:'https://searchie-assets-shirsendu.s3.ap-southeast-1.amazonaws.com';
// Mixins
@import "mixins/bootstrap";
@import "mixins/flexbox";
@import "mixins/scrollbar";
@import "mixins/placeholder";

// Vars
@import "vars/bootstrap";
@import "vars/colors";
@import "vars/shadows";
@import "vars/borders";
@import "vars/typography";
@import "vars/spacing";

// Libs
@import "~glider-js/glider.css";
@import "~bootstrap-vue/src/index.scss";
@import "~codemirror/lib/codemirror.css";
@import "~codemirror/theme/neo.css";

// Base
@import "base/reset";
@import "base/utilities";
@import "base/animations";
@import "base/typography";
@import "base/icons";
@import "base/buttons";
@import "base/dropdowns";
@import "base/popovers";
@import "base/input-group";
@import "base/forms";
@import "base/avatars";
@import "base/alerts";
@import "base/tooltips";
@import "base/layout";
@import "base/modals";
@import "base/tabs";
@import "base/tables";
@import "base/filter-switch";
@import "base/pagination";
@import "base/tags";
@import "base/toast";
@import "base/picker";
@import "base/skeleton";
@import "base/loaders";
@import "base/iframe";
@import "base/misc";

//Components
@import "components/chapter-time";
@import "components/sidenav";
@import "components/topnav";
@import "components/stats";
@import "components/side-panel";
@import "components/cards";
@import "components/progress";

@import "components/wizard";
@import "components/select-option-list";
@import "components/activity";
@import "components/attributes";
@import "components/webhooks";
@import "components/uploader";
@import "components/segments";
@import "components/user-avatar";
@import "components/person";
@import "components/widget";
@import "components/trim";
@import "components/transcript";
@import "components/temporary";
@import "components/notifications";
@import "components/topbar";
@import "components/codemirror";
@import "components/radio";
@import "components/achievement";
@import "components/wisdom-analytics";
@import "components/app-slider";
@import "components/copilot";
@import "components/spotlight";
@import "components/watch";
@import "components/toggle-special";
@import "components/resource-takeover-overlay";

//Pages
@import "pages/auth";
@import "pages/hubs/dashboard";
@import "pages/hubs/listing";
@import "pages/hubs/editor";
@import "pages/hubs/settings";
@import "pages/hubs/comments";
@import "pages/account-settings";
@import "pages/audience";
@import "pages/library";
@import "pages/apps";
@import "pages/dashboard";
@import "pages/getting-started";
@import "pages/upgrade-promo";

// Overwrites
@import "overwrites/userpilot";
