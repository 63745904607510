// Sidepanel playlist editor / extension of .side-panel

&-playlist {

  &-files {
    box-shadow: $shadow-small;
    border-radius: $border-radius-lg;
    overflow: hidden;
    background: $neutral-0;
  }

  &-filter {
    padding: $padding-sm + $padding;
    @include flexbox(row,center,initial);

    > div {
      @include flexbox(row,center,space-between);
      flex: 1 0 0;
    }

    .search-form {
      position: relative;
      flex: 1 0 0;

      .form-control {
        padding-left: 32px;
        width: 100%;
      }

      .btn {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}
