.wizard {
  border-radius: $border-radius-lg;
  margin: 0 0 $margin-default;
  background-color: $primary-4;
  box-shadow: $shadow-small;
  padding: $padding-default;
  position: relative;

  .hub-dashboard-feed & {
    top: $margin-default;
    position: sticky;
    z-index: 1;
  }
  h2 {
    color: $primary-2;
  }
  h3 {
    color: $neutral-0;
    margin-bottom: 0;
  }
  p {
    color: $primary-2;
    margin-bottom: 16px;
  }
  &__step {
    padding-top: 16px;
    display: flex;
    justify-content: flex-start;
    button {
      margin-right: 8px;
    }
  }
  &__finish {
    margin-left: 40px;
    transform: translateY(-10px);
  }
  &__pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $neutral-0;
    width: 146px;
    margin-left: auto;
    margin-bottom: 8px;
    button {
      &.disabled {
        border-color: $primary-4;
        background: $primary-4;
        color: $primary-2;
      }
    }
  }
  &__page {
    margin-bottom: 0;
    span {
      color: $primary-2;
    }
  }
  &__progress {
    height: 4px;
    background-color: $primary-3;
    position: relative;
    border-radius: 2px;
    div {
      height: 4px;
      background-color: $white;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 2px;
      @include transition(.15s);
    }
    &-reverse {
      height: 4px;
      background-color: $neutral-2;
      position: relative;
      border-radius: 2px;
      div {
        height: 4px;
        background-color: $primary-3;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 2px;
        @include transition(.15s);
      }
    }
  }
  &__dismiss {
    &__button {
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }
}

#hub-onboarding-dismiss {
  .modal-dialog {
    position: absolute;
    min-width: 240px;
    right: 382px;
    top: 60px;
  }
  .modal-header { padding: $padding-default;}
  .modal-footer { padding: 0 $padding-default - $padding-sm $padding-default;}
}
