.card-achievement {

  // Default variant =========================/

  margin: 0 0 $margin 0;
  border-radius: $border-radius-lg;
  box-shadow: $shadow-small;
  @include transition(.15s);
  background: $neutral-0;
  min-width: 200px;
  padding: 12px;
  min-height: 112px;
  @include inline-flexbox(column,initial,space-between);

  @include hover() {
    box-shadow: $shadow-big;
    background: $primary-1;
    color: $primary-3;
    .slot-top .popover-toggle {
      display: inline-flex;
      + svg { display: none;}
    }
    .slot-top .slot-left {
      margin-top: -7px;
    }
    .slot-bottom {
      .slot-right {
        .txt-body-small {
          color: $primary-3 !important;
        }
      }
    }
  }
  .slot-top {
    @include inline-flexbox(row,center,space-between);
    width: 100%;
    .slot-left {
      @include inline-flexbox(row,center,center);
      .txt-heading-small {
        margin-bottom: 0;
        margin-left: 8px;
        word-break: break-all;
      }
      .custom-checkbox {
        margin: 0
      }
    }
    .popover-toggle {
      display: none;

      &[aria-describedby] {
        display: inline-flex;

        + svg { display: none;}
      }
    }
  }
  .slot-bottom {
    @include inline-flexbox(row,center,space-between);
    width: 100%;
    .slot-left {
      @include inline-flexbox(row,center,center);
      span {
        margin-right: 15px;
      }
    }
  }
  &.selected {
    background: $primary-1;
    color: $primary-3;
    .slot-bottom {
      .slot-right {
        .txt-body-small {
          color: $primary-3 !important;
        }
      }
    }
  }
}
