.audience {
  &-main {
    min-height: 100vh;
    position: relative;
    background: $neutral-0;
  }
  &-table {
    &.disabled {
      .ag-overlay-no-rows-wrapper { display: none;}
    }

    &-head {
      .audience-header.top-sticky-blur ~ & { top: 80px; &:after { display: none;} .with-topbar & { top: 140px;}}
    }
  }
  &-skeleton {
    @include flexbox(row,center,flex-start);
    border-bottom: 1px solid $neutral-1;
    padding-left: 42px;
    background: $neutral-0;
    position: relative;
    min-height: 84px;
    overflow: hidden;

    &:before {
      content:'';
      transform: skewX(-45deg);
      background-image: linear-gradient(90deg, rgba(255,255,255,0) 0px, rgba(255,255,255,.6) 80px, rgba(255,255,255,.6) 120px, rgba(255,255,255,0) 200px);
      position: absolute;
      background-repeat: repeat-x;
      left: -400px;
      width: 200px;
      height: 100%;
      animation: shine 3s infinite;
    }

    .skeleton {
      &-checkbox {
        border-radius: $border-radius-sm;
        background: $neutral-2;
        margin: 0 $margin 0 $margin-default;
        display: inline-block;
        min-width: 18px;
        height: 18px;
      }
      &-avatar {
        margin-right: $margin;
        background: $neutral-2;
        border-radius: 50%;
        min-width: 40px;
        display: block;
        height: 40px;
      }
      &-title {
        background: $neutral-2;
        margin-bottom: $margin;
        border-radius: 2px;
        display: block;
        width: 180px;
        height: 8px;
      }
      &-subtitle {
        background: $neutral-2;
        border-radius: 2px;
        display: block;
        width: 240px;
        height: 8px;
      }
    }
  }

  &-header {

    &-inner { @include flexbox(row,center,initial);}

    .slot-right {
      @include flexbox(row,center,flex-end);
      margin-left: auto;

      > .btn { min-width: 48px;}
    }

    .count {
      @include inline-flexbox(row,center,center);
      min-width: 32px;
      padding: 0 $padding;
      border-radius: $border-radius-md;
      background: $primary-3;
      color: $neutral-0;
      font-weight: 700;
      height: 32px;
    }

    &.no-bg {
      background: unset;
    }

    &.top-sticky-blur { &:after { left: 8px; right: 8px; background: $neutral-2; opacity: 1;}}
  }

  &-empty {
    @include flexbox(column,center,center);
    min-height: calc(100vh - 89px);
    padding: 2*$padding-lg 0;
    background: $neutral-0;
    text-align: center;
    color: $neutral-4;

    &.no-bg {
      background: unset;
    }
  }

  &-edit {
    &-row {
      @include flexbox(row,center,initial);
      &:not(:last-child) { border-bottom: 1px solid $neutral-2;}

      .handle {
        @include inline-flexbox(row,center,center);
        @include transition(.15s);
        color: $neutral-3;
        margin: 0 $margin;
        min-width: 24px;
        cursor: grab;

        @include hover() {
          color: $primary-4;
        }
      }

      .slot-left {
        @include flexbox(row,center,initial);
        position: relative;
        margin-right: $margin;
        //overflow: hidden;
        flex: 40% 0 0;

        &.fluid { flex: 1 0 0; .form-control-select { flex: 1 0 0;}}

        .txt-body-small {
          display: inline-flex;
          padding-right: $padding;
          background: $neutral-0;
          position: relative;

          &:after {
            content: "";
            display: block;
            position: absolute;
            pointer-events: none;
            left: 100%;
            width: 300px;
            top: 50%;
            height: 1px;
            background: $neutral-2;
          }
        }
      }
      .slot-right {
        @include flexbox(row,center,initial);
        flex: 1 0 0;

        .form-control-text { flex: 1 0 0;}
      }
    }
  }
}
