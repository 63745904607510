.icon-xs {
  height: auto;
  width: 12px !important;
  min-width: 12px;
}
.icon-sm {
  height: auto;
  width: 18px !important;
  min-width: 18px;
}
.icon-regular {
  height: auto;
  width: 24px !important;
  min-width: 24px;
}
.icon-md {
  height: auto;
  width: 32px !important;
  min-width: 32px;
}
.icon-lg {
  height: auto;
  width: 48px;
  min-width: 48px;
}

.icon-direction {
  &.down {
    transform: scaleY(-1);
  }
}

.icon-box {
  @include flexbox(row,center,center);
  border-radius: $border-radius-lg;
  background: $neutral-1;
  text-align: center;
  color: $neutral-6;
  overflow: hidden;
  transition: .2s;
  min-width: 48px;
  height: 48px;
  width: 48px;

  &-small {
    border-radius: $border-radius-md;
    min-width: 32px;
    padding: 0 5px;
    height: 32px;
    width: 32px;
  }

  &-large {
    min-width: 96px;
  }
}
