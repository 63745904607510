.card-library {
  @include inline-flexbox(column,initial,space-between);
  border-radius: $border-radius-lg;
  margin: 0 $margin $margin-default;
  box-shadow: $shadow-small;
  padding: $padding-default;
  @include transition(.15s);
  background: $neutral-0;
  position: relative;
  min-height: 114px;
  color: $neutral-6;
  min-width: 200px;
  cursor: pointer;

  @include hover() {
    background: $primary-1;
    box-shadow: $shadow-big;
    color: $primary-4;
  }

  .txt-heading-small {
    margin-bottom: $margin-sm;
  }

  input[type="file"] {
    position: absolute;
    appearance: none;
    height: 100%;
    width: 100%;
    opacity: 0;
    left: 0;
    top: 0;
  }
}

.add-media {
  margin: 0 0 $margin-default;
  &-figure {
    @include flexbox(row,flex-start,flex-start);
    .txt-heading { padding-top: 12px; margin-bottom: $margin;}
    .txt-body { color: $neutral-4;}
  }
  .card-library-icon {
    @include flexbox(row,center,center);
    border-radius: $border-radius-lg;
    @include transition(.15s);
    background: $neutral-1;
    margin-right: $margin;
    min-width: 48px;
    height: 48px;
  }
  @include hover() {
    .card-library-icon { background: $neutral-0; color: $primary-3;}
    .add-media-figure .txt-body { color: $primary-3;}
    .add-media-right { color: $primary-3;}
  }
  &-right {
    margin: -40px $margin $margin auto;
  }
}
