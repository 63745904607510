// The dropdown wrapper (`<div>`)
.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;

  // Generate the caret automatically
  @include caret();
}

// The dropdown menu
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: $zindex-dropdown;
  display: none; // none by default, but block on "open" of the menu
  float: left;
  min-width: $dropdown-min-width;
  padding: $padding 0;
  margin: $dropdown-spacer 0 0; // override default ul
  @include font-size($dropdown-font-size);
  color: $neutral-6;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  list-style: none;
  background-color: $neutral-0;
  background-clip: padding-box;
  border-radius: $border-radius-md;
  --corner-smoothing: 60%;
  box-shadow: $shadow-big;

  //.btn { min-height: 40px;}
  &.center {
    left: 50% !important;
    transform: translateX(-50%)!important;
    margin-top: 50px !important;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .dropdown-menu#{$infix}-left {
      right: auto;
      left: 0;
    }

    .dropdown-menu#{$infix}-right {
      right: 0;
      left: auto;
    }
  }
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
// Just add .dropup after the standard .dropdown class and you're set.
.dropup {
  .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: $dropdown-spacer;
  }

  .dropdown-toggle {
    @include caret(up);
  }
}

.dropright {
  .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: $dropdown-spacer;
  }

  .dropdown-toggle {
    @include caret(right);
    &::after {
      vertical-align: 0;
    }
  }
}

.dropleft {
  .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: $dropdown-spacer;
  }

  .dropdown-toggle {
    @include caret(left);
    &::before {
      vertical-align: 0;
    }
  }
}

// When Popper is enabled, reset the basic dropdown position
// stylelint-disable-next-line no-duplicate-selectors
.dropdown-menu {
  &[x-placement^="top"],
  &[x-placement^="right"],
  &[x-placement^="bottom"],
  &[x-placement^="left"] {
    right: auto;
    bottom: auto;
  }
}

// Dividers (basically an `<hr>`) within the dropdown
.dropdown-divider {
  border: none;
  display: block;
  border-bottom: 1px solid $neutral-2;
  margin: $margin 0;
}


.dropdown-cont {
  margin-top: $margin;
  outline: none;
  width: 250px !important;
  overflow: hidden;
  background: $neutral-0;
  box-shadow: $shadow-big;
  border-radius: $border-radius-lg;
  --corner-smoothing: 60%;
  border: 0;
  list-style: none;
  padding: $padding 0;

  &.sm {
    width: 210px !important;
  }
}
// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
  display: flex;
  align-items: center;
  width: 100%; // For `<button>`s
  clear: both;
  font-size: $txt-size-1;
  font-weight: 400;
  color: $neutral-6;
  text-align: inherit; // For `<button>`s
  text-decoration: if($link-decoration == none, null, none);
  white-space: nowrap; // prevent links from randomly breaking onto new lines
  background-color: transparent; // For `<button>`s
  border: 0; // For `<button>`s
  padding: 11px 12px;
  user-select: none;
  cursor: pointer;
  @include transition(.15s);

  // Prevent dropdown overflow if there's no padding
  // See https://github.com/twbs/bootstrap/pull/27703
  @if $dropdown-padding-y == 0 {
    &:first-child {
      @include border-top-radius($dropdown-inner-border-radius);
    }

    &:last-child {
      @include border-bottom-radius($dropdown-inner-border-radius);
    }
  }

  @include hover-focus() {
    color: $dropdown-link-hover-color;
    text-decoration: none;
    @include gradient-bg($dropdown-link-hover-bg);
  }

  &.active,
  &:active {
    color: $primary-4;
    text-decoration: none;
    background: $primary-1;
  }

  &.disabled,
  &:disabled {
    color: $dropdown-link-disabled-color;
    pointer-events: none;
    background-color: transparent;
    // Remove CSS gradients if they're enabled
    @if $enable-gradients {
      background-image: none;
    }
  }

  @include hover() {
    background: $neutral-1;
  }
  &.text-warning {
    color: $red-4 !important;
  }
}

.dropdown-menu.show {
  display: block;
}

// Dropdown section headers
.dropdown-header {
  display: block;
  padding: 0;
  margin-bottom: 0; // for use with heading elements
  white-space: nowrap; // as with > li > a
}

// Dropdown text
.dropdown-item-text {
  display: block;
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  color: $dropdown-link-color;
}

// Dropdown groups
.dropdown-group {
  &-scroll {
    max-height: 300px;
    overflow: auto;
    @include scrollbar-thin;

    .dd-search {
      background: $neutral-0;
      padding: 0 $padding;
      position: sticky;
      top: 0;

      &-input {
        border-radius: $border-radius-md;
        border: 1px solid $neutral-2;
        @include flexbox(row,center,flex-start);
        overflow: hidden;
        position: relative;

        .popover-dropdown-search {
          font-family: $app-v2-font;
          font-size: $txt-size-1;

          @include placeholder {
            color: $neutral-4;
          }
        }

        .btn-icon {
          border-radius: $border-radius-md 0 0 $border-radius-md;
          color: inherit;
        }
      }

      &-spinner {
        margin: 0 -8px;
      }

      &-no-result {
        text-align: center;
        padding: 10px;
      }
    }
    .dropdown-item { overflow: hidden;}
    .sticky-bottom {
      border-top: 1px solid $neutral-2;
      background: $neutral-0;
      padding-top: $padding;
      margin-top: $margin;
      position: sticky;
      z-index: 2;
      bottom: 0;
    }
    &.list {
      position: absolute;
      top: 78px;
      left: 17px;
      background: $neutral-0;
      z-index: 9;
      width: calc(100% - 34px);
      max-height: 240px;
      box-shadow: 0 8px 8px 0 rgba(#000, .1), 0 16px 32px 0 rgba(#000, .1);
      border-radius: 0 0 $border-radius-sm $border-radius-sm;
      &::-webkit-scrollbar {display: none;}
      scrollbar-width: none; 
      -ms-overflow-style: none;

      .sticky-bottom {
        border-top: none;
        bottom: -1px;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: -17px;
          height: 1px;
          width: calc(100% + 17px);
          background: $neutral-2;
        }
        &:hover {
          color: $primary-3;
          background: $neutral-1;
          cursor: pointer;
        }
      }
    }
    &-item {
      &:hover {
        color: $primary-3;
        background: $neutral-1;
        cursor: pointer;
      }
    }
    .list-unstyled {
      li {
        .dropdown-item {
          @include flexbox(row,flex-start,flex-start);
          .txt-body {
            white-space: normal;
          }
        }
      }
    }
  }
}

// Keeping the menus with submenus at 40px height
.has-submenu a {
  padding: 8px 12px;
}