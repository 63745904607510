.multiselect {
  font-family: $app-v2-font;
  position: relative;
  color: #000;
  padding: 0;
  outline: none;
  &.folder-multiselect {
    margin-bottom: 16px;
  }
  &--disabled {
    cursor: not-allowed;
    color: $custom-control-label-disabled-color;
  }
  .selected-item {
    height: 9px;
    display: none;
  }
  .caret {
    transition: .15s;
    position: absolute;
    cursor: pointer;
    right: 12px;
    top: 12px;
    z-index: 2;
  }
  .with_audience {
    display: flex;
    width: 100%;
    align-items: center;

    span {
      margin-left: 0;
      display: inline-flex;
      align-items: center;
      color: #939393;
      padding-left: 10px;

      &:first-of-type {
        margin-left: auto;
      }

      + span {
        &:before {
          content: "|";
          margin-right: 7px;
          opacity: .5;
          font-weight: 400;
        }
      }

      svg {
        margin: 0 0 0 7px;
      }
    }
  }
  // end multiselect.scss
  .icon-search {
    @include transition(.15s);
    pointer-events: none;
    position: absolute;
    opacity: 0;
    left: 12px;
    top: 12px;

    &.visible {
      opacity: 1;
      z-index: 2;

      + .multiselect__tags {
        .multiselect__input {
          padding-left: 45px !important;

          @at-root .multiselect-small .icon-search.visible + .multiselect__tags .multiselect__input { padding-left: 30px !important;}
        }
      }
    }
  }

  &--active {
    .caret {
      transform: scaleY(-1);

      &.icon-arrow-both { transform: none;}
    }
    .icon-search { opacity: 1; z-index: 2;
      + .multiselect__tags {
        .multiselect__input {
          padding-left: 45px !important;

          @at-root .multiselect--active.multiselect-small .icon-search + .multiselect__tags .multiselect__input { padding-left: 30px !important;}
        }
      }
    }

    .multiselect__input {
      border-radius: $border-radius-md $border-radius-md 0 0;
      border-bottom: none;
      background: $neutral-0;
    }
    //+ .preview {
    //  border-radius: $border-radius-lg 0 0 0 !important;
    //}
  }

  &__single {
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: $border-radius-md;
    position: absolute;
    line-height: 46px;
    background: $neutral-0;
    overflow: hidden;
    display: flex;
    align-items: center;
    bottom: 1px;
    color: #000;
    right: 1px;
    top: 1px;
    left: 1px;
    transition: .2s;
    font-size: $txt-size-0;
    padding: 0 12px;
    .multiselect:not(.multiselect--disabled) &:hover {
      color: $primary-3;
    }
    .multiselect--disabled:not(.read-only) & {
      background-color: $neutral-1;
      color: $neutral-3;
    }
    span {
      font-size: 1.4rem;
      line-height: 1.6;
    }
    .icon-regular + span {
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0 30px 0 0;
    }

    &__truncated {
      display: block;
      margin-right: 20px;
      padding-top: 2px;
    }
  }

  &__input {
    font-family: $app-v2-font;
    position: relative !important;
    // @include placeholder(#939393);
    border: 1px solid $neutral-2;
    padding: 0 12px !important;
    width: 100% !important;
    font-family: $app-v2-font;
    transition: padding .1s;
    border-radius: $border-radius-md;
    font-size: 1.4rem;
    cursor: pointer;
    display: block;
    height: 49px;
    // @include transition(.2s);
    .customizer-preview & {
      padding-left: 116px !important;
    }
  }

  &__placeholder {
    padding: 0 40px 0 20px;
    align-items: center;
    position: absolute;
    font-weight: 600;
    font-size: 14px;
    color: #939393;
    display: flex;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    display: none;

    > svg {
      path { fill: #939393;}
      margin-right: 15px;
    }

    .dashed {
      position: absolute;
      border-radius: 7px;
      background: #FAFAFA;
      border: 1.5px dashed #d0d0d0;
      padding-left: 20px;
      padding-top: 1px;
      top: 0;
      left: 0;
      right: 0;
      height: 43px;
      font-size: 14px;
      font-weight: 600;
      color: #939393;
      overflow: hidden;
      display: flex;
      align-items: center;

      &.white { background: #fff;}

      svg {
        margin-right: 10px;
        width: 14px;
        height: auto;
        path { fill: currentColor;}
        &.icon-plus {
          width: 14px;
          line { stroke: $primary-3;}
        }
      }
    }
  }

  &__content {
    //padding-top: 10px;
    background: #fff;
    overflow: hidden;
    width: 100%;

    &-wrapper {
      box-shadow: $shadow-big;
      border-radius: 0 0 $border-radius-md $border-radius-md;
      border: 1px solid $neutral-2;
      position: absolute;
      background: $neutral-0;
      //margin-top: -10px;
      border-top: none;
      overflow: auto;
      width: 100%;
      z-index: 100;
    }
  }

  .multiple-selected-list {
    @include flexbox(row,center,initial);
    @include transition(.15s);
    padding: $padding-sm $padding-sm * 3;
    min-height: 40px;

    .icon {
      position: relative;
      display: inline-flex;
      width: 18px;
      min-width: 18px;
      height: 18px;

      svg {
        @include transition(.15s);
        opacity: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 18px;

        &.on-hover {
          opacity: 0;
        }
      }
    }

    @include hover() {
      background: $neutral-1;
      .icon {
        svg {
          opacity: 0;

          &.on-hover {
            opacity: 1;
          }
        }
      }
    }
  }

  .multiple-selection {
    @at-root .side-panel-widget.p-4 .multiple-selection { margin-bottom: -8px;}
    .btn {
      max-width: 100%;
      span {
        white-space: nowrap;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .icon-flag {
    flex: 0 0 21px;
    margin-right: 12px;
    max-height: 21px;
    max-width: 21px;
    height: 21px;
    width: 21px;
  }

  &__option {
    @include transition(.15s);
    align-items: center;
    cursor: pointer;
    color: $neutral-6;
    display: flex;
    height: 40px;
    position: relative;
    font-size: $txt-size-1;
    padding: $padding-sm $padding-sm * 3;
    opacity: 1;

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
      width: 100%;
    }

    svg {
      width: auto;
      height: auto;
      max-height: 18px;
      max-width: 18px;
      flex: 0 0 18px;
      object-fit: contain;
      object-position: center;
      margin-right: 10px;
      &.social {
        height: 13px;
      }
      &.no-style {
        width: auto;
        height: auto;
        margin-right: 10px;

        path { fill: #293AD3;}
      }
    }

    &--highlight {
      background: $neutral-1;
      color: $primary-3;
    }
    &--selected {
      background: $primary-1;
      color: $primary-3;

      .selected-item  {
        display: block;
      }

      .tag-option {
        svg {
          path:not(.no-fill) { fill: currentColor;}
          .cl-stroke { stroke: currentColor;}
        }
      }
    }
    &--disabled {
      cursor: default;
      background: $neutral-0;
      color: $neutral-3;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 15px;
      right: auto;
      width: 80%;
      opacity: 0;
      transition: .2s;
      top: 50%;
      margin-top: -2.5px;
      height: 5px;
      border-radius: $border-radius-md;
      background: #D7E0D8;
      pointer-events: none;
    }

    .wf-loading & {
      background: #fff;
      pointer-events: none;
      font-size: 0;
      &:before { opacity: .5;}
    }
  }

  &__element {
    font-size: 16px;
  }
  &--above {
    &:after,&:before {
      transform: rotate(-45deg);
    }
    &:before {
      transform: rotate(45deg);
    }
    .multiselect__content-wrapper {
      border-top: 1px solid $neutral-2;
      border-bottom-right-radius:0;
      border-top-right-radius:$border-radius-md;
      border-bottom-left-radius:0;
      border-top-left-radius:$border-radius-md;
      margin-bottom: -1px;
      border-bottom:none;
      bottom:100%;
    }
    &.multiselect--active {
      &:before {
        transform: rotate(-45deg);
      }
      &:after {
        transform: rotate(45deg);
      }
      .multiselect__input {
        border-top: none;
        border-radius: 0 0 $border-radius-sm $border-radius-sm;
        border-bottom: 1px solid $neutral-2;
      }
    }
  }
  .tag-option {
    align-items: center;
    max-width: 100%;
    display: flex;
    width: 100%;

    span {
      transition: .2s;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: calc(100% - 30px);
      overflow: hidden;
      display: block;

      @at-root .multiselect.with-tooltips .tag-option span { width: 100%;}

      &.audience-count {
        flex: auto 0 0;
        padding-left: 10px;
      }
    }
    &.add {
      &:hover {
        span { color: $primary-3;}
      }
    }
  }
  &__fixed-bottom {
    .multiselect__single {
      padding-right: 40px;
      align-items: center;
      display: flex;

      .btn { pointer-events: none;}
    }
    .multiselect__input {
      font-size: 16px;
    }
    .multiselect__content {
      padding-bottom: 75px;
      position: static;
      overflow: auto;
      max-height: 295px;

      &-wrapper {
        overflow: hidden;
      }

      .multiselect__option {
        font-size: 16px;
        height: auto;
        padding: 10px 20px;
        line-height: 20px;

        &--highlight {
          background: #fff;
          color: $primary-3;
        }
        &--selected {
          background: #fff;
          color: $primary-3;
        }
      }

      .fixed-bottom {
        position: absolute;
        height: 65px;
        padding-top: 10px;
        padding-bottom: 15px;
        background: #fff;
        width: 100%;
        bottom: 0;
        left: 0;

        .multiselect__option {
          &:hover {
            color: $primary-3;
          }
        }

        &:before {
          content: "";
          position: absolute;
          left: 20px;
          right: 20px;
          top: 0;
          display: block;
          border: none;
          border-bottom: 1.5px solid rgba(147, 147, 147, 0.1);
          border-color: #EFEFEF;
        }
      }
    }
  }
  &.is-invalid {
    .multiselect__input {
      border-color: #D55757 !important;
    }
  }

  &__tags-wrap {
    display: none;
  }

  &-small {
    .icon-search {
      width: 18px;
      height: 18px;
      left: 4px;
      top: 7px;
    }
    .multiselect__single {
      font-size: $txt-size-0;
      font-weight: 400;
      line-height: 32px;
      padding: 0 $padding;
      margin-right: 35px;

      span { font-size: inherit;}
    }
    .multiselect__input {
      font-size: $txt-size-0;
      padding: 0 $padding !important;
      font-weight: 400;
      height: 32px;
    }
    .caret {
      right: $margin;
      top: $margin-sm;

      @at-root .multiselect.multiselect--disabled:not(.read-only) .caret { color: $neutral-3; pointer-events: none;}
    }
    .multiselect__element {
      font-size: $txt-size-0;
    }
    .multiselect__option {
      font-size: $txt-size-0;
      padding: 0 $padding;
      font-weight: 400;
      height: 32px;
    }
    &.multiselect--above {
      .multiselect__content-wrapper {
        border-top-right-radius:$border-radius-sm;
        border-top-left-radius:$border-radius-sm;
      }
      &.multiselect--active {
        &:before {
          transform: rotate(-45deg);
        }
        &:after {
          transform: rotate(45deg);
        }
        .multiselect__input {
          border-top: none;
          border-radius: 0 0 $border-radius-sm $border-radius-sm;
        }
      }
    }
  }
  .icon-multiselect-spinner {
    position: absolute;
    right: 14px;
    top: 14px;
    z-index: 2;
    height: auto;
    width: 20px;
    animation: a 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
    animation-iteration-count: infinite;
  }

  &__dot__item {
    @include inline-flexbox(row,center,initial);
  }
}

@keyframes a {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.form-control-select {
  &-tag {
    &.btn-md {
      font-size: $txt-size-1;
      min-height: 44px;
    }

    &.tag-basic {
      span { max-width: none;}
    }
  }
}