.custom-checkbox {
  position: relative;
  min-width: 18px;
  display: flex;

  .custom-control-input {
    display: inline-block;
    overflow: hidden;
    padding: 0;
    margin: 0;
    opacity: 0;
    height: 0;
    width: 0;

    &:checked,&.checked {
      + .custom-control-label {
        &:before {
          border-color: $primary-4;
          background: $primary-4;
          color: $neutral-0;
        }
        &:after {
          opacity: 1;
          width: 14px;
          top: 9px;
          left: 2px;
        }

        @include hover() {
          &:before {
            border-color: $primary-4;
            background: $primary-4;
            box-shadow: $shadow-hover;
          }
        }
      }

      &:disabled {
        + .custom-control-label {
          pointer-events: none;
          opacity: 1;
          &:before {
            border-color: $neutral-2;
            background: $neutral-2;
          }
          &:after {
            content: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.33334 7L5.83334 11.0833L11.6667 3.5' stroke='%23AFC3B5' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          }
        }
      }
    }
    &:indeterminate {
      + .custom-control-label {
        &:before {
          border-color: $primary-3;
          background: $primary-3;
          color: $neutral-0;
        }
        &:after {
          background: $neutral-0;
          border-radius: 1px;
          perspective: 1px;
          opacity: 1;
          width: 10px;
          content: "";
          height: 2px;
          left: 4px;
          top: 9px;
        }

        @include hover() {
          &:before {
            border-color: $primary-4;
            background: $primary-4;
            box-shadow: $shadow-hover;
          }
        }
      }

      &:disabled {
        + .custom-control-label {
          pointer-events: none;
          opacity: 1;
          &:before {
            border-color: $neutral-2;
            background: $neutral-2;
          }
          &:after {
            background: $neutral-3;
          }
        }
      }
    }
    &:disabled {
      + .custom-control-label {
        pointer-events: none;
        opacity: .5;
        &:before {
          border-color: $neutral-2;
        }
      }
    }
  }

  .custom-control-label {
    @include inline-flexbox(row,center,center);
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    min-width: 18px;
    min-height: 18px;
    outline: none;

    @include hover() {
      &:before {
        border-color: $primary-3;
        box-shadow: $shadow-hover;
      }
    }

    &:before {
      @include transition(.15s);
      box-shadow: none;
      outline: none;
      box-sizing: border-box;
      border-radius: $border-radius-sm;
      border: 1px solid $neutral-2;
      background: rgba($neutral-0, .0);
      position: absolute;
      display: block;
      height: 18px;
      width: 18px;
      content: "";
      left: 0;
      top: 0;
    }

    &:after {
      content: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.33334 7L5.83334 11.0833L11.6667 3.5' stroke='white' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      transform: translateY(-50%);
      @include transition(.15s);
      display: inline-flex;
      position: absolute;
      overflow: hidden;
      height: 14px;
      opacity: 0;
      z-index: 2;
      left: 4px;
      top: 9px;
      width: 0;
    }
  }
}

.form-control-checkbox-slot {
  margin-left: 32px;
}


.form-checkbox-tile {
  .custom-control-input:checked {
    + .custom-control-label {
      box-shadow: none;
      background: $primary-1;
      color: $primary-4;
      @include hover() { color: $primary-3;}
      .card-app__body__left-icon { background: $neutral-0;}
    }
  }
  
  .custom-control-label {
    @include inline-flexbox(row,center,flex-start);
    border-radius: $border-radius-lg;
    box-shadow: $shadow-small;
    padding: $padding-default;
    background: $neutral-0;
    position: relative;
    transition: .2s;
    cursor: pointer;
   //bottom: 0;
   //right: 0;
   //left: 0;
   //top: 0;
  
    @include hover() { box-shadow: $shadow-big;}
  
    &:before,&:after { display: none;}
  }
}
