// Tags =====================/

.tag {
  &-list {
    @include flexbox(row,flex-start,flex-start);
    flex-wrap: wrap;
  }
  &-basic {
    @include inline-flexbox(row,center,center);
    margin: 0 $margin $margin 0;
    border-radius: $border-radius-md;
    padding: $padding-sm $padding;
    background: $neutral-1;
    font-size: $txt-size-0;
    color: $neutral-6;
    font-weight: 700;
    height: 32px;

    &.form-control-select-tag {
      @include hover() { background: $neutral-1;}
    }

    span {
      display: block;
      max-width: 220px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .btn-xs { min-height: 24px; padding: 0; min-width: 24px; margin-right: -4px;}
    > .btn-overlay:first-child { margin-left: -4px;}
  }

  &-variant-dark {
    background: rgba($neutral-6,.05);
    svg { color: $neutral-6;}
  }
}
