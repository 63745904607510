.no-bg { background: transparent !important;}

.bg-white { background: $neutral-0 !important;}
.bg-neutral-1 { background: $neutral-1 !important;}

.bg-blue-1 { background: $primary-1 !important;}
.bg-blue-3 { background: $primary-3 !important;}
.bg-blue-gradient { background: $blue-gradient !important;}

.bg-green-1 { background: $green-1 !important;}

.bg-red-1 { background: $red-1 !important;}


.bg-gradient-blue-green { background: $gradient-blue-green !important;}
