.card-segment {
  @include flexbox(column,initial,space-between);
  @include transition(.15s);
  background: $neutral-0;
  border-radius: $border-radius-lg;
  box-shadow: $shadow-small;
  margin: 0 auto $margin;
  padding: 12px 12px 12px 16px;
  min-height: 114px;
  overflow: hidden;
  width: 100%;

  @include hover() {
    background: $primary-1;
    box-shadow: $shadow-big;
    color: $primary-4;

    .popover-toggle { opacity: 1;}
    .card-segment-avatars { color: $primary-3;}
    .b-avatar-group {
      .b-avatar {
        border-color: $primary-1;
      }
    }
    .txt-muted-2 {
      color: $primary-2;
    }
  }

  .popover-toggle {
    opacity: 0;
  }

  .slot-top {
    @include flexbox(row,flex-start,space-between);

    &-cta {
      @include flexbox(row,center,flex-end);
    }
  }
  .slot-bottom {
    @include flexbox(row,flex-end,space-between);
  }
  .txt-heading-small {
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-inline-box;
    -webkit-line-clamp: 2;
            line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 51px;
  }
  &-avatars {
    @include flexbox(row,center,initial);
    @include transition(.15s);
    color: $neutral-4;
  }

  &.selected {
    color: $primary-4;
    background: $primary-2;
    box-shadow: $shadow-small;

    .slot-top { color: $primary-4;}

    .slot-bottom { color: $primary-4;}
    .card-segment-avatars { color: $primary-3;}

    .b-avatar-group {
      .b-avatar {
        border-color: $primary-2;
      }
    }
  }
}
