// Hub Dashboard Page ===============/

//$dashboard-side-panel-right-width: 460px;

.getting-started {

  &-onboarding {
    box-shadow: $shadow-small;
    background: $neutral-0;
    border-radius: 10px;
    margin-bottom: 10px;
    overflow: hidden;

    .btn-primary-success {
      .txt-body {
        transition: .2s;
        max-width: 80px;

        &.expanded { max-width: 160px;}
      }
    }

    &-header {
      @include flexbox(row, center, space-between);
      position: relative;
      user-select: none;
      transition: .2s;
      cursor: pointer;
      padding: 20px;

      .getting-started-onboarding > & { min-height: 92px; box-shadow: $shadow-small;}
      @include hover() { color: $primary-3; .txt-muted { color: $neutral-6 !important; transition: .2s;} .txt-muted-2 { color: $primary-3 !important; transition: .2s;}}
    }

    &-body {
      @include flexbox(row,initial,initial);
      padding: $padding-lg 68px 32px;
    }

    &-tasks {
      flex: 1 0 0;

      .explainer {
        @include flexbox(row, center, initial);
        padding: 0 48px 0 60px;

        &-left {
          width: 70%;
          max-width: 565px;
          padding-right: $padding-lg;
          margin-right: 64px;
          padding-bottom: $padding-lg;
          
          > [class*='form-control'] { max-width: 468px; + hr {max-width: 468px;}}
        }
        & .top {max-width: 468px;}
        &-right { margin-left: auto;}
        &-illustration { max-width: 400px;}
      }
      .card-app-inner {
        display: grid;
        grid-template-columns: 230px 230px;
        column-gap: 16px;
      }
    }

    &-task {
      min-height: 48px;
      
      &-status {
        @include flexbox(row,center,initial);
        &.task-done {
          .task-title {
            color: $neutral-3;
          }
        }
      }
    }

    &-get-support {
      background-image: url("data:image/svg+xml,%3Csvg width='271' height='196' viewBox='0 0 271 196' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.59415 24.8926C8.57732 28.9331 9.06199 30.5032 9.06199 30.5032L7.59415 24.8926Z' fill='%23D1EEA9'/%3E%3Cpath d='M7.59415 24.8926C8.57732 28.9331 9.06199 30.5032 9.06199 30.5032' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.1776 36.4056C11.1608 40.4461 11.6455 42.0163 11.6455 42.0163L10.1776 36.4056Z' fill='%23D1EEA9'/%3E%3Cpath d='M10.1776 36.4056C11.1608 40.4461 11.6455 42.0163 11.6455 42.0163' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1.45171 34.8882L6.62628 34.0222L1.45171 34.8882Z' fill='%23D1EEA9'/%3E%3Cpath d='M1.45171 34.8882L6.62628 34.0222' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.482 32.7353L17.6566 31.8693L12.482 32.7353Z' fill='%23D1EEA9'/%3E%3Cpath d='M12.482 32.7353L17.6566 31.8693' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M138.376 -27.4622C95.8271 2.15519 54.8669 32.9285 30.0637 76.6052C16.1342 101.125 23.9471 143.515 64.237 138.6C81.7887 136.451 97.3816 127.961 112.433 119.652C142.806 102.859 167.183 76.2596 201.889 65.7593C217.921 60.9031 233.519 58.7041 246.249 71.0013C273.698 97.5264 225.547 130.176 223.241 156.726C219.885 195.331 275.859 208.906 296.434 176.772' fill='%23D1EEA9'/%3E%3Cpath d='M138.376 -27.4622C95.8271 2.15519 54.8669 32.9285 30.0637 76.6052C16.1342 101.125 23.9471 143.515 64.237 138.6C81.7887 136.451 97.3816 127.961 112.433 119.652C142.806 102.859 167.183 76.2596 201.889 65.7593C217.921 60.9031 233.519 58.7041 246.249 71.0013C273.698 97.5264 225.547 130.176 223.241 156.726C219.885 195.331 275.859 208.906 296.434 176.772' stroke='black' stroke-width='1.5' stroke-miterlimit='10'/%3E%3C/svg%3E%0A");
      background-position: top right;
      background-repeat: no-repeat;
      background-color: $primary-2;
      padding: 64px 92px 150px;
      width: 100%;

      .support {

        &-grid {
          grid-template-columns: repeat(3, 1fr);
          display: grid;
        }

        &-element {
          @include flexbox(row, initial, initial);

          &-icon {
            @include inline-flexbox(row,center,center);
            box-shadow: $shadow-small;
            background: $neutral-0;
            border-radius: 50%;
            margin-right: 20px;
            min-width: 52px;
            height: 52px;
          }

          &-content { max-width: 260px;}
        }
      }

      > .txt-title-small { margin-bottom: 80px;}
    }
    // &-chart {
    //   margin: $margin $margin-default $margin-default;
    // }
    &-donut-chart {
      @include inline-flexbox(row,center,initial);
      position: relative;

      &-labels {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        // h1 {
        //   font-size: 34px;
        //   font-weight: bold;
        // }
      }
      .progress {
        fill: transparent;
        stroke: #06211E;
        stroke-linecap: round;
        transition: stroke-dashoffset 0.5s ease;
      }
    }
  }
}
