// Styles for custom copilot components live here

.copilot {

  .side-panel-body {
    position: relative;
  }

  &-input {
    border: none;
    box-shadow: none !important;
    border-radius: 0;
    padding-left: $padding-default;
    padding-right: 44px;
    max-height: 468px;
    overflow: hidden;
    transition: .3s;

    + .btn-icon { position: absolute; right: 4px; bottom: 4px;}
    &.initial { max-height: 48px !important; line-height: 1.96;}
    &:disabled, &.disabled { background: transparent; opacity: .5;}

    &-signin-overlay {
      background-color: $neutral-0;
    }
  }

  &-cta {
    border-radius: $border-radius-md $border-radius-md 0 0;
    background: $neutral-0;
    position: sticky;
    margin: auto -32px 0;
    bottom: 0;
    z-index: 4;
  }

  // Output ========================================= //

  &-output {
    word-break: break-word;
    font-size: $txt-size-1;
    //white-space: pre-wrap;
    line-height: 1.6;
    text-wrap: wrap;

    h1,h2,h3,h4,h5,h6,p { font-size: inherit; line-height: inherit; margin-bottom: $margin-default; white-space: pre-wrap;}
    ol ol, ul ol, ul ul, ol ul { counter-reset: custom-counter; margin-top: $margin-sm;}
    > ul, > ol { margin-bottom: $margin-default;}

    ol {
      list-style: none;
      padding-left: 28px;
      counter-reset: custom-counter;
      display: grid;
      row-gap: $margin-sm;

      > li {
        display: block;
        position: relative;
        padding-left: 0;

        &:before {
          content: counter(custom-counter);
          counter-increment: custom-counter;
          display: block;
          line-height: 1.6;
          min-width: 22px;
          text-align: center;
          margin-right: 6px;
          margin-left: -28px;
          float: left;
        }
      }
    }

    ul {
      list-style: none;
      padding-left: 28px;
      display: grid;
      row-gap: $margin-sm;

      > li {
        display: block;
        position: relative;
        padding-left: 0;

        &:before {
          content: "\2022";
          color: $primary-3;
          font-size: 1.7em;
          display: block;
          line-height: 21px;
          min-width: 22px;
          text-align: center;
          margin-right: 6px;
          margin-left: -28px;
          float: left;
        }
      }
    }

    code {
      font-family: 'DM Mono', monospace;
      font-size: inherit;
      line-height: inherit;
      color: $neutral-0;
      background-color: $neutral-6;
      padding: $padding-default;
      border-radius: $border-radius-lg;
      white-space: pre-wrap;
      display: block;
      margin: $margin-sm 0 $margin-default;
    }

    blockquote {
      margin: 0; /* Reset default margin */
      padding: $padding-default;
      background-color: $neutral-0;
      border-left: 4px solid $neutral-2;
      font-size: inherit;
      line-height: inherit;

      cite {
        display: block;
        margin-top: 10px;
        font-style: italic;
        color: $neutral-4;
      }

      &:before {
        content: '\201C';
        font-size: 3.5em;
        line-height: 30px;
        color: $neutral-2;
        margin-right: $margin-sm;
        vertical-align: middle;
      }
    }
  }

  // Prompts ======================================== //

  &-prompts {
    height: 60vh;
    overflow: auto;

    &-list { margin-top: 10px;}

    &-item {
      @include flexbox(row,center,initial);
      padding: $padding $padding-default;
      background: $neutral-0;
      min-height: 61px;
      transition: .2s;
      cursor: pointer;

      @include hover() {
        background: $neutral-1;
        .copilot-prompts-item-cta { opacity: 1; pointer-events: all;}
      }

      &.txt-primary {
        .copilot-prompts-icon { background: $primary-1;}
        @include hover() { background: $primary-1;}
      }

      &-cta {
        @include flexbox(row,center,flex-end);
        pointer-events: none;
        margin-bottom: auto;
        margin-left: auto;
        margin-top: 6px;
        // min-width: 76px;
        transition: .4s;
        opacity: 1;

        // Hide only when hover is possible, otherwise show it (important for smaller devices)
        @media (hover: hover) {
          opacity: 0;
        }
      }

      &-info { min-width: 0; max-width: calc(100% - 60px);}

      &-title {
        font-size: $txt-size-1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        line-height: 1.6;

        em {
          border-radius: $border-radius-sm;
          background-color: $orange-1;
          font-style: normal;
          padding: 3px 2px;
          margin: -3px 0;
        }
      }

      &-desc {
        font-size: $txt-size-0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: $neutral-4;
        line-height: 1.6;

        .icon-xs { float: left; margin: 2.5px 2.5px 2.5px 0;}

        em {
          text-decoration: underline;
          text-decoration-color: $orange-1;
          text-decoration-thickness: 2px;
          font-style: normal;
          color: $neutral-6;
        }
      }

      .play-icon-overlay {
        background: rgba($neutral-6,.5);
        display: inline-flex;
        border-radius: 30px;
        position: absolute;
        color: $neutral-0;
        padding: 6px;
      }
    }

    &-icon {
      @include flexbox(row,center,center);
      border-radius: $border-radius-sm;
      background: $neutral-1;
      margin-right: $margin;
      margin-bottom: auto;
      overflow: hidden;
      transition: .2s;
      min-width: 45px;
      height: 45px;
      width: 45px;
      position: relative;

      > img { width: 100%; height: 100%; object-fit: cover; object-position: center;}
      > svg { max-width: 24px; max-height: 24px; }
      + div { max-width: calc(100% - 130px);}
    }
  }

  // Sign-in section ================================ //

  .assistant-sign-in {
    background: linear-gradient(to bottom, rgba($neutral-0, 0) 0%, rgba($neutral-0, 1) 43%, rgba($neutral-0, 1) 60%, rgba($neutral-0, 0) 100%);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;

    .btn-md { width: 100%; max-width: 280px; font-size: 1.4rem;}

    &-cta {
      @include inline-flexbox(row,center,center);
      padding-top: $padding-default;
      font-size: $txt-size-1;
      text-align: center;
      font-weight: 700;
      line-height: 1.6;
    }
  }

  &-feedback {

    .side-panel-header {
      padding: $padding-lg 32px $padding-default;
      border-bottom: 1px solid $neutral-2;
      @media (max-width: 768px) { padding: 12px $padding-default !important;}
    }
    
    &-cta {
      padding: 32px;
      @media (max-width: 768px) { padding: $padding-default !important;}
    }
  }
}