.segment {
  &-group-operator {
    padding: $padding-default;
    @include flexbox(row,center,center);
    //.filter-switch {
    //  box-shadow: $shadow-small;
    //}
  }
}

.filter {
  &-group {
    border-radius: $border-radius-lg;
    box-shadow: $shadow-small;
    background: $neutral-0;
    //overflow: hidden;

    &-head {
      @include flexbox(row,center,initial);
      border-bottom: 1px solid $neutral-2;
    }
  }

  &-row {
    padding: $padding-default 52px $padding-default $padding-default;
    border-bottom: 1px solid $neutral-2;
    position: relative;

    &-delete {
      @include flexbox(row,center,center);
      position: absolute;
      width: 52px;
      bottom: 0;
      right: 0;
      top: 0;
    }

    .slot-top {
      @include flexbox(row,initial,initial);
      margin-bottom: $margin;

      .condition-selector {
        min-width: 0;
        flex: 1 0 0;
      }
    }

    .slot-left {
      margin-right: $margin;
      min-width: 140px;
      width: 128px;
    }

    .slot-bottom {
      @include flexbox(row,initial,initial);

      .condition-final {
        flex: 1 0 0; min-width: 0;
        .searched {
          display: inline-flex;
          width: 100%;
          .form-control-sm {flex: 1;}
        }
      
        .condition-registration {
          display: inline-flex;
          width: 100%;
          .form-control-sm {flex: 1;}
          .searchie-calendar {
            position: absolute;
            top: 0;
            left: -117px;
            background: white;
            box-shadow:  $shadow-big;
            border-radius: $border-radius-md;
          }
        }
      }
    }
  }

}

.condition-selector {
  position: relative;
  flex: 1 0 0;
  //border: 1px solid $neutral-2;
  //padding: 0;
  //border-radius: $border-radius-md;
  //font-size: $txt-size-0;
  //font-weight: 400;
  //color: $neutral-6;
  overflow: hidden;

  .drop {
    border-radius: 0 0 $border-radius-md $border-radius-md;
    border: 1px solid $neutral-2;
    background: $neutral-0;
    position: absolute;
    max-height: 345px;
    overflow: auto;
    z-index: 10;
    right: -1px;
    left: -1px;
    top: 100%;

    .input-group {
      .icon-regular {
        display: none;
      }
      .form-control {
        border-radius: $border-radius-sm;
        margin: 0 8px;
      }
    }
    .selector-search {
      margin-bottom: 8px;

      .input-group {
        position: relative;

        &-prepend {
          pointer-events: none;
          position: absolute;
          z-index: 4;
          left: 16px;
          top: 7px;
        }

        .form-control { padding-left: 33px !important;}
      }
    }
  }

  .selected-condition {
    position: relative;
    padding: 0 $padding;
    cursor: pointer;

    &-inner {
      @include flexbox(row,center,initial);
      margin-right: 34px;
      position: relative;
      min-height: 32px;
      overflow: auto;
    }

    .caret {
      @include transition(.15s);
      top: $padding-sm;
      position: absolute;
      right: 0;
    }

    &-item {
      white-space: nowrap;

      &.with-tooltip {
        transition: color .2s;

        @include hover() {
          color: $primary-3;
        }
      }
    }
  }
  .selector-list {
    padding: 0 34px 0 $padding;
    position: relative;
    @include transition(.15s);
    cursor: pointer;
    @include flexbox(row,center,initial);
    min-height: 32px;

    .caret {
      @include transition(.15s);
      top: $padding-sm;
      position: absolute;
      right: $padding;
    }

    &:not(.disabled):hover {
      background: $neutral-1;
      color: $primary-4;
    }

    &.no-events { pointer-events: none;}

    &.back {
      padding: 15px $padding 15px 34px;
      @include flexbox(row,center,initial);
      border-bottom: 1px solid $neutral-2;
      margin-bottom: 8px;
      .caret {
        right: auto;
        left: $padding;
        top: auto;
      }
    }

    &.sub-list {
      padding-left: 10px;
    }

    &-label {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      line-height: 18px;
      .icon-sm {
        margin-right: 10px;
        float: left;
      }
    }
    &-group {
      .selector-list:first-of-type {
        margin-top: 8px;
      }
      .selector-list:last-of-type {
        margin-bottom: 8px;
      }
    }
    &.disabled {
      padding-right: 10px;
      cursor: default;
    }
  }

  &.is-open {
    border-radius: $border-radius-md $border-radius-md 0 0;
    border-color: $neutral-2;
    overflow: visible;
    box-shadow: none;
    .selected-condition {
      .caret {
        transform: scaleY(-1);
      }
    }
  }
}

.condition-final {
  .form-control-sm {
    font-size: $txt-size-0;
    padding: 0 $padding;
    color: $neutral-6;
  }
}
