// Activity

.activity {
  position: relative;
  max-width: 100%;
  display: block;

  &-header {
    @include flexbox(row,center,space-between);
    padding: $padding 0;
    min-height: 80px;
  }

  &-group {

    &-header {
      @include flexbox(row,center,space-between);
      margin: 0 0 $margin-default;
      min-height: 48px;

      h6 { margin: 0;}
    }
  }

  &-row {
    @include flexbox(row,flex-start,flex-start);
    margin: 0 0 $margin-default;
    position: relative;

    &:before {
      content: "";
      display: block;
      width: 1px;
      position: absolute;
      left: 20px;
      top: 20px;
      bottom: 0;
      background: $neutral-2;
    }

    &:last-child {
      &:before {
        bottom: auto;
        height: 30px;
      }
    }

    &:only-child {
      &:before {
        display: none;
      }
    }

    + .activity-row {
      &:before {
        top: -16px;
      }
    }

    .avatar {
      margin-right: $margin-default;
      margin-top: 10px;
      min-width: 40px;
    }
  }

  &-action {
    padding: $padding-default $padding-lg;
    background: $neutral-0;
    border-radius: $border-radius-lg;
    box-shadow: $shadow-small;
    flex: 1 0 0;
    h6 {
      .txt-primary, .txt-dark {
        
      }
    }

    &.no-shadow {
      box-shadow: unset;
    }
  }

  &-date {
    width: 100%;
    max-width: max-content;
  }

  &-title {
    word-break: break-word;
    margin: 0;
    .txt-primary {
      cursor: pointer;
    }
  }

  // Activity widget style ================= /

  &-widget {
    background: $neutral-0;
    box-shadow: $shadow-small;
    border-radius: $border-radius-lg;
    margin-bottom: $margin-default;
    padding-top: $padding;

    .activity {

      &-group {
        padding: $padding $padding-lg;

        &:not(:last-child) {
          border-bottom: 1px solid $neutral-2;
        }
      }

      &-action {
        background: none;
        box-shadow: none;
        padding: 0 $padding;
      }

      &-row {
        padding-bottom: $padding-default;
        &:before { left: 12px;}
      }
    }
  }

  &-badge {
    @include flexbox(row,center,center);
    border-radius: $border-radius-lg;
    background: $neutral-6;
    color: $neutral-0;
    margin: 0 $margin 0 0;
    min-width: 24px;
    height: 24px;
    z-index: 3;

    .icon-sm { width: 16px;}
  }

  .state-empty {
    @include flexbox(column,center,flex-end);
    padding: 2*$padding-lg 0;
    text-align: center;

    &-icon {
      min-height: 120px;
      @include flexbox(column,center,flex-end);
    }
  }
}
