.app-slider {
    &-container {
        padding-top: $padding-default;
    }
    &-inner {
        margin-bottom: -8px;
        overflow-x: auto;

        .card-app:first-child {
            .layout-split-right & {
                margin-left: max(50vw - 700px - 207px, 16px);
            }
        }        
    }
    &-container {
        .arrows {
            .btn-secondary {
                border-color: transparent;
                background: none;
                &:hover {box-shadow: none;}
            }  
            .btn {
                &.disabled  {
                    @at-root .library-header .app-slider-container .arrows .btn.disabled {background: none !important;}
                }
                &:hover {background: none;}
            }
        }
    }
}