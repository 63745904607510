// Poor & duplicated code, to be cleaned

.toggle-large {
  position: relative;
  .left {
    position: absolute;
    top: 6px;
    left: -4px;
    color: $neutral-0;
    font-weight: 700;
    .right {display: none;}
  }
  .right {
    position: absolute;
    top: 6px;
    left: 20px;
    color: $neutral-2;
    font-weight: 700;
    .left {display: none;}
  }
  .green {
    position: relative;
    .left {
      position: absolute;
      top: 6px;
      left: -4px;
    }
    .custom-control-label {
      &:before {
        background: $green-3;
        border-color: $green-3;
        width: 60px;
        height: 30px;
        border-radius: 30px;
      }
      &:after {
        background: $neutral-0;
        width: 24px;
        height: 24px;
        right: 33px;
        top: 3px;
      }
    }
    .custom-control-input {
  
      &:not(:disabled) + .custom-control-label {
        &:before {
          background: $primary-3;
          border-color: $primary-3;
        }
        @include hover() {
          &:before {
            border-color: $primary-3;
            background: $primary-3;
            box-shadow: $shadow-hover;
          }
        }
      }

      &:checked:not(:disabled) + .custom-control-label {
        &:after { right: 4px;}
        .left { color: $primary-2;}
      }
    }
  }
  .gray {
    position: relative;
    .rigth {
      position: absolute;
      top: 10px;
      left: 10px;
    }
    .custom-control-input {
      &:checked {
        + .custom-control-label {
          .left { color: $neutral-2;}
          &:after { right: 4px;}
        }
      }
      &:not(:checked) {
        + .custom-control-label:after { right: 33px;}
      }
    }
    .custom-control-label {
      &:before {
        width: 60px;
        height: 30px;
        border-radius: 30px;
        background-color: $neutral-0;
      }
      &:after {
        background: $neutral-3;
        width: 24px;
        height: 24px;
        top: 3px;
      }
    }
    .custom-control-input {
      &:not(:disabled) + .custom-control-label {
        @include hover() {
          &:before { border-color: $primary-3; box-shadow: $shadow-hover;}
          &:after { background: $primary-3;}
          .right { color: $primary-4-transparent;}
        }
      }
    }
  }
}
