.resource-takeover-overlay {
  position: fixed;
  top: 0;
  left: 64px;
  width: 100%;
  height: 100%;
  z-index: 10;
  @include flexbox(column, center, center);
  background: rgba(255,255,255,0.85);
  backdrop-filter: blur(5px);
  &.resource-takeover-absolute {
    position: absolute;
    left: 0;
  }
  & + div {
    max-height: 100vh;
    overflow: hidden;
  }
  &__image {
    padding-top: 66px;
    position: relative;
    & > img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 84px;
      height: 84px;
      object-fit: cover;
      border-radius: 25%;
      z-index: -1;
    }
  }
}