.card-app {
  background: $neutral-0;
  box-shadow: $shadow-small;
  border-radius: $border-radius-lg;
  padding: $padding-default;
  margin-bottom: $margin-default;
  cursor: pointer;
  @include transition(.15s);
  min-height: 100px;
  @include flexbox(column,flex-start,flex-start);

  @include hover() {
    &:not(.card-app-disabled) {
      background: $primary-1;
      box-shadow: $shadow-big;
      .txt-heading, .txt-body { color: $primary-4;}

      .card-app__body__left-icon { background: $neutral-0;}
      .card-app__body__right-icon { .txt-body-small { opacity: 1; max-width: 50px; margin-left: 4px;}}
    }
  }

  &__body {
    display: flex;
    position: relative;
    width: 100%;
    &__left-icon {
      @include transition(.15s);
      background: $neutral-1;
      border-radius: $border-radius-lg;
      @include flexbox(row,center,center);
      width: 48px;
      height: 48px;
    }
    &__content {
      padding: $padding;
      width: calc(100% - 56px);
      margin-bottom: $margin-default;
      //max-width: 345px;
      .txt-body { color: $neutral-4;}
    }
    &__right-icon {
      @include flexbox(row,center,initial);
      position: absolute;
      right: 0;
      top: 0;

      .txt-body-small {
        @include transition(.2s);
        margin-left: 0;
        display: block;
        overflow: hidden;
        max-width: 0;
        opacity: 0;
      }
    }
  }
  &__upgrade {
    position: absolute;
    top: 0;
    right: 0;
  }
  &__action {
    display: flex;
    margin-left: auto;
    margin-top: auto;
  }
  &-disabled {
    cursor: default;
  }
  &-small {
    min-width: 230px;
    flex-direction: initial;
    align-items: center;
    justify-content: space-between;
    min-height: 84px;
    svg {color: $primary-3;}
    span {
      width: 48px;
      height: 48px;
      background-color: $neutral-1;
      border-radius: $border-radius-lg;
      @include flexbox(row,center,center);
    }
    &:hover {
      span {background-color: #fff;}
      box-shadow: 0 2px 8px 0 rgba(#000,.1), 0 4px 20px 0 rgba(#000,.1) !important;
    }
    .txt-body {padding: 0 15px;}
  }
}
