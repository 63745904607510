.no-border { border: none !important;}
.bd-blue-3 { border-color: $primary-3 !important;}
.bd-neutral-2 { border: 1px solid $neutral-2;}

//Border radius
.br-0 { border-radius: 0 !important;}
.br-top-0 { border-top-left-radius: 0 !important; border-top-right-radius: 0 !important;}
.br-bottom-0 { border-bottom-left-radius: 0 !important; border-bottom-right-radius: 0 !important;}
.br-1 { border-radius: $border-radius-sm !important;}
.br-1-5 { border-radius: $border-radius-md !important;}
.br-2 { border-radius: $border-radius-lg !important;}
.br-3 { border-radius: $border-radius-lg-2 !important;}
.br-top-3 { border-top-left-radius: 18px !important; border-top-right-radius: 18px !important;}
.br-bottom-3 { border-bottom-left-radius: 18px !important; border-bottom-right-radius: 18px !important;}
.br-4 { border-radius: $border-radius-xl !important;}
.br-50 { border-radius: 50% !important;}

.br-bottom-2 { border-bottom-left-radius: 8px !important; border-bottom-right-radius: 8px !important; }

//border 
.bl-1 {border: 1px solid $neutral-2;}   // bl = border ligth, to be revised

.br-bottom-0 { border-bottom-left-radius: 0 !important; border-bottom-right-radius: 0 !important; }
.br-top-0 { border-top-left-radius: 0 !important; border-top-right-radius: 0 !important; }