.progress {
  &-line {
    background: $neutral-2;
    position: relative;
    border-radius: 2px;
    perspective: 1px;
    overflow: hidden;
    display: block;
    height: 4px;

    &-bar {
      background: currentColor;
      border-radius: 2px;
      position: absolute;
      perspective: 1px;
      height: 100%;
      left: 0;
    }
  }
}