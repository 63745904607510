// side-panel

&-model {
  position: relative;
  &-name {
    button {
      @include flexbox(row,flex-start,space-between);
      min-height: 0;
    }
  }
  &-image {
    @include flexbox(column,center,center);
    border-radius: $border-radius-sm;
    border: 1px solid $neutral-1;
    position: relative;
    overflow: hidden;
    span {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      background: $neutral-6;
      color: $neutral-0;
      opacity: 0.5;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include flexbox(row,center,center);
    }
    img {
      height: 100%;
      width: 100%;
    }
  }
  &-title {
    @include flexbox(row,flex-start,space-between);
    .btn-sm { min-height: 22px;}
  }
  &-files {
    @include flexbox(row,center,initial);
    .btn-right {
      margin-left: 0;
    }
  }
}