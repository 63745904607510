.list-card-file {
  @include transition(.15s);
  background: $neutral-0;
  border-radius: $border-radius-lg;
  box-shadow: $shadow-small;
  margin: 0 auto $margin-default;
  overflow: hidden;
  width: 100%;
  @include flexbox(row,initial,flex-start);

  @include hover() {
    background: $primary-1;
    cursor: pointer;
    .list-card-file-figure {
      background: $primary-2;
    }
  }
  &-figure {
    background: $neutral-2;
    min-width: 100px;
    width: 100px;
    height: 100px;
    position: relative;
    .slot-bottom {
      position: absolute;
      bottom: 8px;
      right: 8px;
      .card-file-time {
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(20px);
      }
    }
    .thumbnail {
      object-fit: cover;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }
    .preview {
      width: 100px;
      height: 100px;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      opacity: 0;
      transition: 0.15s;
    }
    svg {
      position: absolute;
      left: calc(50% - 27px);
      top: calc(50% - 27px);
    }
  }
}