// Hub Dashboard Page ===============/

.hub-dashboard {
  &-feed {
    background: $neutral-0;
    box-shadow: $shadow-small;
    max-width: 100%;
    flex: 1 0 0;
    position: relative;
    .stats + & { max-width: calc(100% - 350px);}
    .wizard {
      &:after {
        position: absolute;
        content: "";
        top: -16px;
        left: -16px;
        border: 16px solid $neutral-0;
        border-bottom-color: transparent;
        width: 100%;
        height: calc(100% - 16px);
        z-index: -1;
      }
    }
  }
  .hub-favicon-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
}
