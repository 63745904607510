.form-control-date {
  .datepicker {
    @include flexbox(row,center,initial);
    min-height: 30px;
  }
  &.form-control-sm {
    .datepicker {
      font-size: $txt-size-0;
    }
  }

  &.right {
    position: relative;

    .daterange-picker__container { right: 0; margin-top: 12px;}
  }
}

.datepicker-cont {
  position: unset !important;
  box-shadow: unset !important;
  padding: 1px !important;
}

.datepicker-controls {
  display: flex;
  align-items: center;
  justify-content: space-around;

  @include flexbox(row, center, space-around);

  .btn {
    width: 46%;
  }
}

.daterange-picker {
  &__container {
    //padding: $padding-sm;
    background: $neutral-0;
    border-radius: $border-radius-md;
    position: absolute;
    z-index: 999;
    box-shadow: $shadow-big;

    .mx-table-date td, .mx-table-date th {
      height: 30px;
    }

    .mx-datepicker-main {
      border: none;

      .mx-range-wrapper,
      .mx-calendar {
        display: block;

        .mx-calendar+.mx-calendar {
          border: none;
        }

        td {
          display: table-cell;
          text-align: center;
          vertical-align: middle;
        }

        td.active div {
          background-color: $primary-3;
          height: 100%;
          border-radius: $border-radius-md;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-weight: 600;
        }

        .mx-calendar-content .cell {
          transition: .2s;
          border-radius: 20px;
          color: $neutral-4;

          &.not-current-month {
            color: $neutral-2;
          }

          &.active {
            background: transparent;
          }
        }

        .mx-calendar:nth-child(1) {
          td.active {
            background-color: #e9ebfa;
            border-top-left-radius: 25px;
            border-bottom-left-radius: 25px;
          }
        }

        .mx-calendar:nth-child(2) {
          td.active {
            background-color: #e9ebfa;
            border-top-right-radius: 25px;
            border-bottom-right-radius: 25px;
          }
        }

        td.in-range,
        td.hover-in-range {
          color: $primary-3 !important;
          background-color: $primary-1;
          border-radius: 0 !important;
        }

        .mx-table-date {
          td {
            font-size: 14px;

          }
          th {
            font-size: 0;
            font-weight: 600;
            color: $neutral-2;
            vertical-align: middle;

            :first-letter {
              text-transform: uppercase;
              font-size: 11px;
            }
          }
          .today {
            color: $primary-4;
            font-weight: 600;

            &.active {
              color: #fff;
            }
          }
        }
      }
      .mx-btn {
        &:hover {
          color: $primary-4;
        }
      }
      .mx-calendar-header-label {
        .mx-btn {
          font-weight: 400;
          color: $primary-4;
        }
      }
    }
  }

  &__header {
    @include flexbox(row, center, center);
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    padding: 17px 10px 10px;

    &.advanced-datepicker {
      justify-content: flex-start !important;
      div {
        text-align: left;

        h4 {
          margin-bottom: 5px;
          font-weight: 600;

          .f-year {
            color: $secondary !important;
            margin-left: 0;
          }
        }
      }
    }

    span {
      margin-left: 10px;
    }

    svg.icon-btn-close {
      position: absolute;
      right: 16px;
      top: 16px;
      width: 30px;
      cursor: pointer;
    }
  }

  &__selected-dates {
    font-weight: bold;
    text-align: center;
    font-size: 13px;
    padding: 1em;
  }

  &__dropdown-options {
    hr.divider {
      margin: 16px 0 8px !important;
    }
  }

  &__dropdown-section {
    .btn {
      @include flexbox(row, center, initial);
      position: relative;
      padding-left: 34px;
      font-weight: 600;
      font-size: 13px;
      height: 32px;
      width: 100%;

      svg.left {
        transform: translate(-50%,-50%);
        position: absolute;
        max-height: 20px;
        max-width: 19px;
        width: auto;
        left: 17px;
        top: 50%;

        path {
          fill: $secondary !important;
        }
      }

      svg.caret {
        transform: translate(-50%) rotate(180deg);
        position: absolute;
        transition: .15s;
        height: auto;
        right: 0;
        width: 12px;
        top: 50%;

        path {
          fill: #000 !important;
        }
      }

      &.collapsed {
        svg.caret {
          transform: translate(-50%) rotate(0deg);
        }
      }
    }

    .collapse {
      position: relative;

      .mx-datepicker {
        left: -9px;
      }
    }

    .date-nav-options {
      li {
        position: relative;
        top: 3px;
        padding: 5px 8px;
        font-size: 13px;
        font-weight: 600;

        a {
          color: #000 !important;

          svg {
            margin-right: 5px;
            position: relative;
            top: 3px;
          }

          &.active {
            color: $primary-4 !important;
            svg {
              path {
                fill: $primary-4 !important;
              }
            }
          }
        }
      }
    }
  }
}
