&-draggable.empty {
    & > span {
        display: block;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            top: -5px;
            left: 0;
            width: 100%;
            right: 0;
            bottom: -5px;
            z-index: 1;
        }
    }
}