.card-series {
  @include transition(.15s);
  background: $neutral-0;
  border-radius: $border-radius-lg;
  box-shadow: $shadow-small;
  margin: 0 auto $margin-default;
  overflow: hidden;
  width: 100%;

  @include hover() {
    background: $primary-1;
    cursor: pointer;
    .card-series-stats {
      border-color: $primary-2;
    }
    .card-series-cta {
      .btn-group {
        max-width: 110px;
        opacity: 1;
      }
    }
    .card-series-thumbnail .thumbnail-default {
      background: $primary-2;
      color: $primary-3;
    }
  }

  .txt-heading-small {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .skeleton-item { display: flex; }

  &-thumbnail {
    overflow: hidden;
    width: 100%;
    padding: 0 0 56.25%;
    //background: $neutral-6;
    position: relative;
    margin: 0 0 $margin;

    .thumbnail {
      border-radius: $border-radius-sm;
      object-position: center;
      pointer-events: none;
      object-fit: cover;
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 0;
      top: 0;
      left: 0;

      &-default {
        @include flexbox(row,center,center);
        border-radius: $border-radius-sm;
        background: $neutral-2;
        color: $neutral-3;
        pointer-events: none;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
      }
    }

    .dropdown {
      position: absolute;
      right: 12px;
      top: 12px;

      &-toggle { color: $neutral-0;}
    }
  }

  &-inner {
    padding: 0 $padding-default $padding-default;
    position: relative;
  }

  &-cta {
    @include flexbox(row,center,flex-start);

    .btn-group {
      overflow: hidden;
      @include transition(.15s);
      @include inline-flexbox(row,center,center);

      @media (hover: hover) {
        max-width: 0;
        opacity: 0;
      }
    }
  }

  &-stats {
    @include inline-flexbox(row,center,flex-start);
    color: $primary-4;
    height: 32px;
    min-width: 45px;
    padding-right: 12px;
    margin-right: $padding-sm;
    // border-right: 1px solid transparent;
    @include transition(.15s);
  }

  &-bottom {
    @include flexbox(row,flex-end,space-between);
  }

  // Small size layout

  &-sm {
    @include flexbox(row,initial,flex-start);
    padding: $padding;
    min-height: 112px;

    @include hover() {
      .txt-heading-small {
        color: $primary-4;
      }
      .card-series-date {
        // pointer-events: none;
        // opacity: 0;
      }
    }

    .txt-heading-small {
      padding-right: 36px;
      white-space: normal;
      word-break: break-word;
      text-overflow: ellipsis;
      display: -webkit-inline-box;
      -webkit-line-clamp: 2;
              line-clamp: 2;
      -webkit-box-orient: vertical;
      max-height: 51px;
    }

    .card-series {

      &-thumbnail {
        border-radius: $border-radius-sm;
        padding: 0;
        min-width: 96px;
        width: 96px;
        height: 96px;
        margin: 0 $margin 0 0;
      }

      &-inner {
        @include flexbox(column,flex-start,space-between);
        padding: $padding-sm;
        flex: 1 0 0;

        .popover-toggle {
          position: absolute;
          top: $padding-sm;
          right: $padding-sm;
        }
      }

      &-bottom {
        position: relative;
        width: 100%;
      }

      &-date {
        @include transition(.15s);
        position: absolute;
        text-align: right;
        white-space: nowrap;
        opacity: .5;
        bottom: 0;
        right: 0;
      }
    }
  }
  &.selected {
    color: $primary-4;
    background: $primary-2;
    box-shadow: $shadow-small;

    &:before { opacity: 0;}

    &:after {
      background: $primary-2;
      opacity: .9;
    }

    .slot-top { color: $primary-4;}

    .slot-bottom {
      color: $primary-4;

      .txt-body-small { opacity: .5; color: $primary-4;}
    }
  }
  &.new-item {
    @at-root .library-accordion-body .card-series.new-item {
      animation-name: blink;
      animation-duration: 3s;
      @keyframes blink {
        0% {
          background: $orange-1;
        }
        25% {
          background: $neutral-0;
        }
        50% {
          background: $orange-1;
        }
        75% {
          background: $neutral-0;
        }
        100% {
          background: $orange-1;
        }
      }
    }
  }
}
