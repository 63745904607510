// Side-panel-section

&-section {
  @include flexbox(initial, initial, space-between);
  @include transition(.15s);
  background: $neutral-0;
  padding: 15px 15px 0;
  margin-bottom: 1px;
  position: relative;
  cursor: pointer;
  width: 100%;

  &:not(.side-panel-section-disabled) {
    @include hover() {
      background: $primary-1;
      color: $primary-3;
  
      .side-panel-section-figure {
        svg {
          [stroke="#F0F4F3"] { stroke: $primary-2;}
          [stroke="#AFC3B5"] { stroke: $primary-3;}
        }
      }
    }
  }

  &:before {
    content: "";
    position: absolute;
    color: $neutral-2;
    height: 1px;
    width: 100%;
    bottom: 0;
    left: 0;
  }

  &:first-of-type { border-radius: $border-radius-lg $border-radius-lg 0 0;}
  &:last-of-type { border-radius: 0 0 $border-radius-lg $border-radius-lg;}

  &-content {
    @include flexbox(row,flex-start,initial);
    
    .icon-regular {
      margin-right: 10px;
      color: $primary-3;
    
      .side-panel-section-disabled & { color: $neutral-3;}
    }
  }

  .slot-right {
    position: absolute;
    right: 16px;
    top: 16px;
  }

  &-figure { @include flexbox(flex-end);}

  &-wrap {
    border-radius: $border-radius-lg;
    box-shadow: $shadow-small;
    position: relative;
    overflow: hidden;
  }

  &-disabled {
    pointer-events: none;
    color: $neutral-3;

    @include hover() {
      pointer-events: none;
      color: $neutral-3;
    }

    .side-panel-section-figure { color: $neutral-3; opacity: .3;}
  }
}
