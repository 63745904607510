.hub-settings {
  > .side-panel-left { z-index: 6;}
  &-list {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 8px 16px rgba(0, 0, 0, 0.03);
    background: $neutral-0;
    width: 100%;

    &[name="Achievements"] { max-width: none;
      &.hub-settings-list { .wrapper-md.pl-4 { padding-left: 0!important;}}
    }

    > .wrapper-md:not(:has(.ag-header)) { overflow: hidden;}
  }
  &-header { min-height: 80px;}
  &-buttons {
    @include inline-flexbox(row,flex-start,flex-start);
    .btn {
      margin-left: $margin;
      min-width: 80px;
    }
  }
  &-inner {
    max-width: 600px;
    &-large { max-width: 1000px;}
  }
  &-preview {
    position: sticky;
    top: 112px;
    &:after {
      content: "";
      pointer-events: none;
      background: $neutral-2;
      position: absolute;
      display: block;
      right: -16px;
      height: 1px;
      bottom: 3px;
      left: -16px;
    }

    .phone-preview-text {
      color: #031B18;
      overflow-wrap: anywhere;
    }
    .preview-body-android {
      opacity: 0.6;
    }
  }
}
