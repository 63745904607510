// MIO palette ==================/

$mio-0: #06211E;
$mio-1: #8ADC6D;

// Neutral palette ==================/

$neutral-0: #FFFFFF;
$neutral-0-5: #F3F8F7;
$neutral-1: #F0F4F3;
$neutral-2: #D7E0D8;
$neutral-3: #AFC3B5;
$neutral-4: #7B877E;
$neutral-5: #0A3530;
$neutral-6: #031B18;


// Blue palette =====================/

$blue-gradient: linear-gradient(to right, #E0EEFD 0%, #F3F4EA 100%);
$primary-1: #E6F7D1;
$primary-2: #D1EEA9;
$primary-3: #06211E;
$primary-4: #214C47;
$primary-4-transparent: rgba(#568C0B,.2);


// Green palette ====================/

$green-gradient: linear-gradient(to right, #D8EAE3 0%, #FCF2E6 100%);
$green-1: #D6F5EE;
$green-2: #5BD4B7;
$green-3-light: #CBEEE6;
$green-3: #23AD8C;
$green-4: #0E6B55;


// Red palette =====================/

$red-gradient: linear-gradient(to right, #F2E0DD 0%, #FCF2E6 100%);
$red-1: #FFE0E0;
$red-2: #EC6060;
$red-3: #D75050;
$red-4: #BD3A3A;
$red-5: #8B2020;


// Orange palette ==================/

$orange-gradient: linear-gradient(to right, #F4F2E6 0%, #FCF2E6 100%);
$orange-1: #FFE5CF;
$orange-2: #FFD1AA;
$orange-3: #FFB872;
$orange-4: #DC931D;
$orange-5: #A24E00;


// Wisdom AI palette ==============/
$wisdom-cta-gradient: linear-gradient(270deg, #88A2FF 23%, #E68FEE 97%);
$wisdom-cta-gradient-hover: linear-gradient(270deg, #E68FEE 23%, #88A2FF 97%);

// Special palette ================/

$gdrive: #1E88E5;
$odrive: #0078D4;
$obvio: #2262A2;
$canva: #00C4CC;
$vimeo: #19B7EA;
$facebook: #4267B2;
$podcast: #993BC9;
$wistia: #1E64F0;
$dropbox: #0061FF;
$zoom: #4A8CFF;
$stripe: #6772E5;
$youtube: #FF0000;
$convertkit: #44B1FF;
$instagram: #F14282;
$tiktok: #FF004F;


// Mixed gradients ================/

$gradient-blue-green: linear-gradient(131.34deg, #E0EEFD 17.97%, #F3F4EA 88.8%);

