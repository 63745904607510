.hub-editor {

  &-loading {
    @include flexbox(column,center,center);
    background: $neutral-1;
    position: fixed;
    z-index: 1055;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
  }

  &-preview {
    @include flexbox(row,flex-start,center);
    @include transition(.2s);
    box-shadow: $shadow-big;
    position: absolute;
    left: 0;
    top: 64px;
    bottom: 0;
    right: 0;

    &.preview-mobile {
      overflow: auto;
      padding: $padding-default;

      iframe {
        border-radius: $border-radius-lg;
        box-shadow: $shadow-small;
        width: 375px;
        height: 812px;
      }
    }

    iframe {
      @include transition(.3s);
      margin: auto;
      height: 100%;
      width: 100%;
    }
    &-overlay {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background: rgba(0,0,0,.3);
    }
  }
  .form-control-wrap, .form-control-text {
    scroll-margin-top: 80px;
  }
}

#exit-editor-dialog {
  .modal-dialog {
    margin: auto auto 0 0;
    align-items: flex-end;
    min-height: 100%;
    padding: 1rem;
  }
}
