//
// Base styles
//

.alert {
  @include flexbox(row,flex-start,flex-start);
  border-radius: $border-radius-lg;
  --corner-smoothing: 60%;
  margin-bottom: $margin;
  padding: $padding-default;
  position: relative;
  border: none;
}

// Headings for larger alerts
.alert-heading {
  // Specified to prevent conflicts of changing $headings-color
  color: inherit;
}

// Provide class for links that match alerts
.alert-link {
  font-weight: $alert-link-font-weight;
  color: inherit;
  cursor: pointer;
}

.alert-icon {
  margin-right: $margin;
  margin-top: 2px;
}

.alert-count {
  margin-left: auto;
  min-width: 32px;
  height: 32px;
  @include inline-flexbox(row,center,center);
  border-radius: $border-radius-sm;
  padding: $padding-sm;
  font-size: $txt-size-1;
  font-weight: 700;
  color: $neutral-0;
}


// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
  padding-right: $close-font-size + $alert-padding-x * 2;

  // Adjust close link position
  .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: $alert-padding-y $alert-padding-x;
    color: inherit;
  }
}


// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

.alert {
  &-info {
    color: $primary-4;
    background: $primary-1;

    hr {
      border-top-color: $primary-4;
    }

    .alert-count { background: $primary-3;}

    //.alert-link {
      //color: $orange-5;
    //}
  }
  &-success {
    color: $green-4;
    background: $green-1;

    hr {
      border-top-color: $green-4;
    }

    .alert-count { background: $green-3;}

    //.alert-link {
      //color: $orange-5;
    //}
  }
  &-warning {
    color: $orange-5;
    background: $orange-1;

    hr {
      border-top-color: $orange-5;
    }

    .alert-count { background: $orange-4;}

    .alert-link { color: $orange-5;}
  }
  &-error {
    color: $red-4;
    background: $red-1;

    hr {
      border-top-color: $red-4;
    }

    .alert-count { background: $red-3;}

  }
  //duplicate of info no need for it
  //&-primary {
  //  color: $primary-4;
  //  background: $primary-1;
  //  hr {
  //    border-top-color: $primary-4;
  //  }
  //}
}
