.card-folder {
  @include inline-flexbox(column,initial,space-between);
  margin: 0 $margin $margin-default;
  padding: $padding-default 0 0;
  position: relative;
  color: $neutral-6;
  min-width: 200px;
  cursor: pointer;

  &:before {
    border-radius: $border-radius-lg $border-radius-lg 0 0;
    @include transition(.15s);
    position: absolute;
    border-bottom: 16px solid $neutral-0;
    border-right: 18px solid transparent;
    box-sizing: border-box;
    width: 96px;
    content: "";
    top: 0;
    left: 0;
  }

  &-loading {
    pointer-events: none;
    order: 100;
    &:before { border-bottom-color: #D7E0D8;}
    .card-folder-content { background: #D7E0D8;}
  }

  .slot-open-library {
    position: absolute;
    top: 24px;
    right: 12px;
  }

  &-content {
    @include flexbox(column,flex-start,flex-start);
    @include transition(.15s);
    background: $neutral-0;
    box-shadow: $shadow-small;
    padding: 0 $padding-default $padding;
    border-radius: 0 $border-radius-lg $border-radius-lg $border-radius-lg;
    min-height: 163px;

    .txt-heading-small {
      display: block;
      max-width: 100%;
      margin-top: auto;
      text-overflow: ellipsis;
      pointer-events: none;
      white-space: nowrap;
      overflow: hidden;

      &.with-pointer-events {
        pointer-events: all;
      }
    }

    .slot-thumbnails {
      margin: $margin-default 0 $margin;
      pointer-events: none;
      display: block;
      overflow: hidden;
      width: 100%;

      &:first-child {
        margin-top: $margin-default + $margin-lg;
      }

      > div {
        position: relative;

        .thumbnail-img {
          border-radius: $border-radius-sm;
          overflow: hidden;
          object-position: center;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      .thumbnail {
        border-radius: $border-radius-md;
        overflow: hidden;

        &:first-child {
          padding-bottom: calc((68% - 8px) * 56.25 / 100);
          width: calc(68% - 8px);
          margin-right: 8px;
          display: inline-block;
          float: left;
        }

        &:nth-child(2) {
          padding-bottom: calc((32% - 8px) * 56.25 / 100);
          width: 32%;
          display: inline-block;
          float: left;
        }

        &-default {
          @include transition(.15s);
          background: $neutral-2;
          color: $neutral-4;

          > svg {
            transform: translate(-50%,-50%);
            position: absolute;
            max-height: 50%;
            width: auto;
            left: 50%;
            top: 50%;
          }
        }

        &-count {
          border-radius: $border-radius-md;
          @include transition(.15s);
          position: relative;
          background: $neutral-1;
          color: $neutral-4;
          float: left;

          &.no-thumb-bg {
            background: $neutral-1;
          }

          &:first-child { padding-bottom: calc(36.16% - 7px); width: 100%; margin-top: 7px;}
          &:nth-child(2) { width: 32%; padding-bottom: 36.16%;}
          &:nth-child(3) { width: 32%; padding-bottom: calc(18.08% - 4px); margin-top: $margin;}

          .txt-body {
            transform: translateY(-50%);
            position: absolute;
            left: 0;
            width: 100%;
            text-align: center;
            white-space: nowrap;
            top: 50%;
          }
        }
      }
    }
  }

  &.sm {
    .card-folder-content { min-height: 130px;}
  }

  @include hover() {
    color: $primary-4;

    &:before {
      border-bottom-color: $primary-1;
    }

    .card-folder-content {
      background: $primary-1;
      box-shadow: $shadow-big;

      .txt-muted { color: $primary-3 !important;}

      .card-file-status {
        color: $primary-4;

        .txt-body-small { color: $primary-3; opacity: 1;}
      }

      .slot-thumbnails {
        .thumbnail-count {
          background: rgba($primary-2,.7);
          color: $primary-4;
        }
        .thumbnail-default {
          background: $primary-2;
          color: $primary-4;
        }
      }
    }
  }

  &.selected {
    color: $primary-4;

    &:before {
      border-bottom-color: $primary-1;
    }

    .card-folder-content {
      background: $primary-1;
      box-shadow: $shadow-small;

      .card-file-status {
        color: $primary-4;

        .txt-body-small { color: $primary-3; opacity: 1;}
      }

      .slot-thumbnails {
        .thumbnail-count {
          background: rgba($primary-2,.45);
          color: $primary-4;
        }
        .thumbnail-default {
          background: $primary-2;
          color: $primary-4;
        }
      }
    }
  }

  // List variant =============================/

  &-list {
    margin: 0 0 $margin;
    padding: 0;

    @include hover() {
      .card-file-status {
        .card-file-count { color: $primary-3;}
      }
    }

    &:before { display: none;}

    .card-folder-content {
      border-radius: $border-radius-lg;
      padding: $padding-sm $padding $padding-sm 12px;
      flex-direction: row;
      align-items: center;
      min-height: 48px;

      .txt-heading-small {
        pointer-events: all;
        line-height: 1;
        margin: 0;
      }
    }

    .card-file-status {
      white-space: nowrap;
      margin-left: auto;
      flex: 45% 0 0;
      width: auto;

      .card-file-count {
        display: inline-flex;
        margin-right: auto;
        color: $neutral-4;
        text-align: left;
        min-width: 58px;
      }

      .card-file-stat {
        margin-left: 30px !important;
        order: 10;
      }
    }
  }
}
