// Sidepanel link-row / extension of .side-panel

&-link-row {
  @include flexbox(row,center,flex-start);
  border-radius: $border-radius-md;
  justify-content: space-between;
  border: 1px solid $neutral-2;
  align-items: center;
  display: flex;
  height: 48px;
  width: 100%;

  .handle {
    display: inline-flex;
    cursor: grab;
  }

  p {
    text-align: left;
    padding: 11px 0 11px 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0;
    overflow: hidden;
    &:focus {
      outline: none;
    }
  }
  .show-pencil {opacity: 0;}
  &:hover {.show-pencil {opacity: 1}}
}
