.auth-page {
  display: block;
  //padding-left: 440px;
  background-color: $neutral-0;
  overflow: auto;
  height: 100vh;
  &-inner {
    display: flex;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      height: auto;
      min-height: 100vh;
      padding-left: 0;
    }
  }

  // Slider ===============================================/
  &-slider {
    max-width: 50vw;
    padding: 20px;
    width: 640px;
    @include media-breakpoint-down(lg) { padding-left: 0;}
    @include media-breakpoint-down(sm) { max-width: 100%; width: 100%; padding-left: 20px;}

    &-inner {
      border-radius: $border-radius-xl;
      position: relative;
      overflow: hidden;
    }

    .glider-slide[class*="auth-slide"] {
      @include flexbox(column,flex-start,center);
      height: calc(100vh - 40px) !important;
      border-radius: $border-radius-xl;
      padding: 50px 60px 120px;
      @media (max-height: 900px) and (max-width: 991px)  { padding: 40px 40px 110px 60px;}
      @include media-breakpoint-down(sm) { height: auto !important; padding: 40px 30px 110px 50px; flex-wrap: wrap;}
      @include media-breakpoint-down(xs) { padding: 30px 30px 90px 30px; .ml-4 { margin-left: 0 !important;}}
    }

    .auth-slider {
      border-radius: $border-radius-xl;
      position: relative;
      overflow: hidden;

      .txt-heading-small.txt-muted > b.txt-primary {
        &:after { content: " | "; color: $neutral-4; margin: 0 3px; @include media-breakpoint-down(xs) { display: none;}}
        @include media-breakpoint-down(xs) { display: block;}
      }

      &-figure {
        @include inline-flexbox(column,initial,initial);
        margin-bottom: 70px;
        position: relative;
        @media (max-height: 900px) { margin-bottom: 40px;}

        &:before {
          content: "";
          border-radius: 12px;
          mix-blend-mode: multiply;
          background: $primary-1;
          position: absolute;
          display: block;
          width: 95%;
          height: 97%;
          top: 13%;
          left: 17%;

          @at-root .auth-page-slider .auth-slide-colleen .auth-slider-figure:before { background: $green-1;}
          @at-root .auth-page-slider .auth-slide-juan .auth-slider-figure:before { background: $red-1;}
        }

        &.ml-auto:before { left: auto; right: 17%;}
      }

      &-img {
        border-radius: $border-radius-lg;
        box-shadow: $shadow-big;
        position: relative;
        height: auto;
        width: 248px;
        @include media-breakpoint-down(lg) { width: 200px;}
        @media (max-height: 700px) { width: 150px;}
        @include media-breakpoint-down(xs) { width: 150px;}
      }

      &-blockquote {
        font-size: $txt-size-2;
        line-height: 1.5;
        max-width: 413px;
        @include media-breakpoint-down(lg) { font-size: $txt-size-1;}
        @include media-breakpoint-down(xs) { margin-left: 0 !important; padding-top: 30px;}

        &:before {
          content: url("data:image/svg+xml,%3Csvg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.23224 11.568C9.23224 13.674 7.55824 15.51 5.02024 15.51C2.15824 15.51 0.322244 13.188 0.322244 10.326C0.322244 3.09 5.29024 0.659998 8.69224 0.335999V3.63C6.64024 3.954 4.37224 5.628 4.26424 8.112C4.53424 7.95 4.91224 7.842 5.39824 7.842C7.82824 7.842 9.23224 9.3 9.23224 11.568ZM19.8702 11.568C19.8702 13.674 18.1962 15.51 15.6582 15.51C12.7962 15.51 10.9602 13.188 10.9602 10.326C10.9602 3.09 15.9282 0.659998 19.3302 0.335999V3.63C17.2782 3.954 15.0102 5.628 14.9022 8.112C15.1722 7.95 15.5502 7.842 16.0362 7.842C18.4662 7.842 19.8702 9.3 19.8702 11.568Z' fill='black'/%3E%3C/svg%3E%0A");
          transform: translate(-70%, -50%);
          display: inline-block;
          margin-left: -20px;
          @include media-breakpoint-down(xs) { margin-left: -10px; margin-right: -10px; transform: translate(0, -110%);}
        }

        &:after {
          content: url("data:image/svg+xml,%3Csvg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.23224 11.568C9.23224 13.674 7.55824 15.51 5.02024 15.51C2.15824 15.51 0.322244 13.188 0.322244 10.326C0.322244 3.09 5.29024 0.659998 8.69224 0.335999V3.63C6.64024 3.954 4.37224 5.628 4.26424 8.112C4.53424 7.95 4.91224 7.842 5.39824 7.842C7.82824 7.842 9.23224 9.3 9.23224 11.568ZM19.8702 11.568C19.8702 13.674 18.1962 15.51 15.6582 15.51C12.7962 15.51 10.9602 13.188 10.9602 10.326C10.9602 3.09 15.9282 0.659998 19.3302 0.335999V3.63C17.2782 3.954 15.0102 5.628 14.9022 8.112C15.1722 7.95 15.5502 7.842 16.0362 7.842C18.4662 7.842 19.8702 9.3 19.8702 11.568Z' fill='black'/%3E%3C/svg%3E%0A");
          transform: translate(100%, 60%);
          display: inline-block;
          @include media-breakpoint-down(xs) { transform: translate(50%, 60%);}
        }

        &-inner { opacity: .7; padding: 4px 0;}
        &.small { max-width: 350px;}
      }

      &-illustration {
        position: absolute;
        @include media-breakpoint-down(lg) { transform: scale(.8)}
        @media (max-height: 1000px) { transform: scale(.8);}
        @media (max-height: 800px) { transform: scale(.5);}

        &.a1 { top: 2%; right: 0; transform-origin: center right;}
        &.a2 {
          bottom: 7%; left: 8%; transform-origin: center left;
          @media (max-height: 1000px) { bottom: 3%; left: 4%;}
          @media (max-height: 700px) { bottom: 0; left: 0;}
        }
        &.b1 { top: 10%; left: 22%; transform-origin: center left;}
        &.b2 { bottom: 14%; right: 0; transform-origin: center right; @include media-breakpoint-down(md) { bottom: 4%;}}
        &.c1 { top: 50%; left: 0; transform-origin: center left;}

        &-wrap {
          pointer-events: none;
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          top: 0;
        }
      }

      &-dots {
        align-items: center;
        position: absolute;
        bottom: 20px;
        width: 100%;
        left: 0;
    
        .glider-dot {
          background: $neutral-3;
          transition: .2s;
          opacity: .5;
          margin: 9px;
          height: 8px;
          width: 8px;
    
          &.active {
            background: $primary-3;
            width: 12px;
            height: 12px;
            opacity: 1;
          }
        }
      }
    }

    .auth-slide {

      &-amy { background: $blue-gradient;}
      &-colleen { background: $green-gradient;}
      &-colleen { background: $green-gradient;}
      &-juan { background: $red-gradient;}      
    }
  }
  /*
  &-sidebar {
    position: fixed;
    min-height: 100vh;
    width: 440px;
    z-index: 2;
    left: 0;
    top: 0;
    @include media-breakpoint-down(md) { width: 50vw;}
    @include media-breakpoint-down(sm) {
      width: 100%;
      position: relative;
      height: 100vh;
      min-height: 330px;
      left: auto;
      top: auto;
      order: 2;
    }
    @include media-breakpoint-down(xs) { height: 173vw;}
    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      z-index: 0;
    }
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $neutral-6;
      mix-blend-mode: normal;
      opacity: 0.35;
      z-index: 1;
    }
    .inner {
      padding: 48px 48px 64px;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 2;
      height: 100%;
      @include flexbox(column, flex-start, space-between);

      @include media-breakpoint-down(md) {
        padding: $padding-lg;
      }
    }
  }*/
  &-content {
    @include flexbox(column, initial, initial);
    padding: $padding-lg;
    overflow: auto;
    height: 100vh;
    flex: 1 0 0;
    @include media-breakpoint-down(sm) {
      min-height: 100vh;
      overflow: hidden;
      flex: auto 0 0;
      height: auto;
    }
    .inner {
      margin: 8vh auto auto;
      max-width: 470px;
      width: 100%;
      height: 100%;
      @include media-breakpoint-down(xs) { margin-top: 50px;}
      position: relative;

      // Plans
      .plans-wrapper-full {
        width: calc(100vw - 48px);
        left: 50%;
        margin-left: calc(-50vw + 235px + 24px);
        @media screen and (max-width: 520px) { margin-left: 0; left: 0; width: 100%;}


        .plans {
          grid-auto-columns: fit-content(340px);
          padding-bottom: $padding-lg;
          display: inline-grid;
          @include media-breakpoint-down(xs) { grid-auto-columns: fit-content(290px);}

          &-inner { align-items: center;}
        }
      }
      .plans-bottom-button {
        position: sticky;
        z-index: 9;
        bottom: -20px;
        background-color: $neutral-0;
        & > div {
          margin-bottom: 0 !important;
          padding-bottom: 35px !important;
        }
      }
      .plans-details {
        margin-bottom: 0 !important;
        button::after {display: none};
        button::before {display: none};
      }
    }
    form {
      @include flexbox(column, initial, initial);
    }
  }
  &-cta {
    @include flexbox(row,center,center);
    flex-wrap: wrap;
    position: relative;

    &.with-arrow {
      @include media-breakpoint-down(sm) { margin-bottom: 65px;}
      &:after {
        content: url("data:image/svg+xml,%3Csvg width='114' height='114' viewBox='0 0 114 114' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M97.9349 75.9287C71.4182 91.8387 27.5586 75.485 9.05351 43.0608' stroke='black' stroke-width='3.46856' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.6671 63.1998C10.5643 54.7818 9.80558 51.2483 8.6715 42.8615L26.6075 49.8559' stroke='black' stroke-width='3.46856' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M72.6704 74.3743C89.6906 75.8271 104.29 62.3667 100.432 49.139' stroke='black' stroke-width='3.46856' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        pointer-events: none;
        position: absolute;
        top: 30%;
        left: 83%;
        @include media-breakpoint-down(lg) {
          transform: scaleX(-1) rotate(19deg);
          left: 45%;
          top: 60%;
        }
      }
    }
  }
  &-custom-details {
    padding: 145px 140px 20px 0;
    width: 100%;
    overflow: auto;
    .txt-heading {
      margin-bottom: 4px;
    }
    .auth-page-custom-detail {
      &-image {
        position: relative;
        margin-bottom: 20px;
        img {
          width: 100%;
          height: auto;
        }
        .effect-right {
          position: absolute;
          top: -42px;
          right: -40px;
        }
      }
      &-paragraph {
        margin-bottom: 34px;
      }
      &-list {
        li { @include flexbox(row,center,initial); }
      }
      hr { margin: 24px 0;}
    }
    .auth-page-custom-detail-faq__icon, .auth-page-custom-detail-list__icon {
      flex: 0 0 26px;
      height: 26px;
      background-color: $primary-1;
      border-radius: 13px;
      @include flexbox(row,center,center);
    }

    &--fml {
      border-radius: 30px 30px 43px 43px;
      border: 2px solid #06211E;
      border-bottom-width: 13px;
      position: relative;
      background: #DCFBB4;
      margin: 9vh -20px auto;
      padding: 30px 55px 50px;
      width: calc(100% + 40px);
      @media (max-width: 1300px) { width: 100%; margin: 9vh 0 auto;}
      @include media-breakpoint-down(md) { padding: 12px 30px 30px !important;}
      @include media-breakpoint-down(xs) { padding: 10px 20px 20px !important;}

      .txt-heading {
        margin-bottom: $margin-default;
        font-size: $txt-size-5;
        line-height: 1.2;
        @include media-breakpoint-down(xs) { font-size: $txt-size-4; &-small { font-size: $txt-size-1 !important;}}
      }

      .auth-page-custom-detail-list__icon {
        background: $primary-4;
        margin-bottom: auto;
        svg path { stroke: $primary-1;}
      }
    }
  }
  &.with-details {
    width: 100%;
    height: 100vh;
    .auth-page-content {
      .inner {
        margin: 8vh 0 0;
      }
    }
    .auth-page-inner {
      max-width: 1240px;
      padding: 30px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr 520px;
      gap: 48px;
      height: 100vh;
      position: relative;
      .auth-page-content {
        overflow: scroll;
        padding: 0 0 0 140px;
        .effect-left {
          position: absolute;
          top: 65px;
          left: -165px;
        }
        &:has(+ .auth-page-custom-details--fml) { padding-left: 0; align-items: center; .effect-left { display: none;}}
      }
      @include media-breakpoint-down(lg) {
        grid-template-columns: 1fr 1fr;
      }
      @include media-breakpoint-down(md) { 
        grid-template-columns: 1fr;
        overflow: scroll;
        .scroll-to-top {
          left: calc(50% - -250px)!important;
        }
        .auth-page-content {
          overflow: initial;
          height: 100%;
          padding: 0;
          margin: 0 auto;
          width: 100%;
          .inner {
            margin: 0 auto;
            .effect-left {
              display: none;
            }
          }
        }
        .auth-page-custom-details {
          overflow: initial;
          text-align: left;
          padding: 0;
          max-width: 470px;
          margin: 0 auto;
          .auth-page-custom-detail-image {
            max-width: 600px;
            margin: 0 auto;
          }
        }
      }
      @include media-breakpoint-down(sm) {
        .auth-page-content {
          min-height: auto;
          height: 100%;
        }
        .auth-page-custom-details {
          padding: 0;
          .effect-right {
            display: none;
          }
          .auth-page-custom-detail-image {
            max-width: 600px;
          }
        }
        .scroll-to-top {
          right: 0!important;
          margin-right: 0!important;
        }
      }
      .scroll-to-top {
        position: sticky;
        bottom: 16px;
        left: calc(50% - -465px);
        transition: bottom 0.3s ease;
        z-index: 999;
        margin-right: 70px;
        border-radius: $border-radius-md;
      }
    }
  }
}

.onboarding {

  .txt-title { max-width: 520px;}
  
  &-steps {
    @include inline-flexbox(row,center,center);
    padding-bottom: 80px;
    position: relative;
    overflow: hidden;

    //@include media-breakpoint-down(xs) {
    //  &.compact {
    //    transform: translateX(-50%);
    //    left: 50%;
    //  }
    //}

    &:before {
      content: "";
      border-bottom: 1px solid $neutral-2;
      @include transition(.2s);
      transform: translateY(-50%);
      position: absolute;
      display: block;
      right: calc(44px + 24px);
      left: calc(44px + 24px);
      top: 24px;
    }
    &-progress {
      border-bottom: 1px solid $primary-3;
      transition: .3s ease-in;
      position: absolute;
      margin-left: calc(44px + 24px);
      display: block;
      width: auto;
      left: 0;
      top: 24px;
    }
    &-item {
      @include transition(.4s);
      text-align: center;
      position: relative;
      opacity: 1;
      margin: 0 44px;
      width: 48px;
      z-index: 3;

     // @include media-breakpoint-down(lg) {
     //   margin: 0 24px;
     // }
      @include media-breakpoint-down(xs) {
        .onboarding-steps.compact & {
          margin: 0 30px;
        }
      }
    }
    &-num {
      @include flexbox(row,center,center);
      border: 1px solid $neutral-2;
      @include transition(.2s);
      background: $neutral-0;
      border-radius: 50%;
      font-size: $txt-size-2;
      line-height: 1;
      color: $neutral-4;
      position: relative;
      height: 48px;
      width: 48px;

      &.completed {
        border-color: $primary-3;
        color: $primary-4;

        .onboarding-steps-label { color: $neutral-4;}
      }

      &.active {
        border-color: transparent;
        background: $primary-3;
        color: $neutral-0;

        .onboarding-steps-label { color: $neutral-6;}
      }
    }
    &-label {
      transform: translateX(-50%);
      @include transition(.2s);
      white-space: nowrap;
      position: absolute;
      padding: $padding;
      left: 50%;
      top: 100%;
    }
  }

  &-cta {
    @include flexbox(row,center,center);
    margin: 0 -$margin-sm;

    .btn-block {
      margin: 0 $margin-sm;
      flex: 1 0 0;
    }
  }

  &-finish {
    @include flexbox(column,center,center);
    min-height: calc(100vh - 48px);
    text-align: center;
    padding: 50px 0;

    .txt-title { min-height: 41px;}

    &-figure {
      @include flexbox(row,center,initial);
      @include transition(.2s);
      border: 3px solid $neutral-2;
      box-sizing: content-box;
      position: relative;
      margin: 72px 0 112px;
      border-radius: 50%;
      color: $neutral-0;
      width: 164px;
      height: 164px;

      &:before {
        //content: "";
        @include transition(.2s);
        border-bottom: 1px solid $neutral-2;
        position: absolute;
        display: block;
        right: 100%;
        width: 50vw;
        top: 50%;
      }

      svg {
        @include transition(.4s);
        overflow: hidden;
        width: 0;
      }

      &.unlocked {
        background: $green-3;
        border-color: $green-3;
        &:before { border-color: $primary-3;}

        svg { width: 164px;}
      }
    }
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    @include media-breakpoint-down(xs) {
      grid-template-columns: repeat(1, 1fr);
      column-gap: 0;
      row-gap: $padding;
    }

    &-item {
      position: relative;
      height: 116px;

      .custom-control-input:checked {
        + .custom-control-label {
          box-shadow: none;
          background: $primary-1;
          @include hover() { color: $primary-3;}
          .card-app__body__left-icon { background: $neutral-0;}
        }
      }

      .custom-control-label {
        @include flexbox(row,center,flex-start);
        border-radius: $border-radius-lg;
        box-shadow: $shadow-small;
        background: $neutral-0;
        position: absolute;
        transition: .2s;
        cursor: pointer;
        padding: 34px;
        
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;

        @include hover() { box-shadow: $shadow-big;}

        &:before,&:after { display: none;}
      }
    }
  }

  &-migration {
    @include flexbox(column,initial,center);
    padding: 50px 0 30px;
    min-height: 350px;
  }
}
