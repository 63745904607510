.radio-option {
  list-style: none;
  @include hover() {
    .radio {
      border-color: $primary-4;

      &:not(.active) {
        box-shadow: $shadow-hover;
      }
    }
  }

  .radio {
    border: 1px solid $neutral-2;
    @include transition(.15s);
    background: $neutral-0;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    box-shadow: none;
    height: 24px;
    width: 24px;
    margin-right: 8px;
  
    @include hover() {
      box-shadow: $shadow-hover;
      border-color: $primary-4;
    }
  
    &.active {
      border-color: $primary-4;
      border-width: 6px;
    }
  }
}