.no-min-height { min-height: 0 !important;}
.no-min-width { min-width: 0 !important;}

.w-100 { width: 100% !important;}
.maxw-100 { max-width: 100% !important;}
.w-auto { width: auto !important;}
.h-100 { height: 100% !important;}
.h-auto { height: auto !important;}

.resize-none { resize: none !important;}
