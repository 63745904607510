@import "resources/utils/Badge/badge";

.achievement-preview {
  @include flexbox(row,center,space-evenly);
  border-radius: $border-radius-lg $border-radius-lg 0 0;
  background: rgba($neutral-1,.5);
  padding-bottom: 48px;
  min-height: 280px;

  + .nav-tabs-lined { margin-top: -48px;}

  &-prev {
    margin: -$margin-default $margin-lg 0;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 0;
  }

  &-next {
    @extend .achievement-preview-prev;
    left: auto;
    right: 0;
  }

  &-icon {
    .swiper & {
      transform: translate(-50%, -50%);
      position: absolute;
      z-index: 2;
      left: 50%;
      top: 50%;
    }
  }
}

.card-email-notification {
  display: flex;
  align-items: flex-start;

  .email-link {
    display: flex;
    align-items: center;
  }

  .category-title {
    font-size: 17px;
  }
}

.achievement-name {
  @include flexbox(row,center,initial);
  > .txt-no-wrap { max-width: 40%;}
}

