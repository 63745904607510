&-title {
  @include flexbox(column,flex-start,flex-start);
  position: relative;
  width: 100%;

  > .inner {
    @include flexbox(row,flex-start,initial);
    width: 100%;
  }

  &-placeholder {
    padding: $padding $padding-sm;
    position: absolute;
    color: $primary-4;
    margin: -6px 0 0;
    top: 0;
    left: 0;
  }

  [contenteditable]:not([contenteditable="false"]) {
    transition: border-color .15s, background .15s, color .15s;
    border-radius: $border-radius-md;
    margin: -6px 0 -6px !important;
    border: 1px solid transparent;
    padding: 6px 10px 6px 0;
    word-break: break-word;
    position: relative;
    width: 100%;
    z-index: 1;
    &.in-focus {
      padding: 6px 115px 6px 8px;
      border-color: $neutral-2;
      background: $neutral-0;
      color: $primary-3;
      outline: none;

      @include hover() {
        border-color: $neutral-2 !important;
        background: $neutral-0 !important;
        color: $primary-3 !important;
      }
    }
    [contenteditable="false"] {
      width: 100%;
    }
    &:not([contenteditable="false"]) {
      @include hover() {
        background: rgba(#AFC3B5,.2);
        border-color: $neutral-2;
        color: $neutral-6;
        cursor: text;
      }
    }
    /* Strange edge case when copying text with copy all */
    > h3 {
      padding-right: 0 !important;
      padding-left: 0 !important;
      width: auto !important;
      background: none !important;
      color: inherit !important;
      outline: inherit !important;
    }

    .side-panel-description__content,.txt-title-small {
      background: transparent !important;
      width: auto !important;
      padding: 0 !important;
      color: inherit;
      border: none !important;
      font-size: inherit !important;
      line-height: inherit !important;
      display: inline;
    }

    .with-extension::after { display: none;}
    &::selection { background: $primary-4-transparent;}
  }
  &-actions {
    @include flexbox(row,center,flex-end);
    flex: 0 0 110px;
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 2;
  }
  & + .btn {
    margin-left: $margin-default !important;
  }

  &-buttons {
    @include inline-flexbox(row,center,flex-end);
    padding-top: $padding-sm;
    margin-bottom: $margin-sm;
    padding-left: $padding;

    + .side-panel-title-actions { right: 75px;}
  }

  &.is-invalid {
    [contenteditable], [contenteditable]:focus, [contenteditable]:not([contenteditable="false"]):hover, &-actions, .btn, p, .side-panel-title-placeholder {
      color: $red-3;
      border-color: $red-3;
    }
  }
  &-icon {
    width: 48px;
    height: 48px;
    flex: 0 0 48px;
    border-radius: $border-radius-lg;
    background-color: $neutral-0;
    @include inline-flexbox(initial,center,center);
  }
}
