// Sidepanel option / extension of .side-panel

&-widget {
  border-radius: $border-radius-lg-2;
  box-shadow: $shadow-small;
  background: $neutral-0;
  margin: 0 0 16px;

  .popover-body & {
    box-shadow: none;
    box-shadow: $shadow-big;
    width: 318px;
  }

  @at-root .side-panel-visibility-dropdown[x-placement="bottom"] {
    margin-top: -106px;
  }

  &-group {
    .side-panel-widget:not(:last-of-type) {
      border-radius: 0;
      margin-bottom: 1px;
    }
    .side-panel-widget:first-of-type {
      border-radius: $border-radius-lg-2 $border-radius-lg-2 0 0 ;
    }
    .side-panel-widget:last-of-type {
      border-radius: 0 0 $border-radius-lg-2 $border-radius-lg-2;
    }
    .side-panel-option:not(:last-of-type):not(.is-locked) {
      border-bottom: 1px solid $neutral-2;
    }

    //guys be carefull when adding stuff with only root component class
  }
  .with-actions {
    .input-group {
      input {
        padding-right: 80px;
      }
    }
  }
}

&-option {
  @include flexbox(row,flex-start,flex-start);
  @include transition(.15s);
  background: transparent;
  position: relative;
  padding: 20px 24px 24px;
  cursor: pointer;

  &.v-centered { align-items: center;}

  &-transition {
    transition: background-color .15s linear;
  }

  &-bg {
    background: $primary-1;
    color: $primary-4;
  }

  &:not(.is-locked):not(.disabled) {
    @include hover() {
      background: $primary-1;
      color: $primary-4;

      .txt-muted { color: $primary-3;}

      .custom-control-label {
        &:before {
          border-color: $primary-4;
        }
      }
    }
  }

  &-wrap {
    border-radius: $border-radius-lg-2;
    overflow: hidden;
    padding: 0;
    
    &.type-checkbox { .inner > h3 { font-size: $txt-size-1;}}
    &.with-border { border: 1px solid $neutral-2;}
  }

  .slot-left {
    margin: 0 $margin-default 0 0;
    color: $primary-3;
  }

  .slot-right {
    display: flex;
    padding: 0 0 0 $margin-default;
    margin: 0 0 0 auto;
  }

  .inner {
    .status-On {
      color: $green-3;
    }
    .status-Off {
      color: $neutral-4;
    }
  }

  .txt-heading-small {
    margin-bottom: $margin-sm;
    word-break: break-word;
  }

  // Line indicator =================/

  .line {
    border-radius: 0 3px 3px 0;
    background: $primary-3;
    position: absolute;
    height: 26px;
    width: 3px;
    top: 18px;
    left: 0;
  }

  // Nav state
  &.is-locked, &.disabled {
    // pointer-events: none;
    .txt-heading-small {
      color: $neutral-3;
    }
    .slot-left {
      > .icon-regular { color: $neutral-3;}
    }
    .slot-right {
      color: $neutral-2;
    }
  }

  &.is-locked {
    cursor: initial;
    .slot-left .radio {
      border: 6px solid $neutral-2;
    }
    @include hover() {
      .slot-left .radio {
        &:not(.active) {
          box-shadow: none;
        }
        border-color: $neutral-2;
      }
    }
  }
  // Radio variant =================/

  &.type-radio {

    @include hover() {
      .radio {
        border-color: $primary-4;

        &:not(.active) {
          box-shadow: $shadow-hover;
        }
      }
    }

    .counter {
      @include inline-flexbox(row,center,center);
      border-radius: $border-radius-md;
      background: rgba($primary-4,.1);
      font-size: $txt-size-1;
      padding: $padding-sm;
      text-align: center;
      font-weight: 700;
      min-width: 32px;
      color: $primary-3;
      height: 32px;
    }

    &.radio-simple {
      padding: 10px 24px;
      align-items: center;
      .slot-left {
        display: inline-flex;
      }
    }
  }

  .radio {
    border: 1px solid $neutral-2;
    @include transition(.15s);
    background: $neutral-0;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    box-shadow: none;
    height: 24px;
    width: 24px;

    @include hover() {
      box-shadow: $shadow-hover;
      border-color: $primary-4;
    }

    &.active {
      border-color: $primary-4;
      border-width: 6px;
    }
  }

  // modal body block
  &.modal-body-block {
    margin-right: -32px;
    margin-left: -32px;
    padding-left: 32px;
    padding-right: 32px;
  }
}
