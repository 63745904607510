.hub-settings-list {
  &[name="Comments"] {
    .wrapper-md {
      overflow: visible;
      @media (max-width: 1500px) {overflow: hidden;}
    }
    .form-control-tabs {
      .nav-tabs {
        .nav-item {
          min-width: 112px;
          width: 100%;
        }
      }
    }
    @at-root .comments-input-mention-list {
      min-width: 280px;
      width: 100%;
      position: relative;
      margin-left: -13px;
      border-radius: .5rem;
      background: #fff;
      color: rgba(0, 0, 0, .8);
      overflow: hidden;
      font-size: .9rem;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 10px 20px rgba(0, 0, 0, .1);
      padding: 5px 0;
      &.empty {
        display: none;
      }
      .comments-input-mention {
        margin: 0;
        width: 100%;
        text-align: left;
        border: 1px solid transparent;
        padding: 12px;
        &:hover, &.is-selected {
          background: $primary-1;
        }
        &-avatar {
          width: 18px;
          height: 18px;
          margin-right: 10px;
          border-radius: 50%;
          overflow: hidden;
          background: #f0f0f0;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .glider-contain {
      .glider {
        transition: transform .3s ease;
        scrollbar-width: none;
        z-index: 0;
        overflow-y: hidden;
        .glider-track {
          margin: 0 0 10px 10px;
          .glider-item {
            height: 84px !important;
            padding-right: 10px;
            position: relative;
            display: flex;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: $border-radius-sm;
            }
            .remove-attachment-btn {
              cursor: pointer;
              color: #fff;
              background-color: rgba(0, 0, 0, .5);
              position: absolute;
              top: 50%;
              left: calc(50% - 5px);
              transform: translate(-50%, -50%);
              border-radius: 50%;
              padding: 10px;
              font-size: 12px;
              width: 32px;
              height: 32px;
              display: flex;
              &:hover {
                svg {transform: scale(1.3);}
              }
            }
            .gif-label {
              position: absolute;
              top: 5px;
              right: 14px;
              background-color: #fff;
              color: #000;
              padding: 2px;
              border-radius: $border-radius-sm;
              font-size: 8px;
              line-height: 12px;
            }
          }
        }
      }
    }
    .glider-btn {
      position: absolute;
      bottom: 78px;
      cursor: pointer;
      width: 32px;
      height: 32px;
      border-radius: $border-radius-md;
      background: #fff;
      z-index: 1;
      color: $primary-3;
      svg {
        position: relative;
        left: calc(50% - 23px);
        top: calc(50% - 15px);
        &:hover {
          transform: scale(1.3);
        }
      }
      &-prev {
        left: 13px;
        padding-left: 30px;
      }
      &-next {
        right: 13px;
        padding-left: 30px;
      }
      &.disabled {display: none;}
    }
    .hub-settings-inner {
      .comments-item {
        position: relative;
        padding: 10px 24px 16px 24px;
        &-mention {
          font-weight: 700;
          color: $primary-3;
        }
        &-line {
          background-color: $neutral-2;
          height: calc(100% - 50px);
          position: absolute !important;
          left: -28px;
          width: 1px;
          top: 40px;
          transform: translateX(-50%);
          z-index: 3;
          &-reply {
            top: 0;
            height: 100%;
          }
        }
        &:not(.comments-item-nested):not(.comments-item-replying):not(.reply) {
          .comments-item-line {
            display: none;
          }
        }
        & > div {
          position: relative;
          z-index: 2;
        }
        .mark-as-read {
          width: 48px;
          flex: 0 0 48px;
        }
        &-background {
          background: $neutral-1;
          border-radius: $border-radius-xl;
          position: absolute !important;
          left: 0;
          right: -20px;
          top: 0;
          opacity: 0;
          z-index: -1;
          transition: opacity .3s;
          bottom: 0;
          &-parent {
            top: -10px;
            left: -72px;
            right: -72px;
            bottom: -16px;
          }
        }
        &-hovered {
          & > .comments-item-background, .comments-item-background-parent {
            opacity: 1;
          }
        }
        &-body {
          position: relative;
          &-hovered {
            .comments-item-cta {
              .comments-item-cta-el {
                color: $neutral-4;
                font-weight: 700;
                &:hover {
                  @include transition(.3s);
                  color: $primary-3!important;
                }
              }
              .comments-buttons-hidden {
                
                .comments-delete {
                  &:hover {
                    @include transition(.3s);
                    color: $red-3!important;
                  }
                }
              }
            }
            .comments-item-response {
              background: $neutral-2!important;
            }
          }
          & > p {
            display: grid;
            grid-template-columns: auto auto auto auto auto;
            a {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
            .txt-dot {
              margin-top: 10px;
            }
          }
        }
        &-attachments-images {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 10px;
          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
          }
          div {
            position: relative;
            background-color: $neutral-0;
          }
          svg {
            position: absolute;
            top: 5px;
            right: 5px;
          }
        }
        &-inner {
          .comments-buttons-hidden {
            display: inline-flex;
            color: $neutral-4;
            font-weight: 700;
          }
        }
        &-response {
          max-width: 656px;
          width: 100%;
          padding: 12px;
          transition: all .3s;
        }
        &.reply {
          position: relative;
          &.comments-item-replying {
            .comments-item-body {
              &::after {
                content: "";
                background-color: $neutral-2;
                height: calc(100% - 40px);
                position: absolute !important;
                left: -28px;
                width: 1px;
                top: 40px;
                transform: translateX(-50%);
                z-index: 3;
              }
            }
          }
          &:last-of-type {
            .comments-item-line {
              display: none;
            }
          }
          .comments-item-background {
            left: -80px;
            right: -80px;
          }
          max-width: calc(100% + 58px);
          .mark-as-read {
            margin-right: -72px;
          }
          .comments-item-response { max-width: calc(656px - 76px);}
          .comments-item-avatar {
            position: relative;
            .comments-avatar-bound {
              position: absolute;
              top: -37px;
              right: 39.5px;
              display: initial;
            }
          }
          .comments-input-inside {
            max-width: 585px;
            &.is-invalid {
              border-color: $red-3;
              outline: 0;
              &:focus { box-shadow: $shadow-hover-red;}
              @include hover() { box-shadow: $shadow-hover-red;}
            }
          }
          .reply {
            .comments-input-inside {max-width: 510px;}
          }
        }
        &-avatar {
          width: 40px;
          height: 40px;
          flex: 0 0 40px;
          border-radius: 50%;
          img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 50%;
          }
          .comments-avatar-bound {display: none;}
        }
      }
    }
    .comments-item {
      .comments-item-inner {
        .comments-input-inside {
          max-width: 655px;
          width: 100%;
          padding: 0;
          .comments-input {
            &-form-items {
              width: 100%;
              margin-bottom: 0;
              .ProseMirror {
                min-height: initial;
                padding: 10px;
              }
              &.with-txt {
                margin-right: 55px;
                .ProseMirror {
                  font-size: 17px;
                  font-weight: 450;
                  line-height: 25.5px;
                }
              }
              .comments-attachment-list {
                .comments-attachment-doc {
                  .comments-attachment {
                    color: $primary-3;
                    padding: 7px;
                    background-color: $primary-1;
                    border-radius: $border-radius-md;
                    span {
                      font-size: 11px;
                      line-height: 17.6px
                    }
                    .remove-attachment-btn {
                      &:hover {
                        cursor: pointer;
                        svg {transform: scale(1.2);}
                      }
                    }
                  }
                }
              }
            }
          }
          .comments-attachment-buttons-list {
            @include flexbox(row,center,space-between);
            background-color: rgba(228, 228, 228, 0.5);
            padding: 10px;
            .attachment-buttons {
              a {
                &:hover {
                  color: $neutral-6!important;
                  svg {transform: scale(1.2);}
                }
                &.error {
                  color: $neutral-4!important;
                  cursor: initial;
                  pointer-events: none;
                  &:hover {
                    svg {transform: none;}
                  }
                }
              }
            }
          }
          .side-panel-description-actions {
            padding: 5px 0 0 0;
          }
        }
      }
    }
  }
}