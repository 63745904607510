.form-control-rte {
  height: initial;
  position: relative;
  resize: vertical;
  //overflow: auto;

  .ProseMirror {
    min-height: 55px;
    
    @at-root .form-control-rte.form-control-rte-lg .ProseMirror { min-height: 110px;}
    @at-root .form-control-rte.one-line.with-top-personalisation .ProseMirror { min-height: 30px; @include flexbox(row,center,initial);}

    a { color: rgba(#568C0B,.85);}
    ::selection { background: $primary-4-transparent;}
  }

  [data-personalisation] {
    color: $red-3;
  }
  [contenteditable] {
    &:focus {
      outline: none;
    }
    ::selection { background: $primary-4-transparent;}
  }

  &.with-top-personalisation {
    .ProseMirror {
      padding-right: 60px;
    }
  }
  &.with-reset {
    .ProseMirror {
      padding-right: 30px;
    }
  }
  &.one-line {
    .rte-level {
      margin: 12px 0 0 !important;
      padding: 0;
      height: 0;
      width: 0;
    }
    .rte-personalisation {
      position: absolute;
      right: 6px;
      top: 10px;
      border: none;

      .multiselect__content-wrapper { right: 0;}
    }
  }

  .multiselect {
    margin-right: $margin-sm;

    &__content-wrapper {
      border-radius: $border-radius-md $border-radius-md $border-radius-lg $border-radius-lg;
      border: none !important;
      padding: $padding 0;
      min-width: 250px;
      display: flex;
    }

    &__placeholder {
      @include transition(.15s);
      border-radius: $border-radius-sm;
      padding: 0 $padding-sm 0 $padding-sm;
      align-items: center;
      position: relative;
      color: $neutral-6;
      white-space: nowrap;
      font-weight: 400;
      cursor: pointer;
      display: flex;
      height: 24px;

      .icon-sm { color: $neutral-4;}

      @include hover() {
        background: rgba($neutral-6,.05);
        color: $primary-3;

        .icon-sm { color: currentColor;}
      }

      @at-root .form-control-rte .multiselect--active .multiselect__placeholder {
        background: rgba($neutral-6,.05);
        color: $primary-3;

        .icon-sm { color: currentColor;}
      }
    }

    &--disabled {
      opacity: .5;

      > .multiselect__tags { pointer-events: none;}
    }
  }

  .rte {

    &-btn {
      border-radius: $border-radius-sm;
      @include transition(.15s);
      margin-right: $margin-sm;
      justify-content: center;
      align-items: center;
      color: $neutral-4;
      display: flex;
      height: 24px;
      width: 26px;
      border: none;

      @include hover() {
        background: rgba($neutral-6,.05);
      }

      &.is-active {
        background: $primary-3;
        color: $neutral-0;

        @include hover() {
          background: $primary-4;
        }
      }

      &.disabled {
        pointer-events: none;
        opacity: .5;
      }
    }

    &-menubar {
      margin-bottom: -10px;
      margin-right: -13px;
      margin-left: -13px;
    }

    &-reset {
      position: absolute;
      right: 8px;
      top: 8px;
      width: 32px;
      height: 32px;
      min-height: 0;
      padding: 0;
      cursor: pointer;
      color: $primary-4;
      z-index: 2;
    }

    &-confirm {
      position: absolute;
      right: 8px;
      top: 8px;
      width: 32px;
      height: 32px;
      min-height: 0;
      padding: 0;
      cursor: pointer;
      color: $primary-4;
      z-index: 2;
    }

    &-level {
      align-items: center;
      padding: $padding;
      display: flex;

      &:first-of-type { margin-top: $margin-default;}

      &-bottom {
        border-radius: 0 0 10px 10px;
        background: $neutral-1;
      }
    }

    &-personalisation {

      &.no-options {
        min-width: 40px;
        border: none;
        margin-left: 0;
        .multiselect__placeholder {
          justify-content: initial;
        }
      }

      .multiselect {
        &__content-wrapper {
          max-height: 400px !important;
          min-width: 200px;
          overflow: scroll;
        }
      }

      &:last-child:not(:first-child),&:nth-last-child(2):not(:first-child) {
        .multiselect__content-wrapper {
          right: 0;
        }
      }

      .multiselect__content {
        max-height: 400px;
        overflow: scroll;
      }
    }

    &-text-size {

      .multiselect {
        &__option {
          min-height: 40px;
          height: auto;

          span[data-size] {
            margin: .25em 0;
          }
        }
      }
    }
  }
}


// AI Additional

.rte-suggestion {
  @include transition(.15s);
  border-radius: 0 0 $border-radius-md $border-radius-md;
  border: 1px solid $neutral-2;
  padding: $padding-default 48px $padding-default 12px;
  background: $neutral-0;
  color: $neutral-4;
  position: relative;
  border-top: none;
  width: 100%;
  order: 2;

  @include hover() {
    background: $neutral-1;
    color: $neutral-6;
    cursor: pointer;
    .icon-regular { opacity: 1; visibility: 1;}
  }

  .icon-regular {
    pointer-events: none;
    color: $primary-3;
    position: absolute;
    visibility: 0;
    opacity: 0;
    right: 0;
    top: 0;
    margin: 14px 10px;
  }

  .txt-body {
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    display: -webkit-inline-box;
    -webkit-line-clamp: 3;
            line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 66px;
    word-break: break-all;
  }

  + .side-panel-description {
    .side-panel-description__content,.ProseMirror {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  + .inner {
    [contenteditable]:not([contenteditable="false"]) {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
