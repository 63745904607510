.watch-iframe {
  width: 100%;
  height: 100vh;

  @include media-breakpoint-down(sm) {
    height: calc(100vh - 64px); //fallback for devices without dvh support
    height: calc(100dvh - 64px);
    position: fixed;
    width: 100vw;
    top: 64px;
    left: 0;
  }
}