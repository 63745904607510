.card-simple {
  @include inline-flexbox(column,initial,flex-end);
  border-radius: $border-radius-lg;
  margin: 0 $margin $margin-default;
  box-shadow: $shadow-small;
  padding: $padding-default $padding-default $padding-sm;
  @include transition(.15s);
  background: $neutral-0;
  min-height: 193px;
  color: $neutral-6;
  min-width: 200px;
  cursor: pointer;
  position: relative;

  .slot-top {
    position: absolute;
    right: 12px;
    opacity: 0;
    top: 12px;
  }

  @include hover() {
    box-shadow: $shadow-big;
    background: $primary-1;
    color: $primary-4;

    .slot-top { opacity: 1;}
    .card-file-status { color: $primary-3;}
  }
  
  .txt-heading-small {
    margin-bottom: $margin-sm;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-inline-box;
    -webkit-line-clamp: 2;
            line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &-wrap {
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    display: grid;
  }
}
