&-preview {
  @include flexbox(column,initial,initial);
  background: $neutral-2;
  position: absolute;
  width: 440px;
  right: 100%;
  bottom: 0;
  top: 0;

  &-header {
    padding: 36px 32px 20px;
  }

  &-footer {
    padding: 32px 32px 32px 25px;
  }

  &-body {
    margin: auto $margin-lg auto 0;
    //flex: 1 0 0;

    /////////////////////////////////////////////==================================================================
    &.hub-container {
      border-radius: 0 $border-radius-md $border-radius-md 0;
      background: #fff; // var
      box-shadow: $shadow-small;
    }

    .payment-plans {
      @include scrollbar-thin;
    }
    /////////////////////////////////////////////==================================================================
  }
}