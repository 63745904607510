.custom-radio {
  position: relative;
  min-width: 24px;
  height: 24px;

  .custom-control-input {
    display: inline-block;
    overflow: hidden;
    padding: 0;
    margin: 0;
    opacity: 0;
    height: 0;
    width: 0;

    &:checked,&.checked {
      + .custom-control-label {
        &:before {
          background: $primary-3;
          border-color: $primary-3;
          color: $neutral-0;
        }
        &:after {
          opacity: 1;
        }

        @include hover() {
          &:before {
            background: $primary-4;
            border-color: $primary-4;
          }
        }
      }
    }

    &:disabled {
      pointer-events: none;

      + .custom-control-label {
        pointer-events: none;

        &:before {
          background: $neutral-2;
          border-color: $neutral-2;
          color: $neutral-0;
        }
        &:after {
          opacity: 1;
        }
      }
    }
  }

  .custom-control-label {
    @include inline-flexbox(row,center,center);
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    min-width: 24px;
    min-height: 24px;
    outline: none;

    @include hover() {
      &:before {
        border-color: $primary-4;
        box-shadow: $shadow-hover;
      }
    }

    &:before {
      @include transition(.15s);
      box-shadow: none;
      outline: none;
      box-sizing: border-box;
      border-radius: 50%;
      border: 1px solid $neutral-2;
      background: $neutral-0;
      position: absolute;
      display: block;
      height: 24px;
      width: 24px;
      content: "";
      left: 0;
      top: 0;
    }

    &:after {
      content: "";
      position: absolute;
      background: $neutral-0;
      border-radius: 50%;
      @include transition(.15s);
      display: inline-flex;
      overflow: hidden;
      opacity: 0;
      z-index: 2;
      width: 12px;
      height: 12px;
      top: 6px; 
      left: 6px;
    }
  }
}
