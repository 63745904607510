.fx-row-center { @include flexbox(row,center,initial);}
.fx-row-center-center { @include flexbox(row,center,center);}
.fx-row-center-between { @include flexbox(row,center,space-between);}
.fx-row-center-center { @include flexbox(row,center,center);}
.fx-row-center-end { @include flexbox(row,center,flex-end);}
.fx-row-center-start { @include flexbox(row,center,flex-start);}
.fx-row-end-center { @include flexbox(row,flex-end,center);}
.fx-row-end-start { @include flexbox(row,flex-end,flex-start);}
.fx-row-start { @include flexbox(row,flex-start,initial);}
.fx-row-start-end { @include flexbox(row,flex-start,flex-end);}
.fx-row-start-between { @include flexbox(row,flex-start,space-between);}
.fx-col-start { @include flexbox(column,flex-start,initial);}
.fx-col-start-center { @include flexbox(column,flex-start,center);}
.fx-col-center { @include flexbox(column,center,initial);}
.fx-wrap { flex-wrap: wrap;}
.fx-inline { display: inline-flex !important;}
.fx-justify-start { justify-content: flex-start !important;}
.fx-justify-center { justify-content: center !important;}
.fx-column { flex-direction: column;}
.fx-row { flex-direction: row;}

.self-stretch { align-self: stretch;}

.col { flex: 1 0 0;}
.col-2 { flex: 2 0 0;}

.grid-two-cols { display: grid; grid-template-columns: 1fr 1fr; gap: $padding-default $padding-default;}
.grid-two-rows { display: grid; grid-template-rows: 1fr 1fr; gap: $padding-default $padding-default;}

//.d-none {}
.d-none-pseudo { &:before { content: none !important; } &::after { content: none !important; }}
.d-none-before { &:before { content: none !important; }}
.d-none-after { &:after { content: none !important; }}

.d-block { display: block !important;}
