$popup-z-index: 2001 !default;
$calendar-sidebar-margin-left: 100px !default;
.searchie-icon-left:before,
.searchie-icon-right:before,
.searchie-icon-double-left:before,
.searchie-icon-double-right:before,
.searchie-icon-double-left:after,
.searchie-icon-double-right:after {
  content: '';
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border-style: solid;
  border-color: currentColor;
  border-width: 2px 0 0 2px;
  border-radius: 2px;
  box-sizing: border-box;
  transform-origin: center;
  transform: rotate(-45deg);
}

.searchie-icon-double-left:after {
  left: -4px;
}

.searchie-icon-double-right:before {
  left: 4px;
}

.searchie-icon-right:before,
.searchie-icon-double-right:before,
.searchie-icon-double-right:after {
  transform: rotate(135deg);
}

.searchie-btn {
  @include transition(.15s);
  box-sizing: border-box;
  line-height: 1;
  font-size: $txt-size-1;
  font-weight: 700;
  padding: 7px 15px;
  margin: 0;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: $border-radius-sm;
  color: $neutral-6;
  white-space: nowrap;

  @include hover() {
    color: $primary-3;
  }
}

.searchie-btn-text {
  border: 0;
  padding: 0 4px;
  text-align: left;
  line-height: inherit;
}
.searchie-scrollbar {
  height: 100%;
  &:hover {
    .searchie-scrollbar-track {
      opacity: 1;
    }
  }
}

.searchie-scrollbar-wrap {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.searchie-scrollbar-track {
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  width: 6px;
  z-index: 1;
  border-radius: $border-radius-sm;
  opacity: 0;
  transition: opacity 0.24s ease-out;
  .searchie-scrollbar-thumb {
    position: absolute;
    width: 100%;
    height: 0;
    cursor: pointer;
    border-radius: inherit;
    background-color: rgba(144, 147, 153, 0.3);
    transition: background-color 0.3s;
  }
}
.searchie-zoom-in-down-enter-active,
.searchie-zoom-in-down-leave-active {
  opacity: 1;
  transform: scaleY(1);
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transform-origin: center top;
}

.searchie-zoom-in-down-enter,
.searchie-zoom-in-down-leave-to {
  opacity: 0;
  transform: scaleY(0);
}

.searchie-datepicker {
  position: relative;
  display: inline-block;
  width: 210px;
  svg {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
  }
}

.searchie-datepicker-range {
  width: 320px;
}

.searchie-datepicker-inline {
  width: auto;
}

.searchie-input-wrapper {
  position: relative;
  .searchie-icon-clear {
    display: none;
  }
  &:hover {
    .searchie-icon-clear {
      display: block;
    }
    .searchie-icon-clear + .searchie-icon-calendar {
      display: none;
    }
  }
}

.searchie-input {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  height: 34px;
  padding: 6px 30px;
  padding-left: 10px;
  font-size: 14px;
  line-height: 1.4;
  color: $neutral-4;
  background-color: #fff;
  border: 1px solid $neutral-2;
  border-radius: $border-radius-lg;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

  &:hover,
  &:focus {
    border-color: $primary-4;
  }
  &:disabled,
  &.disabled {
    color: $neutral-2;
    background-color: $neutral-1;
    border-color: $neutral-2;
    cursor: not-allowed;
  }
  &:focus {
    outline: none;
  }
  &::-ms-clear {
    display: none;
  }
}

.searchie-icon-calendar,
.searchie-icon-clear {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  font-size: 16px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.5);
  vertical-align: middle;
}

.searchie-icon-clear {
  cursor: pointer;
  &:hover {
    color: rgba(0, 0, 0, 0.8);
  }
}

.searchie-datepicker-main {
  //font: 14px/1.5 'Helvetica Neue', Helvetica, Arial, 'Microsoft Yahei', sans-serif;
  color: $neutral-4;
  background-color: $neutral-0;
  border-radius: $border-radius-md;
  border: none;
}

.searchie-datepicker-popup {
  position: absolute;
  margin-top: 1px;
  margin-bottom: 1px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  z-index: $popup-z-index;
}

.searchie-datepicker-sidebar {
  float: left;
  box-sizing: border-box;
  width: $calendar-sidebar-margin-left;
  padding: 6px;
  overflow: auto;
}

.searchie-datepicker-sidebar + .searchie-datepicker-content {
  margin-left: $calendar-sidebar-margin-left;
  border-left: 1px solid $neutral-3;
}

.searchie-datepicker-body {
  position: relative;
  user-select: none;
}

.searchie-btn-shortcut {
  display: block;
  padding: 0 6px;
  line-height: 24px;
}

.searchie-range-wrapper {
  display: flex;
  @media (max-width: 750px) {
    flex-direction: column;
  }
}

.searchie-datepicker-header {
  padding: 6px 8px;
  border-bottom: 1px solid $neutral-3;
}

.searchie-datepicker-footer {
  padding: 6px 8px;
  text-align: right;
  border-top: 1px solid $neutral-3;
}

.searchie-calendar {
  box-sizing: border-box;
  width: 248px;
  //padding: 6px 12px;
  //& + & {
  //  border-left: 1px solid $neutral-3;
  //}
}

.searchie-calendar-header {
  @include flexbox(row,center,initial);
  box-sizing: border-box;
  padding: $padding-default;
  //height: 32px;
  overflow: hidden;

  > .searchie-btn-text {
    @include inline-flexbox(row,center,center);
    color: $primary-3;
    height: 32px;

    @include hover() {
      color: $primary-4;
    }

    &.searchie-btn-icon-double-right { order: 4;}
  }
}

.searchie-calendar-header-label {
  pointer-events: none;
  margin-right: auto;
  order: -1;
}

.searchie-calendar-decade-separator {
  margin: 0 2px;
  &:after {
    content: '~';
  }
}

.searchie-calendar-content {
  padding: 0 $padding-default;
  position: relative;
  height: 230px;
  box-sizing: border-box;
  .cell {
    @include transition(.2s);
    cursor: pointer;
    &:hover {
      background-color: $primary-1;
    }
    &.active {
      color: $neutral-0;
      background-color: $primary-3;
    }
    &.in-range,
    &.hover-in-range {
      color: $primary-3;
      background-color: $primary-1;
    }
    &.disabled {
      cursor: not-allowed;
      color: $neutral-2;
      background-color: $neutral-1;
    }
  }
}

.searchie-calendar-week-mode {
  .searchie-date-row {
    cursor: pointer;
    &:hover {
      background-color: $primary-1;
    }
    &.searchie-active-week {
      background-color: $primary-1;
    }
    .cell {
      &:hover {
        color: inherit;
        background-color: transparent;
      }
      &.active {
        color: inherit;
        background-color: transparent;
      }
    }
  }
}

.searchie-week-number {
  opacity: 0.5;
}

.searchie-table {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-align: center;
  vertical-align: middle;
  display: block;
  thead {
    margin-bottom: $margin-default;
    display: block;
    width: 100%;
    tr {
      @include flexbox(row,center,initial);

      th {
        flex: 1 0 0;
        display: inline-block;
        padding: 0;
        font-weight: 700;
        color: $neutral-2;
        font-size: 0;

        &::first-letter { font-size: $txt-size-0;}
      }
    }
  }
  tbody {
    display: block;
    width: 100%;
    tr {
      @include flexbox(row,center,initial);

      td {
        border-radius: $border-radius-md;
        flex: 1 0 0;
        @include inline-flexbox(row,center,center);
        padding: 0;
        color: $neutral-4;
      }
    }
  }
}

.searchie-table-date {
  td {
    height: 30px;
    font-size: $txt-size-1;
  }

  .today {
    font-weight: 700;
    color: $primary-3;
  }
  .cell.not-current-month {
    color: #ccc;
    background: none; // cover the in-range style
  }
}

.searchie-time {
  flex: 1;
  width: 224px;
  background: #fff;
  & + & {
    border-left: 1px solid $neutral-3;
  }
}
.searchie-calendar-time {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.searchie-time-header {
  @extend .searchie-calendar-header;
  border-bottom: 1px solid $neutral-3;
}

.searchie-time-content {
  height: 224px;
  box-sizing: border-box;
  overflow: hidden;
}

.searchie-time-columns {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.searchie-time-column {
  flex: 1;
  position: relative;
  border-left: 1px solid $neutral-3;
  text-align: center;

  &:first-child {
    border-left: 0;
  }
  .searchie-time-list {
    margin: 0;
    padding: 0;
    list-style: none;
    &::after {
      content: '';
      display: block;
      height: 32 * 6px;
    }
  }
  .searchie-time-item {
    cursor: pointer;
    font-size: 12px;
    height: 32px;
    line-height: 32px;
    &:hover {
      color: $primary-3;
      background-color: $primary-1;
    }
    &.active {
      color: $primary-4;
      background-color: $neutral-0;
      font-weight: 700;
    }
    &.disabled {
      cursor: not-allowed;
      color: $neutral-2;
      background-color: $neutral-1;
    }
  }
}

.searchie-time-option {
  cursor: pointer;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 20px;
  &:hover {
    color: $primary-3;
    background-color: $primary-1;
  }
  &.active {
    color: $primary-4;
    background-color: $neutral-0;
    font-weight: 700;
  }
  &.disabled {
    cursor: not-allowed;
    color: $neutral-2;
    background-color: $neutral-1;
  }
}
