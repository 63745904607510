// Tooltips

.tooltip {
  font-family: $app-v2-font;
  z-index: $zindex-tooltip;
  transition: opacity .15s;
  word-wrap: break-word;
  @include reset-text();
  position: absolute;
  line-height: 1.6;
  font-weight: 400;
  font-size: 14px;
  display: block;
  opacity: 0;
  margin: 0;

  &.show,&.b-tooltip { opacity: 1; }

  .arrow {
    position: absolute;
    display: block;
    width: 16px;
    height: 8px;

    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background: url("data:image/svg+xml,%3Csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.41421 6.58579L16 0L9.5399e-08 -1.90798e-07L6.58579 6.58579C7.36683 7.36683 8.63316 7.36684 9.41421 6.58579Z' fill='%230A3530'/%3E%3C/svg%3E%0A") no-repeat center top;

      @at-root .tooltip.b-tooltip-light .arrow:before {
        background: url("data:image/svg+xml,%3Csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.41421 6.58579L16 0L9.5399e-08 -1.90798e-07L6.58579 6.58579C7.36683 7.36683 8.63316 7.36684 9.41421 6.58579Z' fill='%23FCFCFC'/%3E%3C/svg%3E%0A") no-repeat center top;
      }
      @at-root .tooltip.b-tooltip-info .arrow:before {
        background: url("data:image/svg+xml,%3Csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.41421 6.58579L16 0L9.5399e-08 -1.90798e-07L6.58579 6.58579C7.36683 7.36683 8.63316 7.36684 9.41421 6.58579Z' fill='%2306211E'/%3E%3C/svg%3E%0A") no-repeat center top;
      }
    }
  }

  &.tooltip-no-wrap {
    .tooltip-inner{
      max-width: 400px;
      white-space: nowrap;
    }
  }
  &.tooltip-wrap {
    .tooltip-inner {
      white-space: initial;
    }
    .tooltip-cta-inner {
      text-overflow: initial;
      white-space: initial;
      line-height: 22.4px;
    }
  }
  & strong {
    cursor: pointer;
  }

  &-cta {
    @include flexbox(row, center, initial);
    min-height: 22px;
    max-width: 100%;
    min-width: 0;

    &-inner {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      min-width: 0;
    }

    .btn-icon {
      @include inline-flexbox(row,center,center);
      margin-right: -$margin-sm;
      color: $neutral-0;
      min-height: 22px;
      @include hover() {
        @include transition(.15s);
        background: transparent;
        color: $neutral-2;
      }
      .icon-regular {
        height: 22px;
        width: 22px;
      }
    }
  }
}

.bs-tooltip-top {
  padding: 8px 0;
  margin: 5px 0;

  .arrow {
    bottom: 0;

    &::before {
      top: 0;
    }
  }
}

.bs-tooltip-right {
  padding: 0 8px;
  margin: 0 9px;

  .arrow {
    left: 0;
    width: 8px;
    height: 16px;

    &::before {
      background: url("data:image/svg+xml,%3Csvg width='8' height='16' viewBox='0 0 8 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.41421 6.58579L8 0V16L1.41421 9.41421C0.633165 8.63317 0.633164 7.36684 1.41421 6.58579Z' fill='%230A3530'/%3E%3C/svg%3E%0A") no-repeat center right;
      right: 0;

      @at-root .b-tooltip-light.bs-tooltip-right .arrow::before {
        background: url("data:image/svg+xml,%3Csvg width='8' height='16' viewBox='0 0 8 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.41421 6.58579L8 0V16L1.41421 9.41421C0.633165 8.63317 0.633164 7.36684 1.41421 6.58579Z' fill='%23FCFCFC'/%3E%3C/svg%3E%0A") no-repeat center right;
      }
      @at-root .b-tooltip-info.bs-tooltip-right .arrow::before {
        background: url("data:image/svg+xml,%3Csvg width='8' height='16' viewBox='0 0 8 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.41421 6.58579L8 0V16L1.41421 9.41421C0.633165 8.63317 0.633164 7.36684 1.41421 6.58579Z' fill='%2306211E'/%3E%3C/svg%3E%0A") no-repeat center right;
      }
    }
  }
}

.bs-tooltip-bottom {
  padding: 8px 0;
  margin: 5px 0;

  .arrow {
    top: 0;

    &::before {
      background: url("data:image/svg+xml,%3Csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.58579 1.41421L-3.49691e-07 8L16 8L9.41421 1.41421C8.63317 0.633165 7.36684 0.633164 6.58579 1.41421Z' fill='%230A3530'/%3E%3C/svg%3E%0A") no-repeat center bottom;
      bottom: 0;

      @at-root .b-tooltip-light.bs-tooltip-bottom .arrow::before {
        background: url("data:image/svg+xml,%3Csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.58579 1.41421L-3.49691e-07 8L16 8L9.41421 1.41421C8.63317 0.633165 7.36684 0.633164 6.58579 1.41421Z' fill='%23FCFCFC'/%3E%3C/svg%3E%0A") no-repeat center bottom;
      }
      @at-root .b-tooltip-info.bs-tooltip-bottom .arrow::before {
        background: url("data:image/svg+xml,%3Csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.58579 1.41421L-3.49691e-07 8L16 8L9.41421 1.41421C8.63317 0.633165 7.36684 0.633164 6.58579 1.41421Z' fill='%2306211E'/%3E%3C/svg%3E%0A") no-repeat center bottom;
      }
    }
  }
}

.bs-tooltip-left {
  padding: 0 8px;
  margin: 0 9px;

  .arrow {
    right: 0;
    width: 8px;
    height: 16px;

    &::before {
      background: url("data:image/svg+xml,%3Csvg width='8' height='16' viewBox='0 0 8 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.58579 6.58579L0 0V16L6.58579 9.41421C7.36683 8.63317 7.36684 7.36684 6.58579 6.58579Z' fill='%230A3530'/%3E%3C/svg%3E%0A") no-repeat center left;
      left: 0;

      @at-root .b-tooltip-light.bs-tooltip-left .arrow::before {
        background: url("data:image/svg+xml,%3Csvg width='8' height='16' viewBox='0 0 8 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.58579 6.58579L0 0V16L6.58579 9.41421C7.36683 8.63317 7.36684 7.36684 6.58579 6.58579Z' fill='%23FCFCFC'/%3E%3C/svg%3E%0A") no-repeat center left;
      }
      @at-root .b-tooltip-info.bs-tooltip-left .arrow::before {
        background: url("data:image/svg+xml,%3Csvg width='8' height='16' viewBox='0 0 8 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.58579 6.58579L0 0V16L6.58579 9.41421C7.36683 8.63317 7.36684 7.36684 6.58579 6.58579Z' fill='%2306211E'/%3E%3C/svg%3E%0A") no-repeat center left;
      }
    }
  }
}

.bs-tooltip-auto {
  &[x-placement^="top"] {
    @extend .bs-tooltip-top;
  }
  &[x-placement^="right"] {
    @extend .bs-tooltip-right;
  }
  &[x-placement^="bottom"] {
    @extend .bs-tooltip-bottom;
  }
  &[x-placement^="left"] {
    @extend .bs-tooltip-left;
  }
}

.tooltip-inner {
  background-color: #0A3530;
  border-radius: $border-radius-md;
  --corner-smoothing: 60%;
  box-shadow: $shadow-tooltip;
  font-family: $app-v2-font;
  text-align: center;
  padding: 8px 12px;
  color: $neutral-0;
  // max-width: 210px;
  max-width: 250px;

  &:empty {
    display: none;
  }

  .b-tooltip-lg & { padding: $padding-default;}
  .b-tooltip-light & { background: #FCFCFC !important; color: #0A3530;}
  .b-tooltip-info & { background: $primary-3 !important;}
 }
