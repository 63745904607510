.apps {
  &-layout {
    @include flexbox(row,stretch,flex-start);
    max-width: calc(100vw - 64px);
    padding: 0 0 0 350px;
    min-height: 100vh;
  }
  &-main-content {
    background: $neutral-0-5;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%), 0 8px 16px 0 rgb(0 0 0 / 3%);
    max-width: 100%;
    flex: 1 0 0;
  }
  &-slide-in {
    .card-file-stat { pointer-events: none;}
    
    .side-panel-body {
      .widget-skeleton {
        margin-left: -8px;
        .skeleton-item:nth-of-type(3n+2) {
          svg {
            g > rect:nth-of-type(2) {
              width: 267px;
            }
          }
        }
        .skeleton-item:nth-of-type(3n) {
          svg {
            g > rect:nth-of-type(2) {
              width: 307px;
            }
          }
        }
      }
    }
  }
  &-banner {
    background: $blue-gradient;
    box-shadow: $shadow-small;
    border-radius: 10px;
    position: relative;
    max-width: 1000px;
    overflow: hidden;

    &-inner {
      margin: 0 0 64px 64px;
      position: relative;
      max-width: 362px;
    }

    &-right {
      position: absolute;
      max-width: 50%;
      bottom: 0;
      right: 0;
    }
  }
}

.app-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  grid-auto-rows: 1fr;
}

.app-filter-cont {
  @include flexbox(row,center,space-between);
  width: 100%;

  .filter { width: 50%;}
  .side-panel-group-header {
    width: calc(50% - 8px);
    min-height: 0;
  }
}

.ai-illustration {
  #apps-dialog & {
    transform: translateX(42px);
    + .side-panel-library-cta {
      border-radius: $border-radius-lg $border-radius-lg 0 0;
      box-shadow: 0 0 70px 0 rgba(#000,.1);

      .btn-link-text { float: right;}
    }
  }
}

.vimeo-user-row {
  @include flexbox(row,center,initial);
  min-height: 88px;

  &-details { @include flexbox(column,initial,center);}
}