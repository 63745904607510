.card-notification {
  border-bottom: 1px solid #D7E0D8;
  padding: $padding-default;
  @include transition(.15s);
  background: $neutral-0;
  margin: 0;

  @include hover() {
    background: $primary-1;
    color: $primary-4;

    .txt-muted-2 { color: $primary-3;}
    .card-notification-figure i { background: rgba(#06211E,.1); color: $primary-4;}
    .btn-primary { opacity: 1; pointer-events: all;}
  }

  &.loading {
    pointer-events: none;
    box-shadow: none;
    min-height: 133px;
    opacity: .6;
  }

  .slot-top {
    @include flexbox(row,flex-start,initial);

    .txt-heading-small { word-break: break-word;}
  }

  .slot-bottom {
    @include flexbox(row,flex-start,space-between);
    padding-top: $padding-default;
    padding-left: 26px;
  }

  .btn-primary {
    pointer-events: none;
    opacity: 0;
  }

  &-icon {
    margin-top: 3px;
    min-width: 18px;
  }

  &-details {
    padding-right: $padding-default;
    flex: 1 0 0;
    overflow: hidden; 
    text-overflow: ellipsis;
    p { word-wrap: break-word;}
  }

  &-figure {
    position: relative;

    img {
      border-radius: $border-radius-md;
      object-position: center;
      background: $neutral-1;
      object-fit: cover;
      display: block;
      font-size: 0;
      height: 41px;
      width: 74px;
    }
    i {
      @include flexbox(row,center,center);
      border-radius: $border-radius-lg;
      @include transition(.15s);
      background: $neutral-1;
      color: $neutral-4;
      min-width: 48px;
      height: 48px;
      width: 48px;
    }

    .card-notification.unread & {
      &:before {
        content: "";
        @include transition(.15s);
        border: 3px solid $neutral-0;
        box-sizing: border-box;
        background: $primary-4;
        border-radius: 50%;
        position: absolute;
        display: block;
        height: 12px;
        width: 12px;
        right: -3px;
        top: -3px;
      }

      @at-root .card-notification.unread:hover .card-notification-figure::before { border-color: $primary-1;}
    }
  }
}
