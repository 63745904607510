.person {

  &-dialog {
    &.modal-right.modal.fade:before {
      right: $side-modal-width-sm;
    }
    .modal-dialog {
      max-width: $side-modal-width-sm;
      width: $side-modal-width-sm;
    }
  }

  // Person avatar =====================/

  &-avatar {
    border: 1px solid rgba(#7B877E, .1);
    object-position: center;
    background: $neutral-1;
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
    margin-top: -28px;
  }

  // Person stats =====================/

  &-stats {
    padding-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px 8px;

    &-item {
      background: $neutral-1;
      border-radius: 12px;
      padding: 12px;
    }
  }

  // Person info component =====================/

  &-info {
    @include inline-flexbox(column,center,center);
    position: relative;
    max-width: 400px;

    &.disabled {
      pointer-events: none;
      opacity: .5;

      .person-info-input {
        pointer-events: none;
      }
    }

    &-input {
      border: 1px solid transparent;
      text-align: center;
      border-radius: $border-radius-md;
      overflow: hidden;
      box-shadow: none;
      min-width: 120px;
      padding: 2px 4px;
      box-shadow: none;
      transition: .2s;
      max-width: 100%;
      cursor: pointer;
      outline: none;
      height: auto;
      margin: 0;

      @include hover() {
        border-color: transparent;
        background: $neutral-1;
        box-shadow: none;
      }

      &.in-focus {
        border-color: $neutral-2;
        background: transparent;
        
        &.txt-body { padding-right: 56px;}
        &.txt-title-small { padding-right: 80px;}
      }

      &.invalid { border-color: $red-3 !important; color: $red-3 !important;}

      &.txt-body {
        + .person-info-cta {
          height: 28px;

          > .btn {
            min-height: 20px;
            min-width: 20px;
            height: 20px;
            width: 20px;
            padding: 0;
          }
        }
      }

      &.txt-title-small {
        + .person-info-cta {
          height: 41px;
        }
      }
    }

    &-cta {
      @include flexbox(row,center,center);
      position: absolute;
      column-gap: 4px;
      padding: 0 4px;
      z-index: 2;
      right: 0;
      top: 0;
    }
  }

  &-attribute {
    padding: $padding-lg 0;
    transition: .2s;

    &-disabled {
      pointer-events: none;
      opacity: .8;
    }
  }
}
