@font-face {
  font-family: $app-v2-font;
  src: url($assetUrl + '/fonts/CircularXXWeb-Book.woff') format("woff");
  src: url($assetUrl + '/fonts/CircularXXWeb-Book.woff2') format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: $app-v2-font;
  src: url($assetUrl + '/fonts/CircularXXWeb-Bold.woff') format("woff");
  src: url($assetUrl + '/fonts/CircularXXWeb-Bold.woff2') format("woff2");
  font-weight: 700;
  font-style: normal;
}

//Rem base (html font size)

html {
  font-size: 10px;
}

body, pre {
  font-family: $app-v2-font;
  font-weight: 400;
  font-size: 1.4rem;
  color: $neutral-6;
}

.txt {

  // Font family ===============/

  &-font-main { font-family: $app-v2-font !important;}

  // Text sizes ================/

  // font size 54px
  &-title-x-large {
    margin-bottom: $margin-default;
    font-size: $txt-size-7;
    line-height: 1.2;
  }

  // font size 46px
  &-title-large {
    margin-bottom: $margin-default;
    font-size: $txt-size-6;
  }

  // font size 34px
  &-title {
    margin-bottom: $margin-default;
    font-size: $txt-size-5;
    line-height: 1.2;
  }

  // font size 27px
  &-title-small {
    margin-bottom: $margin-default;
    font-size: $txt-size-4;
    line-height: 1.3;
  }

  // font size 22px
  &-heading {
    margin-bottom: $margin-default;
    font-size: $txt-size-3;
    line-height: 1.4;
  }

  // font size 17px
  &-heading-small {
    margin-bottom: $margin-default;
    font-size: $txt-size-2 !important;
    line-height: 1.5 !important;
  }

  // font size 14px
  &-body {
    font-size: $txt-size-1;
    line-height: 1.6;
  }

  &-body-h {
    font-size: $txt-size-1;
    line-height: 22px;
    font-weight: 450;
  }

  // font size 11px
  &-body-small {
    font-size: $txt-size-0;
    line-height: 1.6;
  }


  // Text colors ================/
  &-primary { color: $primary-3 !important;}
  &-primary-light { color: $primary-2 !important;}
  &-dark { color: $neutral-6 !important;}
  &-wisdom { background: $wisdom-cta-gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &-muted { color: $neutral-4 !important;}
  &-muted-2 { color: $neutral-3 !important;}
  &-muted-3 { color: $neutral-2;}
  &-green-3 { color: $green-3 !important;}
  &-green-4 { color: $green-4 !important;}
  &-red-3 { color: $red-3 !important;}
  &-red-4 { color: $red-4 !important;}
  &-white { color: $neutral-0 !important;}

  &-canva { color: $canva;}
  &-vimeo { color: $vimeo;}
  &-zoom { color: $zoom;}
  &-facebook { color: $facebook;}
  &-podcast { color: $podcast;}
  &-wistia { color: $wistia;}
  &-dropbox { color: $dropbox;}
  &-gdrive { color: $gdrive;}
  &-odrive { color: $odrive;}
  &-obvio { color: $obvio;}
  &-stripe { color: $stripe;}
  &-youtube { color: $youtube;}
  &-convertkit { color: $convertkit;}
  &-instagram { color: $instagram;}
  &-tiktok { color: $tiktok;}

  // Align
  &-right { text-align: right !important;}
  &-left { text-align: left !important;}
  &-center { text-align: center !important;}

  // Font weight
  &-bold { font-weight: 700;}
  &-semibold { font-weight: 500; }
  &-normal { font-weight: 400 !important; }
  &-light { font-weight: 300 !important;}  
}

// Typography dot

.txt-dot {
  background: currentColor;
  display: inline-block;
  border-radius: $border-radius-sm;
  perspective: 1px;
  overflow: hidden;
  margin: 3px 3px;
  color: #C4C4C4;
  min-width: 4px;
  height: 4px;
  width: 4px;
}

// Typography helpers

.txt-wrap { white-space: normal !important;}
.txt-wrap-text { text-wrap: wrap; white-space: pre-wrap;}

.txt-no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &-all { white-space: nowrap;}
}

.txt-break {
  &-word { word-break: break-word;}
  &-all { word-break: break-all;}
}

.txt-simple { text-decoration: none;}
.txt-underline { text-decoration: underline;}

// Truncation
@mixin text-truncate($lines) {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

// Lines from 1 to 5
@for $i from 1 through 5 {
  .txt-truncate-#{$i} { @include text-truncate($i);}
}