&-background-position {

  &.multiselect--above {
    .multiselect__content {
      border-radius: $border-radius-lg $border-radius-lg 0 0;
    }
  }
  .multiselect__content-wrapper {
    overflow: visible !important;
    background: none;
    border: none;
    box-shadow: none;
  }
  .multiselect__content {
    border-top: 1px solid $neutral-1;
    box-shadow: $shadow-big;
    border-radius: 0 0 $border-radius-lg $border-radius-lg;
    background: $neutral-0;
    height: 270px;
    position: relative;
    .multiselect__element {
      .multiselect__option {
        position: initial;
        padding: 0;
        margin: 0;
        height: 0;
        &:before {
          display: none;
        }
        &--highlight {
          background: transparent;
        }
        &--selected {
          background: transparent;
          color: $neutral-6;
          span {
            border: 1px solid $primary-3;
            background: $primary-1;
          }
          &:hover {
            span {
              @include transition(.15s);
              background: $primary-1;
            }
          }
        }
        svg {
          max-width: initial;
          max-height: initial;
        }
      }
      span {
        span {
          position: absolute;
          width: 52px;
          height: 52px;
          border-radius: $border-radius-md;
          background: $neutral-1;
          @include flexbox(row, center, center);
          svg {
            margin-right: 0;
          }
          &:hover {
            @include transition(.15s);
            background: $neutral-2;
          }
        }
      }
      .right-top {
        top: 16px;
        right: 16px;
      }
      .center-top {
        top: 16px;
        right: 109px;
      }
      .left-top {
        top: 16px;
        left: 16px;
      }
      .left-center {
        top: 109px;
        left: 16px;
      }
      .center {
        top: 109px;
        left: 109px;
      }
      .right-center {
        top: 109px;
        right: 16px;
      }
      .right-bottom {
        bottom: 16px;
        right: 16px;
      }
      .center-bottom {
        bottom: 16px;
        left: 109px;
      }
      .left-bottom {
        bottom: 16px;
        left: 16px;
      }
    }
  }
}
