$app-v2-font: 'Circular';

$txt-size-0: 1.1rem;
$txt-size-1: 1.4rem;
$txt-size-2: 1.7rem;
$txt-size-3: 2.2rem;
$txt-size-4: 2.7rem;
$txt-size-5: 3.4rem;
$txt-size-6: 4.6rem;
$txt-size-7: 5.4rem;
