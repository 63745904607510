/*
.hub-template-scroll {
  margin-bottom: 10px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    h2 {
      margin-bottom: 0;
    }
  }
  &__navigation {
    .btn {
      background-color: $neutral-0;
      box-shadow: $shadow-small;
      color: $primary-4;
      &:hover {
        background-color: $primary-1;
      }
      &:disabled, &.disabled {
        cursor: default;
        color: $neutral-3
      }
    }
  }
  &__list {
    width: calc(100% + 16px);
  }
  &--grid {
    .hub-template-scroll__list {
      width: auto;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 16px;
    }
    .card-hub-template {
      margin-right: 0;
    }
    .card-hub-template__container {
      padding-bottom: 0;
    }
  }
}
*/
.hubs {
  @include flexbox(column,initial,initial);
  background: $neutral-1;
  min-height: 100vh;

  .with-topbar & { min-height: calc(100vh - 60px);}

  &__template-options {
    display: flex;
  }

  h2 {
    color: $neutral-6;
    //margin-top: 35px;
  }

  &__total {
    @include flexbox(row,center,space-between);
  }
  &__items {
    @include flexbox(row,flex-start,flex-start);
    margin-bottom: $margin-default * 2;
    position: relative;
    flex-wrap: wrap;
    row-gap: $margin-lg;

    &:has(.card-hub-list) { row-gap: $padding;}
  }
  &__none {
    @include flexbox(column,center,center);
    text-align: center;
    flex: 1 0 0;
  }


  // Templates

  &-templates {

    &-scroll {
      background: $neutral-6;
      position: relative;
      border-radius: 0;
      overflow: hidden;
      padding: $padding-default 0 $padding-lg;

      .txt-title-x-large { color: $neutral-0;}
    }

    &-featured {
      padding: $padding-default 0 $padding-lg;
      column-gap: $padding-default;
      overflow: auto;
      display: flex;

      .card-hub-template:first-child { margin-left: max(50vw - 700px - 30px, 16px);}
      .card-hub-template.blank { margin-bottom: 57px;}
    }

    &-listing {
      background: $neutral-6;
      color: $neutral-0;

      > .top-sticky-blur { top: 0 !important;}
      .skeleton-item.skeleton-item-dark { max-width: calc(50% - 12px);}
      .side-panel-group-header {

        .widget-search {
          max-width: 400px;
          @include hover() { background: rgba(#fff,.2);}
          &.expanded { background: rgba(#fff,.2);}
          input { color: #fff;}
          .btn-icon.txt-white {
            @include hover() { background: transparent;}
          }
        }
      }
    }

    &-sort {
      button {
        color: #fff !important;
        @include hover() {
          background: rgba(#fff,.2) !important;
        }
      }
    }
  }
}
