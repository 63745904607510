.collapse {
  &:not(.show) {
    display: none;
  }
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: .4s cubic-bezier(0.16, 0.87, 0.23, 1);
}

// -- anim rotate
.anim-rotate {
  animation: rotate 1.2s linear infinite;
}
.anim-rotate-reverse {
  animation: rotate 1.2s linear infinite reverse;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// -- vue icon width

.icon-width-enter-active, .icon-width-leave-active {
  transition: .15s;
  max-width: 24px;
}
.icon-width-enter, .icon-width-leave-to {
  overflow: hidden;
  opacity: 0;
  max-width: 0;
}

// -- vue fade

.fade-enter-active, .fade-leave-active {
  transition: opacity .15s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

// -- vue fade list

.fade-list-move, .fade-list-enter-active, .fade-list-leave-active {
  transition: all 0.3s ease;
}
.fade-list-enter, .fade-list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.fade-list-leave-active {
  position: absolute;
  //top: 70vh;
  opacity: 0;
}

// -- vue slide down

.slide-down-enter-active, .slide-down-leave-active {
  transition: .2s;
  opacity: 1;
  max-height: 180px;
}
.slide-down-enter, .slide-down-leave-to {
  opacity: 0;
  max-height: 0;
}

// -- vue slide down lg

.slide-down-lg-enter-active, .slide-down-lg-leave-active {
  transition: .2s;
  opacity: 1;
  max-height: 380px;
}
.slide-down-lg-enter, .slide-down-lg-leave-to {
  opacity: 0;
  max-height: 0;
}

// -- vue slide right

.slide-right-enter-active, .slide-right-leave-active {
  transition: opacity .15s, transform .2s;
}
.slide-right-enter, .slide-right-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}


// -- vue slide up

.slide-up-enter-active, .slide-up-leave-active {
  transition: opacity .15s, transform .2s;
}
.slide-up-enter, .slide-up-leave-to {
  transform: translate3d(0,20px,0);
  opacity: 0;
}

// -- vue only up

.only-up-enter-active, .only-up-leave-active {
  transition: opacity .15s, transform .15s;
}
.only-up-enter {
  transform: translate3d(0,7px,0);
  opacity: 0;
}
.only-up-leave-to {
  transform: translate3d(0,-7px,0);
  opacity: 0;
}

// -- vue only down

.only-down-enter-active, .only-down-leave-active {
  transition: opacity .2s, transform .2s;
}
.only-down-enter {
  transform: translate3d(0,-10px,0);
  opacity: 0;
}
.only-down-leave-to {
  transform: translate3d(0,10px,0);
  opacity: 0;
}


// -- vue fade loader

.fade-load-enter-active {
  animation: fade-load-in .4s ease-in-out;
}
.fade-load-leave-active {
  animation: fade-load-out .4s ease-in-out;
}

// -- vue fade loader transcript

.fade-load-transcript-enter-active {
  animation: fade-load-in-transcript 1.2s;
}
.fade-load-transcript-leave-active {
  animation: fade-load-out-transcript 1.2s;
}

@keyframes fade-load-in {
  0% {
    transform: translateY(15px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-load-out {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-15px);
    opacity: 0;
  }
}


@keyframes fade-load-in-transcript {
  0% {
    transform: translateY(25px);
    opacity: 0;
  }
  60% {
    transform: translateY(25px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-load-out-transcript {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  30% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(3px);
    opacity: 1;
  }
  100% {
    transform: translateY(-15px);
    opacity: 0;
  }
}

// Transcript loader ============================== /

@keyframes transcript-timeline {
  0% { width: 31px;}
  7% { width: 31px;}
  12% { width: 21px;}
  20% { width: 101px;}
  25% { width: 91px;}
  32% { width: 91px;}
  37% { width: 81px;}
  45% { width: 188px;}
  50% { width: 178px;}
  57% { width: 178px;}
  62% { width: 168px;}
  70% { width: 250px;}
  75% { width: 245px;}
  82% { width: 245px;}
  87% { width: 260px;}
  95% { width: 11px;}
  100% { width: 31px;}
}

@keyframes lazy-line {
  0% {
    transform: translateX(-110%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes shine {
  0% {
    left: -(400px + 200px);
  }
  100% {
    left: 4 * 200px;
  }
}

@keyframes shineLg {
  0% {
    left: -(400px + 200px);
  }
  100% {
    left: 6 * 200px;
  }
}
// animation for trim loader

@keyframes move-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100px);
  }
}

@keyframes move-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100px);
  }
}