.form-control-image-tabs {
  background: $neutral-1;
  border-radius: $border-radius-lg;
  position: relative;
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  grid-column-gap: 6px;
  padding: 6px;

  .btn-tab-nav {
    padding: $padding-default $padding-default $padding;
    border-radius: $border-radius-md;
    border: 1px solid transparent;
    flex-direction: column;
    color: $neutral-6;

    > [class*="drop-shadow"] {
      @include flexbox(row,center,center);
      border-radius: $border-radius-md;
      background: $neutral-0;
      height: 48px;
      width: 48px;

      &.drop-shadow-small { box-shadow: $shadow-small;}
      &.drop-shadow-medium { box-shadow: $shadow-mid;}
      &.drop-shadow-large { box-shadow: $shadow-big;}
    }

    @include hover() { color: $primary-3;}
    &.active { color: $primary-3; border-color: $primary-3;}
  }
}
