.card-chart {
  @include inline-flexbox(column,initial,flex-start);
  border-radius: $border-radius-lg;
  box-shadow: $shadow-small;
  background: $neutral-0;

  &-top {
    padding: $padding-default $padding-lg $padding-default $padding-default;
    @include flexbox(row,flex-start,space-between);
  }

  &-graphic {
    margin: auto 0 0;
    //height: 280px;

  }

  &-bottom {
    @include flexbox(column,initial,flex-end);
    padding: $padding-lg;
    margin-top: 0;
    min-height: 130px;

    &-info { flex: 1 0 0; max-width: 320px;}
  }

  //.chart-xaxis-label { transform: translateX(50%);}
}