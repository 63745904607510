// Sidepanel option page / extension of .side-panel

&-option-page {
  @include flexbox(row,center,flex-start);
  border-radius: $border-radius-lg;
  @include transition(.15s);
  box-shadow: $shadow-small;
  background: $neutral-0;
  padding: $padding;
  margin: 0 0 1px;
  cursor: pointer;

  &.disabled {
    background: rgba($neutral-0, 0.5);
    box-shadow: none;
    color: $neutral-3;
    cursor: initial;

    .slot-left {
      color: $neutral-3;
    }
  }

  &:not(.disabled) {
    @include hover() {
      background: $primary-1;
      color: $primary-4;

      .txt-muted { color: $primary-3;}
    }
  }

  &.selected {
    background: $primary-1;
    color: $primary-4;

    .txt-muted { color: $primary-3;}
  }

  .slot-left {
    margin: 0 $margin 0 0;
    padding: 0;
    color: $primary-4;
    @include inline-flexbox(row,center,center);
    min-height: 32px;
    min-width: 32px;
  }

  .slot-right {
    padding: 0 $margin 0 $margin-default;
    margin: 0 0 0 auto;
  }

  .txt-heading-small {
    margin-bottom: 0;
    word-break: break-word;
  }

  // If can be hovered have dropdown on hover

  @media (hover: hover) {

    .popover-toggle {
      opacity: 0;
    }

    &:hover {
      .popover-toggle {
        opacity: 1;
      }
    }
  }

  // Variant muted left icon

  &.variant-secondary {
    .slot-left {
      color: $neutral-4;
    }
    &.disabled {
      .slot-left {
        color: $neutral-3;
      }
    }
  }
}
