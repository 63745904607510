&-button-place-picker {
  ul {
    height: 270px;
    position: relative;
    li {
      .multiselect__option {
        position: initial;
        padding: 0;
        margin: 0;
        height: 0;
        &:before {
          display: none;
        }
        &--highlight {
          background: transparent;
        }
        &--selected {
          background: transparent;
          color: $neutral-6;
          span {
            border: 2px solid $primary-3;
            background: $primary-1;
          }
          &:hover {
            span {
              @include transition(.15s);
              background: $primary-1;
            }
          }
        }
        svg {
          max-width: initial;
          max-height: initial;
        }
      }
      span {
        span {
          position: absolute;
          width: 52px;
          height: 52px;
          border-radius: $border-radius-md;
          background: $neutral-1;
          @include flexbox(row, center, center);
          svg {
            margin-right: 0;
          }
          &:hover {
            @include transition(.15s);
            background: $neutral-2;
          }
        }
      }
      .right-top1 {
        top: 16px;
        right: 76px;
      }
      .right-top2 {
        top: 16px;
        right: 16px;
      }
      .right-top3 {
        top: 76px;
        right: 16px;
      }
      .left-top3 {
        top: 16px;
        left: 76px;
      }
      .left-top2 {
        top: 16px;
        left: 16px;
      }
      .left-top1 {
        top: 76px;
        left: 16px;
      }
      .right-bottom1 {
        bottom: 16px;
        right: 76px;
      }
      .right-bottom2 {
        bottom: 16px;
        right: 16px;
      }
      .right-bottom3 {
        bottom: 76px;
        right: 16px;
      }
      .left-top3 {
        bottom: 16px;
        left: 76px;
      }
      .left-bottom1 {
        bottom: 16px;
        left: 76px;
      }
      .left-bottom2 {
        bottom: 16px;
        left: 16px;
      }
      .left-bottom3 {
        bottom: 76px;
        left: 16px;
      }
    }
    .line {
      position: absolute;
      display: block;
      background: $neutral-6;
      opacity: 0.1;
      &:first-of-type {
        height: 1px;
        width: 52px;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
      }
      &:nth-of-type(2) {
        height: 1px;
        width: 52px;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
      }
      &:nth-of-type(3) {
        height: 52px;
        width: 1px;
        top: 16px;
        right: 50%;
        transform: translateX(-50%);
      }
      &:last-of-type {
        height: 52px;
        width: 1px;
        bottom: 16px;
        right: 50%;
        transform: translateX(-50%);
      }
    }
  }
}