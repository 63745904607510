// Form control focus state
//
// Generate a customized focus state and for any input with the specified color,
// which defaults to the `$input-focus-border-color` variable.
//
// We highly encourage you to not customize the default value, but instead use
// this to tweak colors on an as-needed basis. This aesthetic change is based on
// WebKit's default styles, but applicable to a wider range of browsers. Its
// usability and accessibility should be taken into account with any change.
//
// Example usage: change the default blue border and shadow to white for better
// contrast against a dark gray background.
//
// Textual form controls
//

.form-control {
  border-radius: $border-radius-md;
  @include font-size($txt-size-1);
  border: 1px solid $neutral-2;
  background-color: $neutral-0;
  background-clip: padding-box;
  @include transition(.15s);
  font-family: $app-v2-font;
  --corner-smoothing: 60%;
  padding: 10px 13px;
  color: $neutral-6;
  font-weight: 400;
  box-shadow: none;
  line-height: 1.6;
  display: block;
  height: 48px;
  width: 100%;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Remove select outline from select box in FF
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $neutral-6;
  }

  // Hover
  @include hover() {
    box-shadow: $shadow-hover;
    border-color: $primary-4;
    color: $neutral-6;
    outline: 0;
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  &:focus, &.focus {
    color: $neutral-6;
    border-color: $primary-4;
    outline: 0;
    box-shadow: $shadow-hover;
  }

  // Placeholder
  &::placeholder {
    color: $neutral-4;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly],
  &.disabled {
    background-color: $neutral-1;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    box-shadow: none !important;
    border-color: $neutral-2;
    color: $neutral-3;
    opacity: 1;
  }

  &.form-control[type="password"]:not(:placeholder-shown) {
    font-size: 8px;
    letter-spacing: 6px;
    padding-right: 45px;
  }
  &[placeholder*="••••••••"] {
    &::placeholder {
      transform: translateY(-50%);
      letter-spacing: 6px;
      position: absolute;
      color: $neutral-6;
      font-size: 8px;
      opacity: .5;
      top: 50%;
    }
  }
  // Invalid or error inputs
  &.is-invalid, &.StripeElement--invalid {
    border-color: $red-3;
    outline: 0;
    &:focus { box-shadow: $shadow-hover-red;}
    @include hover() { box-shadow: $shadow-hover-red;}
  }

  &.StripeElement {
    @include flexbox(initial, center, initial);
    & > div {
      width: 100%;
    }
  }

  // Sizes

  &-lg {
    height: 60px;
    padding: 10px 17px;
    font-size: $txt-size-2;
    line-height: 1.5;
  }

  &-sm {
    height: 32px;
    font-size: $txt-size-0;
    padding: 0 $padding !important;

    &.multiselect-small {
      padding: 0 !important;
      .multiselect__input { border: none; outline: none; height: 30px;}
      .multiselect__single { @include hover { color: initial !important;}}
      .caret { top: 3px;}
    }
  }

  // Special widths

  &-width-sm { max-width: 240px; min-width: 240px;}

  // Inner text sizes (RTE)

  [data-size="heading-1"] { font-size: 200%; line-height: 1.3;}
  [data-size="heading-2"] { font-size: 150%; line-height: 1.3;}


  &-label {
    color: $neutral-6;
    display: block;
    margin-bottom: 4px;
  }

  &-description {
    @include transition(.15s);
    display: block;
    color: $neutral-4;
    margin-bottom: 8px;
    margin-top: -4px;
    word-break: break-word;
  }

  &-text {
    &.disabled {
      .form-control-label,
      .form-control-description { color: $neutral-3;}
    }
    .append-input {
      position: absolute;
      right: 8px;
      top: 8px;
      z-index: 10;
    }
    .form-control {
      &:disabled, &[readonly], &.disabled {
        + .input-group-append {
          .form-control-ghost {
            background: none;
            color: #bbb !important;
          }
        }
      }

      &-ghost {
        border-radius: 0 $border-radius-md $border-radius-md 0;
        height: calc(100% - 2px);
        background: $neutral-0;
        pointer-events: none;
        display: inline-flex;
        color: $neutral-3;
        align-items: center;
        padding: 0 $padding;
        margin: 1px;

        &.visible { z-index: 3;}
      }
    }
    .alert { margin-top: $margin;}

    .input-group {
      position: relative;
      &-prepend {
        & ~ input {
          padding-left: 44px;
        }
        @include flexbox(row,center,center);
        position: absolute;
        width: 44px;
        z-index: 4;
        bottom: 0;
        left: 0;
        top: 0;
      }
      &-append {
        & ~ input {
          padding-right: 44px;
        }
        @include flexbox(row,center,center);
        position: absolute;
        min-width: 44px;
        z-index: 4;
        bottom: 0;
        right: 0;
        top: 0;
      }
    }

    .condition-final & {
      .input-group-append {
        @include flexbox(row,center,initial);
        position: absolute;
        text-align: right;
        z-index: 3;
        bottom: 0;
        right: 0;
        top: 0;
      }
    }
  }
  &-file {
    &-invisible {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .custom-file-input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
  }
  &-textarea {
    .form-control {
      height: 136px;
      resize: none;
    }
    .form-group {
      position: relative;
      .form-control-count {
        position: absolute;
        bottom: 5px;
        right: 10px;
        & + .form-control {
          padding-bottom: 40px;
        }
      }
    }
  }

  &-group {
    @include flexbox(row, center, space-between);
    gap: 2rem;
  }

  .chapter-time {
    justify-content: flex-start;
  }
}

@mixin form-control-focus($ignore-warning: false) {
  &:focus {
    border-color: $primary-4;
    outline: 0;
    box-shadow: $shadow-hover;
  }
  @include deprecate("The `form-control-focus()` mixin", "v4.4.0", "v5", $ignore-warning);
}

// This mixin uses an `if()` technique to be compatible with Dart Sass
// See https://github.com/sass/sass/issues/1873#issuecomment-152293725 for more details
@mixin form-validation-state-selector($state) {
  @if ($state == "valid" or $state == "invalid") {
    .was-validated #{if(&, "&", "")}:#{$state},
    #{if(&, "&", "")}.is-#{$state} {
      @content;
    }
  } @else {
    #{if(&, "&", "")}.is-#{$state} {
      @content;
    }
  }
}

@mixin form-validation-state($state, $color, $icon) {
  .#{$state}-feedback {
    display: none;
    width: 100%;
    margin-top: $form-feedback-margin-top;
    @include font-size($form-feedback-font-size);
    color: $color;
  }

  .#{$state}-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%; // Contain to parent when possible
    padding: $form-feedback-tooltip-padding-y $form-feedback-tooltip-padding-x;
    margin-top: .1rem;
    @include font-size($form-feedback-tooltip-font-size);
    line-height: $form-feedback-tooltip-line-height;
    color: color-yiq($color);
    background-color: rgba($color, $form-feedback-tooltip-opacity);
    @include border-radius($form-feedback-tooltip-border-radius);

    // See https://github.com/twbs/bootstrap/pull/31557
    // Align tooltip to form elements
    .form-row > .col > &,
    .form-row > [class*="col-"] > & {
      left: $form-grid-gutter-width / 2;
    }
  }

  @include form-validation-state-selector($state) {
    ~ .#{$state}-feedback,
    ~ .#{$state}-tooltip {
      display: block;
    }
  }

  .form-control {
    @include form-validation-state-selector($state) {
      border-color: $color;

      @if $enable-validation-icons {
        padding-right: $input-height-inner;
        background-image: escape-svg($icon);
        background-repeat: no-repeat;
        background-position: right $input-height-inner-quarter center;
        background-size: $input-height-inner-half $input-height-inner-half;
      }

      &:focus {
        border-color: $primary-4;
        box-shadow: $shadow-hover;
      }
    }
  }

  // stylelint-disable-next-line selector-no-qualifying-type
  textarea.form-control {
    @include form-validation-state-selector($state) {
      @if $enable-validation-icons {
        padding-right: $input-height-inner;
        background-position: top $input-height-inner-quarter right $input-height-inner-quarter;
      }
    }
  }

  .custom-select {
    @include form-validation-state-selector($state) {
      border-color: $color;

      @if $enable-validation-icons {
        padding-right: $custom-select-feedback-icon-padding-right;
        background: $custom-select-background, $custom-select-bg escape-svg($icon) $custom-select-feedback-icon-position / $custom-select-feedback-icon-size no-repeat;
      }

      &:focus {
        border-color: $color;
        box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
      }
    }
  }

  .form-check-input {
    @include form-validation-state-selector($state) {
      ~ .form-check-label {
        color: $color;
      }

      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: block;
      }
    }
  }

  .custom-control-input {
    @include form-validation-state-selector($state) {
      ~ .custom-control-label {
        color: $color;

        &::before {
          border-color: $color;
        }
      }

      &:checked {
        ~ .custom-control-label::before {
          border-color: lighten($color, 10%);
          @include gradient-bg(lighten($color, 10%));
        }
      }

      &:focus {
        ~ .custom-control-label::before {
          box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
        }

        &:not(:checked) ~ .custom-control-label::before {
          border-color: $color;
        }
      }
    }
  }

  // custom file
  .custom-file-input {
    @include form-validation-state-selector($state) {
      ~ .custom-file-label {
        border-color: $color;
      }

      &:focus {
        ~ .custom-file-label {
          border-color: $color;
          box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
        }
      }
    }
  }
}

.input-group:not(.has-validation)>.form-control:not(:last-child) {
  border-top-right-radius: $border-radius-md;
  border-bottom-right-radius: $border-radius-md;
}
.input-group > .form-control:not(:first-child) {
  border-top-left-radius: $border-radius-md;
  border-bottom-left-radius: $border-radius-md;
}

@import "forms/calendar";
@import "forms/radio";
@import "forms/switch";
@import "forms/checkbox";
@import "forms/range";
@import "forms/select";
@import "forms/color-picker";
@import "forms/player-logo";
@import "forms/rich-text";
@import "forms/date";
@import "forms/padding";
@import "forms/radius";
@import "forms/shadow";
@import "forms/button-size";
@import "forms/tags";
@import "forms/icon-picker";
@import "forms/code";
