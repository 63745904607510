.form-control-padding {
  border-radius: $border-radius-sm;
  border: 1px solid $neutral-2;
  position: relative;
  height: 124px;
  margin: 26px;

  &-top {
    margin-left: -25px;
    top: -24px;
    left: 50%;
  }

  &-bottom {
    margin-left: -25px;
    bottom: -24px;
    left: 50%;
  }

  &-left {
    margin-top: -24px;
    left: -25px;
    top: 50%;
  }

  &-right {
    margin-top: -24px;
    right: -25px;
    top: 50%;
  }

  > .form-control {
    border-radius: $border-radius-md;
    background: $neutral-0;
    text-align: center;
    position: absolute;
    padding: $padding;
    height: 48px;
    width: 50px;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance: textfield;
      &:disabled {
        cursor: not-allowed;
        background-color: $neutral-1;
      }
    }
  }

  .btn-lock {
    border: none;
    background: transparent;
    position: absolute;
    color: $neutral-2;
    &:disabled { cursor: not-allowed }

    @include hover() { color: $primary-3;}
    &.active { color: $primary-3;}

    &:before {
      background: currentColor;
      content: "";
      display: block;
    }

    &.X {
      margin-top: -11px;
      padding: 10px 0;
      min-height: 0;
      right: 35px;
      left: 35px;
      top: 50%;

      &:before {
        @include transition(.15s);
        width: 100%;
        height: 2px;
      }
    }

    &.Y {
      margin-left: -11px;
      padding: 0 10px;
      min-height: 0;
      bottom: 30px;
      left: 50%;
      top: 30px;

      &:before {
        height: 100%;
        width: 2px;
      }
    }
  }
}
