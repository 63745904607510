.global-sidenav {
  @include flexbox(column, center, space-between);
  border-right: 1px solid $neutral-2;
  @include transition(.2s);
  background: $neutral-1;
  position: fixed;
  z-index: 1001;
  width: 64px;
  bottom: 0;
  left: 0;
  top: 0;

  @at-root .app:has(.global-sidenav ~ .main .watch-iframe) { padding-left: 0;}
  ~ .main .watch-iframe { padding-left: 64px;}
  @media screen and (max-width: 1055px) { ~ .main .watch-iframe { padding-left: 0;}}

  @include media-breakpoint-down(sm) {
    border-bottom: 1px solid $neutral-2;
    background: $neutral-1;
    height: 64px;
    width: 100%;

    &:has(~ .main .watch-iframe) { .global-sidenav-mid { display: none;}}
  }

  .nav {
    &-item {
      &-icon {
        height: auto;
        width: 32px;
      }

      &-title { display: none;}

      &.selected {
        .nav-link {
          background: $primary-1;
          border: 1px solid $primary-3;
          color: $primary-3;  
        }
      }
    }
    &-link {
      border-radius: $border-radius-lg;
      @include flexbox(row, center, center);
      @include transition(.2s);
      font-size: $txt-size-2;
      color: $neutral-6;
      margin: $margin auto 0;
      min-height: 48px;
      min-width: 48px;

      &.disabled {
        cursor: initial;
      }

      &:not(.disabled):hover {
        box-shadow: $shadow-small;
        background: $neutral-0;
      }

      &.active {
        box-shadow: $shadow-small;
        background: $neutral-0;
        color: $primary-3;
      }
    }
  }

  &-top {
    padding: $padding-default 0;
    a.nav-link {
      margin: -$margin 0 0;
    }

    @include media-breakpoint-down(sm) {
      padding: 16px 4px;
      position: fixed;
      left: 0;
      top: 0;
    }
  }

  &-mid {
    @include media-breakpoint-down(sm) {
      @include flexbox(row,center,center);
      border-top: 1px solid $neutral-2;
      background: $neutral-1;
      column-gap: $padding;
      min-height: 64px;
      position: fixed;
      z-index: 1001;
      bottom: 0;
      right: 0;
      left: 0;

      .nav-link { margin: 0;}
    }
  }

  &-bottom {
    padding: $padding 0;
    column-gap: 8px;

    @include media-breakpoint-down(sm) {
      @include flexbox(row,center,center);
      padding: 0 8px 0 0;
      position: fixed;
      right: 0;
      top: 0;

      .nav {
        @include inline-flexbox(row,center,center);
        column-gap: 8px;
        &-link { margin: 0;}
      }

      > .global-sidenav-avatar:only-of-type { margin-top: 8px;}
      .global-sidenav-avatars {
        display: flex;
        .global-sidenav-avatar {
          margin-bottom: 0!important;
          &.avatar-initials {
            margin-right: 8px;
          }
        }
      }
    }

    #profile-toggle:has(~ .global-sidenav-backdrop) { position: relative; z-index: 1001;}
    .btn-notification {
      @include flexbox(column,center,center);
      position: relative;
      min-height: 48px;
      min-width: 48px;
      margin: $margin auto;

      &.unread {
        @include media-breakpoint-up(md) { transform: translateY(-3px);}

        &:after {
          border: 3px solid $neutral-1;
          box-sizing: border-box;
          background: $primary-4;
          border-radius: 50%;
          position: absolute;
          display: block;
          height: 12px;
          content: "";
          width: 12px;
          right: 9px;
          top: 16px;
        }
      }
    }
  }

  &-backdrop {
    background: linear-gradient(238.09deg, rgba(123, 135, 126, 0.1) 54.76%, rgba(3, 27, 24, 0.5) 99.89%);
    position: fixed;
    z-index: 1000;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
  }

  &-profile {
    border-radius: $border-radius-lg-2;
    position: fixed !important;
    transform: none !important;
    box-shadow: $shadow-mid;
    background: $neutral-1;
    bottom: 8px !important;
    left: 56px !important;
    margin-left: $margin;
    top: auto !important;
    width: 320px;
  }

  &-avatar {
    box-shadow: inset 0 0 0 1px rgba($neutral-4,.1);
    @include flexbox(row,center,center);
    @include transition(.15s);
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    width: 48px;
    height: 48px;
    padding: 4px;
    @include hover() { box-shadow: 0 0 0 4px $primary-4-transparent, inset 0 0 0 1px rgba($neutral-4,.1);}

    .dropdown-menu & {
      @include hover() { box-shadow: none;}
    }

    > img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      display: block;
    }

    .avatar-placeholder {
      @include flexbox(row,center,center);
      background: $neutral-2;
      border-radius: 50%;
      color: $neutral-4;
      overflow: hidden;
      width: 100%;
      height: 100%;
    }

    &.avatar-initials {
      position: relative;
      border-radius: 25%;
    }

    &.sm {
      height: 32px;
      width: 32px;
    }

    &.sm-lg {
      height: 40px;
      width: 40px;
    }

    &.md {
      height: 60px;
      width: 60px;
    }

    &.lg {
      height: 84px;
      width: 84px;
      .avatar-initials { font-size: 2.2rem;}
    }
  }

  &-avatars {
    border-radius: $border-radius-lg;
    box-shadow: $shadow-small;
    background: $neutral-0;
    padding: $padding;
    transition: .2s;
    cursor: pointer;
    @include hover() { box-shadow: $shadow-mid; background: rgba($neutral-0, .9);}
  }

  &-favicon {
    position: relative;

    img,svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .placeholder {
      font-size: 1.4rem;
      font-weight: bold;
      color: $neutral-4;
      background: $neutral-2;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      @include inline-flexbox(row,center,center);
      text-transform: uppercase;
    }
    @at-root .hub-dashboard .hub-favicon-container .fav-image {
      padding: 7px;
      img {
        width: 32px !important; 
        height: 32px;
        border-radius: $border-radius-lg;
      }
    }
    @at-root .hub-editor .global-sidenav .global-sidenav-top .fav-image img {
      width: 32px;
      height: 32px;
    }
    @at-root .hub-editor .global-sidenav .global-sidenav-top .global-sidenav-favicon svg {
      width: 32px;
      height: 32px;
    }
  }
}
