.loading-dots {
  color: rgba(#000,.25);
  margin: 3px 0;

  > circle {
    animation: loadingDot 1s linear infinite;
    &.loading-dot-2 { animation-delay: .3s;}
    &.loading-dot-3 { animation-delay: .6s;}
  }
}

@keyframes loadingDot {
  0% { opacity: 1;}
  33% { opacity: .5;}
  66% { opacity: .25;}
  100% { opacity: 1;}
}

.list-no-more {
  font-size: $txt-size-1;
  color: $neutral-3;
  position: relative;
  text-align: center;
  display: block;

  > hr { position: absolute; left: 0; right: 0; top: 50%; transform: translateY(-50%); margin: 0;}
  > span { position: relative; display: inline-block; margin: $margin-default 0; padding: $padding-sm $padding-lg; background: $neutral-1;}
}

.team-switch-loader {
  .global-sidenav-avatar {
    margin: 0 auto;

    &.lg {
      &:before {
        content:'';
        background-image: linear-gradient(90deg, rgba(#F0F4F3,0) 0px, rgba(#F0F4F3,.6) 60px, rgba(#F0F4F3,.6) 140px, rgba(#F0F4F3,0) 200px);
        position: absolute;
        background-repeat: repeat-x;
        left: -200px;
        width: 200px;
        height: 100%;
        animation: avatarShine 2.15s infinite;
      }
      &:after {
        content: "";
        background: linear-gradient(to bottom, rgba($neutral-0,0), rgba($neutral-0,1));
        position: absolute;
        display: block;
        opacity: .5;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
      }
    }

    &.sm-lg {
      position: relative;
      outline: 3px solid $neutral-1;
      margin: -19px auto $margin-default;
    }
  }
}

@keyframes avatarShine {
  0% {
    left: -200px;
  }
  100% {
    left: 1 * 200px;
  }
}