@import "../emoji-mart";

.form-icon-picker {
  position: relative;
  &.open { z-index: 3;}

  > .form-control {
    @include flexbox(row,center,initial);
    padding: 0 12px;
  }
}

.form-icon-panel {
  border-radius: $border-radius-md $border-radius-md $border-radius-lg $border-radius-lg;
  box-shadow: $shadow-big;
  background: $neutral-0;
  @include flexbox(column,initial,initial);
  position: relative;
  padding-top: 48px;
  //position: absolute;
  width: 456px;
  //top: 0;

  &-sm {
    margin-top: -48px;
    width: 271px;

    [x-placement="top"] & { margin-top: auto;}
  }

  .input-group {
    position: absolute;
    width: 100%;
    z-index: 3;
    left: 0;
    top: 0;
  }

  &.direction-top {
    top: auto;
    bottom: 0;
    
    > .form-control-text { order: 3;}
    > hr { order: 2;}
  }
  
  .nav-tabs {
    margin: $margin-default 12px $margin-sm;
    scroll-behavior: smooth;
    @include scrollbar-none;
    overflow-x: auto;

    @at-root .form-icon-panel-sm .nav-tabs { margin: $margin-default $margin-default 0; padding: 6px 4px;}
    
    .nav-item {
      margin: 0;

      .nav-link {
        min-width: 46px;
        width: 46px;
        
        @at-root .form-icon-panel-sm .nav-tabs .nav-item .nav-link { min-width: 42px; width: 42px; height: 42px; min-height: 42px; margin: 0 2px;}
      }
    }
  }

  //emoji picker overrides
  .emoji-mart {
    font-family: var(--app-font);
    width: 100% !important;
    border-radius: 0;
    background: none;
    font-size: 0;
    border: none;
    height: auto;

    &.only-search { .emoji-mart-scroll { display: none;}}
    &.skeleton-item-light { .emoji-type-image { opacity: 0;}}
    &-category-label { display: none;}
    &-scroll { padding: 0;}

    &-category {
      @extend .form-icon-grid;

      @at-root .form-icon-panel.form-icon-panel-sm .emoji-mart-category {
        grid-template-columns: repeat(5, minmax(0, 1fr));
        padding: 10px $padding-default $padding-default;
      }

      &[aria-label="Frequently Used"],&[aria-label="People & Body"] { display: none;}
    }

    &-emoji {
      @extend .form-icon-grid-item;

      @at-root .form-icon-panel.form-icon-panel-sm .emoji-mart-emoji { padding: 7px;}

      &:before { display: none;}
      > .emoji-type-image { pointer-events: none; opacity: 1; transition: .2s;}
    }

    &.emojis-cat-smileys { .emoji-mart-category:not([aria-label="Smileys & Emotion"]) {display: none;}}
    &.emojis-cat-nature { .emoji-mart-category:not([aria-label="Animals & Nature"]) {display: none;}}
    &.emojis-cat-foods { .emoji-mart-category:not([aria-label="Food & Drink"]) {display: none;}}
    &.emojis-cat-activity { .emoji-mart-category:not([aria-label="Activity"]) {display: none;}}
    &.emojis-cat-places { .emoji-mart-category:not([aria-label="Travel & Places"]) {display: none;}}
    &.emojis-cat-objects { .emoji-mart-category:not([aria-label="Objects"]) {display: none;}}
    &.emojis-cat-symbols { .emoji-mart-category:not([aria-label="Symbols"]) {display: none;}}
    &.emojis-cat-flags { .emoji-mart-category:not([aria-label="Flags"]) {display: none;}}

    .emoji-mart-no-results {
      display: block;
      padding: 24px;
      > div { display: none;}
      &:before { content: "No icons found.";}
    }
  }  
}

.form-icon-dot {
  border-radius: 9px;
  background: $neutral-3;
  transform-origin: center;
  transform: scale(.5);
  display: block;
  transition: .2s;
  margin: 5px;
  height: 9px;
  width: 9px;

  &.active { background: $primary-3; transform: scale(1)}

  &s {
    @include flexbox(row,center,center);
    padding: $padding-sm;
  }
}

.form-icon-scroll {
  max-height: 370px;
  overflow: auto;
  .form-icon-panel-sm & { max-height: 270px;}
}

.form-icon-grid {
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  padding: 10px 12px;
  display: grid;

  .form-icon-panel-sm & {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    padding: 10px $padding-default $padding-default;
  }

  &-item {
    @include flexbox(row,center,center);
    border-radius: $border-radius-md;
    border: 1px solid $neutral-0;
    @include transition(.15s);
    background: $neutral-1;
    color: $neutral-6;
    cursor: pointer;
    padding: 13px;

    @include hover() { background: $neutral-2;}
    &.selected { border-color: $primary-3; background: $primary-1;}

    .form-icon-panel-sm & {
      padding: 7px;
    }
  }
}