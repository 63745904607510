.wisdom {

  &-stats {
    height: calc(100vh - 150px);
    min-height: 800px;

    .slot-left {
      .grid-two-rows { height: 100%; min-height: 800px;}
    }
    
    .slot-right {
      @include flexbox(column,initial,initial);
      row-gap: $padding-default;

      > .wisdom-stats-widget {
        @include flexbox(column,initial,flex-start);
        flex: 1 0 0;

        .wisdom-stats-list {
          flex: 1 0 0;
          overflow: auto;
        }
      }
    }

    &-widget {
      position: relative;

      &-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255,255,255,0.7);
        z-index: 2;
        @include flexbox(column,center,center);
      }
    }
  }

  &-analytics {
    &-tabs { max-width: 415px;}

    &-date-picker {
      &-container {
        position: relative;
        min-width: 252px;

        .form-control-text {
          input {
            pointer-events: none;
          }
        }
      }
      &-filter {
        position: absolute;
        right: 0;
        z-index: 999;
        background-color: #fff;
        border-radius: $border-radius-md;
        box-shadow: 0px 16px 32px 0px #0000001A;
        box-shadow: 0px 2px 6px 0px #0000001A;

        &-col {
          .searchie-range-wrapper {
            flex-direction: column
          }
        }
      }
    }
    
    &-row {
      @include flexbox(row,center,initial);
      border-radius: $border-radius-lg;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 8px 16px 0 rgba(0, 0, 0, 0.03);
      padding: $padding-default;
      margin-bottom: $margin;
      background: $neutral-0;
      cursor: pointer;

      .slot-right {
        @include flexbox(row,center,initial);
        grid-gap: $padding-default;
        padding-left: $padding-lg;
        margin-left: auto;
        min-width: 50%;
        width: 50%;

        > .fx-row-center { flex: 1 0 0; max-width: calc(33.3333% - 16px);}
      }
    }
    // right slide
    &-dialog {
      .audience-info-container {
        width: fit-content;
      }
      .border {
        &-muted {
          border: 1px solid $neutral-3;
          border-radius: $border-radius-lg;
        }
        &-green {
          border: 1px solid $green-2;
          border-radius: $border-radius-lg;
        }
        &-red {
          border: 1px solid $red-2;
          border-radius: $border-radius-lg;
        }
      }


      .answer-container {
        box-shadow: 0px 8px 16px 0px #00000008, 0px 1px 4px 0px #00000014;
        background: #fff;
        padding: 16px;
        border-radius: $border-radius-lg;

      }
      .references {

        &-container {
          //
        }
        &-item {

          .thumbnail {
            width: 45px;
            height: 45px;
            border-radius: $border-radius-sm;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $neutral-1;

            &-play {
              position: absolute;
              z-index: 1;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            img, picture {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }

    }
  }

  &-row {
    box-shadow: 0px 8px 16px 0px #00000008, 0px 1px 4px 0px #00000014;
    background: #fff;
    padding: 16px;
    border-radius: $border-radius-lg;
  
    &-content {
  
      &-right {
        width: 45%;
        margin-left: auto;
      }
  
      &-left {
        width: 275px;
        gap: 4px;
      }
    }
  }
}

#wisdom-analytics {
  left: unset;
  right: 0;
  width: calc(100% - 64px);
}

