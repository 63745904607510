// Tags =====================/

.tag {
  &-list {
    @include flexbox(row,flex-start,flex-start);
    flex-wrap: wrap;
  }
  &-basic {
    @include inline-flexbox(row,center,center);
    margin: 0 $margin $margin 0;
    border-radius: $border-radius-md;
    padding: $padding-sm $padding;
    background: $neutral-1;
    font-size: $txt-size-0;
    color: $neutral-6;
    font-weight: 700;
    height: 32px;

    &.form-control-select-tag {
      @include hover() { background: $neutral-1;}
    }

    span {
      display: block;
      max-width: 220px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .btn-xs { min-height: 24px; padding: 0; min-width: 24px; margin-right: -4px;}
    > .btn-overlay:first-child { margin-left: -4px;}
  }

  &-variant-dark {
    background: rgba($neutral-6,.05);
    svg { color: $neutral-6;}
  }

  &-variant-row {
    @include flexbox(row,center,flex-start);
    margin: 0 0 $margin-sm;
    min-height: 32px;
    flex-wrap: wrap;
    height: auto;
    width: 100%;

    hr { opacity: .5;}

    .slot-right {
      @include inline-flexbox(row,center,center);
      padding-left: $padding;
      position: relative;
      margin-left: auto;
      min-height: 24px;
      gap: $padding-sm;
    }

    .space-row {
      @include flexbox(row,center,flex-start);
      padding: $padding-sm 0;
      color: $neutral-6;  
      min-height: 30px;
      font-weight: 700;
      font-size: 11px;
      .txt-no-wrap { max-width: 310px;}
      > .btn.ml-auto.btn-xs { opacity: 0; visibility: hidden;}
      @include hover() { > .btn.ml-auto.btn-xs { opacity: 1; visibility: visible;}}
    }

    &.is-editing {
      cursor: auto;
      min-height: 42px;
      font-size: $txt-size-1;
      > .btn-left { margin-left: $margin-sm;}
      .space-row { min-height: 40px;}
      .btn-overlay-gray.btn-sm { width: 32px; min-height: 32px;}
      .custom-checkbox .custom-control-label .txt-body { line-height: 1.2;}
    }
  }
}
