.spotlight {

    &-dialog {
      transition: .5s linear;
      backface-visibility: hidden;
  
      .modal-dialog {
        @include flexbox(column,center,center);
        margin: 0 auto;
        padding: 0;
        transition: .5s;
        position: fixed;
        width: 282px;
        height: 50px;
        right: calc(50vw - 207px - 518px);
        top: 16px;
        @media (max-width: 1845px) { right: 198px;}
      }
  
      .modal-content {
        overflow: hidden;
        transition: .5s;
        height: 50px;
      }
  
      + .modal-backdrop { transition: .5s;}
      .modal-body { padding: 0;}
  
      &.show {      
        .modal-dialog { width: 600px; max-width: 600px; height: calc(100vh - 32px); right: calc(50vw - 300px);}
        .modal-content { height: 600px; overflow: hidden;}
        .spotlight-header {
          border-radius: $border-radius-lg $border-radius-lg 0 0;
          background: $neutral-0;
          border: 1px solid transparent;
          border-bottom: 1px solid rgba(#000,.05);
          position: sticky; 
          padding: 12px;
          top: 0;
  
          > .btn { opacity: 1; pointer-events: all;}
        }
      }
  
      .copilot-prompts-item {
        border-radius: $border-radius-lg;
        @include hover() { color: $primary-3; .copilot-prompts-icon { background: $primary-1;}}
      }
    }
  
    &-header {
      border: 1px solid rgba(#000,.05);
      border-radius: $border-radius-lg;
      background: $neutral-2;
      transition: .5s linear;
      padding: 0;
      z-index: 1;
  
      .widget-search { flex: 1 0 0;}
      > .btn { transition: color .2s, background .2s, border-color .2s, opacity .5s; opacity: 0; pointer-events: none;}
    }
  
    &-body {
      position: absolute;
      overflow: auto;
      height: 100%;
      right: 0;
      left: 0;
      top: 0;
    }
  
    &-filters {position: fixed;}
    
    &-state {
      @include flexbox(column,center,center);
      text-align: center;
      min-height: 79%;
      width: 100%;
  
      .txt-title-small { max-width: 340px;}
    }
  
    &-copilot {
      @include flexbox(column,initial,initial);
      background: $neutral-0;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transform: translateX(100%);
      transition: transform 0.3s ease-in-out;
      z-index: 2;
      overflow: scroll;
  
      &-logo {
        transform: translate(-50%, -50%);
        margin-top: $margin-sm;
        position: absolute;
        left: 50%;
        top: 50%;
      }
  
      &.active { transform: translateX(0);}
  
      .side-panel-body {
        @include flexbox(column,initial,initial);
        overflow: auto;
        flex: 1 0 0;
      }
  
      .side-panel-library-cta {background: $neutral-0; border-radius: $border-radius-md;}
    }
  }
  
  
  