&-library {
  @include flexbox(row,flex-start,space-between);
  flex-wrap: wrap;
  margin: 0 (-$margin);
  padding: 0;

  .card-library { flex: calc(50% - 16px) 0 0;}
  .card-file {
    flex: calc(50% - 16px) 0 0;
    &-list { flex: 1 0 0; width: 100%;}
  }
  .card-simple { flex: calc(50% - 16px) 0 0;}
  .card-folder {
    flex: calc(50% - 16px) 0 0;
    &-list { flex: 1 0 0; width: 100%;}
  }

  &.list-view {
    flex-direction: column;
    margin: 0;
  }

  &-divider {
    border-radius: 100px;
    width: 24px;
    background: #fff;
    transform: rotate(90deg);
    opacity: 0.5;
  }

  &-count {
    @include flexbox(row,center,flex-start);
    padding: $padding-sm $padding-default;
    border-radius: $border-radius-lg;
    --corner-smoothing: 60%;
    background: $primary-3;
    color: $neutral-0;
    min-height: 60px;

    h4 {
      span {
        color: $primary-1;
      }
    }

    .custom-checkbox {
      .custom-control-input {
        + .custom-control-label {
          &:before {
            background: $primary-4 !important;
            border-color: $primary-4 !important;
          }
        }
      }
    }
  }

  &-cta {
    background: $neutral-1;
    position: sticky;
    margin: auto -32px 0;
    bottom: 0;
    z-index: 4;

    .bg-white & { background: $neutral-0;}
  }
}
