.card-account {
    @include flexbox(row,center,flex-start);
    border-radius: $border-radius-lg-2;
    padding: 13px $padding-default;
    box-shadow: $shadow-small;
    background: $neutral-0;
    margin-bottom: 1px;
    position: relative;
    min-height: 78px;
    cursor: pointer;
    transition: .2s;
    @include hover() { background: rgba($neutral-0,.5); box-shadow: $shadow-mid;}
}