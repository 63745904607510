.form-control-radius {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;

  > .form-control {
    border-radius: 0;
    background: $neutral-0;
    text-align: center;
    padding: $padding;
    height: 48px;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance: textfield;
    }
  }

  .btn-icon {
    background: $neutral-0;
    margin: -19px 0 0 -19px;
    border-radius: 20px;
    position: absolute;
    color: $neutral-3;
    min-height: 38px;
    height: 38px;
    width: 38px;
    padding: 0;
    left: 50%;
    top: 50%;

    @include hover() { color: $primary-3;}
    &.active { color: $primary-3;}
  }
}
