.select-option-list {

  .dropdown-menu {
    margin-top: 8px;
    outline: none;
    min-width: 250px;
    overflow: hidden;
    background: $neutral-0;
    border-radius: $border-radius-md;
    --corner-smoothing: 60%;
    box-shadow: $shadow-big;
    position: relative;
    display: block;
    border: 0;
    &--small { min-width: 140px;}
  }
  .dropdown-item {
    font-size: 14px;
    padding: 11px 18px;
    user-select: none;
    cursor: pointer;

    @include hover() { background: $neutral-1;}

    &:active {
      background: $primary-1;
      color: $primary-4;
    }
    &.text-warning {
      color: $red-3;
    }
  }
}
