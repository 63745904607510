// Side-panel-segment

&-segment {
  padding: $padding-default $padding-lg $padding-default $padding-default;
  @include flexbox(row,center,space-between);
  @extend .side-panel-widget;
  color: $neutral-6;
  transition: .2s;
  cursor: pointer;

  @include hover() { 
    background: $primary-1;
    color: $primary-4;
  }
  &-inner {
    @include flexbox(center);
    .icon-md {
      margin-right: 14px;
    }
    .side-panel-segment-content {
      @include flexbox(column);
      h3 {
        margin-bottom: initial;
      }
    }
  }
}