.card-widget {
  background: $neutral-0;
  width: 100%;
  border-radius: $border-radius-lg;
  box-shadow: $shadow-small;
  @include transition(.15s);
  .inner {
    padding: 12px;
    cursor: pointer;
    .slot-top {
      @include flexbox(row,flex-start,space-between);
      text-transform: capitalize;
      .slot-left {
        overflow: hidden;
        .txt-heading-small {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .slot-right {
        opacity: 0;
      }
    }
    .slot-bottom {
      @include flexbox(row,center,space-between);
      .slot-left {
        @include flexbox(row,center,flex-start);
        color: $neutral-3;
        div {
          @include flexbox(row,center,flex-start);
        }
      }
      .slot-right {
        color: $neutral-3;
      }
    }
  }
  @include hover() {
    background: $primary-1;
    color: $primary-3;
    box-shadow: $shadow-big;
    .slot-top {
      .slot-right {
        opacity: 1;
      }
    }
    .slot-bottom {
      .slot-right, .slot-left {
        color: $primary-3;
      }
    }
  }
}