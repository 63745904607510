&-chapter-row {
  @include flexbox(row, center, space-between);
  background: $neutral-0;
  margin-bottom: 1px;
  border-radius: $border-radius-sm;
  padding: 0 18px;
  .slot-left {
    @include flexbox(row, center, flex-start);
    width: 100%;
    span {
      margin-bottom: 0;
      flex: 0 0 32px;
    }
    .slot-inner {
      width: 100%;
      input {
        margin-bottom: 0;
        width: 100%;
        padding: 12px 0;
        &.with-actions {
          padding-right: 15px;
        }
      }
    }
    .error {
      color: $red-3 !important;
    }
  }
  .slot-right {
    @include flexbox(row, center, flex-end);
    .btn {
      padding: 6px;
      min-height: 0;
    }
    .chapter-close {
      color: $primary-4;
      &.disabled {
        pointer-events: none;
        color: $neutral-2;
      }
    }
    .chapter-time {
      @include flexbox(row, center, center);
      border: 1px solid $neutral-2;
      box-sizing: border-box;
      border-radius: $border-radius-md;
      padding: 7px;

      &.muted {
        // pointer-events: none;
        border-color: $neutral-2 !important;
        color: $neutral-2 !important;
        box-shadow: none !important;

        > input { color: $neutral-2 !important;}
      }

      input {
        max-width: 15px;
      }
      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }
      input[type="number" i] {
        padding: 0;
      }
      &.error {
        box-shadow: $shadow-hover-red;
        border-color: $red-3;
      }
    }
  }
  .show-pencil {display: none;}
  &:hover {
    box-shadow: $shadow-hover;
    border-color: $primary-4;
    color: $neutral-6;
    outline: 0;
    .slot-inner {
      ::placeholder {
        color: $primary-4;
      }
    }
    .slot-right {
      .chapter-time {
        box-shadow: $shadow-hover;
        border-color: $primary-3;
        color: $primary-3;
        input {
          color: $primary-3;
        }
      }
      .chapter-close {
        cursor: pointer;
      }
    }
    .show-pencil {
      display: inherit;
    }
  }
  &.error {
    box-shadow: $shadow-hover-red;
    border-color: $red-3;
    color: $red-3;
  }
}
