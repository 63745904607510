// Native bootstap partly

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $zindex-popover;
  display: block;
  max-width: 320px;

  ~ [id*="BV_modal_outer_"] {
    z-index: 1060 !important;
  }

  &-volume {
    transform: translate3d(0,-140%,0) rotate(-90deg) !important;
    transform-origin: center;
    bottom: 100% !important;
    right: auto !important;
    left: auto !important;
    top: auto !important;

    .popover-body {
      background: $neutral-0;
      box-shadow: $shadow-small;
      border-radius: $border-radius-md;
      padding: $padding 12px;
      margin: 0 10px;
    }

    .custom-range { width: 100px;}
  }

  &-widget {
    background: $neutral-0;
    border-radius: $border-radius-lg;
    box-shadow: $shadow-big;
    width: 350px;

    .side-panel-widget-group {
      border-top: 1px solid $neutral-2;
      overflow: auto;
      max-height: 330px;
      @include scrollbar-thin;
    }
  }
}
