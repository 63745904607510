// Sidepanel option page / extension of .side-panel

&-option-menu {
  @include flexbox(row,flex-start,flex-start);
  border-radius: $border-radius-lg;
  @include transition(.15s);
  box-shadow: $shadow-small;
  background: $neutral-0;
  padding: $padding $padding $padding ($padding + $padding-default);
  margin: 0 0 1px;
  cursor: pointer;
  position: relative;
  min-height: 48px;

  &.disabled {
    pointer-events: none;
    background: rgba($neutral-0, 0.5);
    box-shadow: none;
    color: $neutral-3 !important;
    cursor: initial;

    .slot-left {
      color: $neutral-3;
    }
  }

  &:not(.disabled) {
    @include hover() {
      background: $primary-1;
      color: $primary-3;

      .txt-muted { color: $primary-3;}
      .handle { color: $primary-3;}
    }

    &:focus-visible {
      background: $primary-1;
      color: $primary-4;

      .txt-muted { color: $primary-3;}
      .handle { color: $primary-3;}
      outline: none;
    }
  }

  .handle {
    @include inline-flexbox(row,center,center);
    @include transition(.15s);
    min-height: 32px;
    color: $neutral-3;
    position: absolute;
    cursor: grab;
    left: 3px;

    @include hover() {
      color: $primary-4;
    }
    + .slot-left { min-width: 0; flex: auto 0 0;}
    + .slot-mid { margin-left: 4px;}
  }

  .slot-left {
    @include flexbox(row,center,center);
    margin: 0 $margin 0 0;
    padding: 0;
    min-height: 32px;
    min-width: 32px;
    color: $primary-4;

    &:empty { display: none;}
  }

  .slot-right {
    @include inline-flexbox(row,center,flex-end);
    padding: 0 $padding-sm 0 $padding-default;
    margin: 0 0 0 auto;
    min-height: 32px;
  }

  .slot-mid {
    margin: 3px 0;
    .txt-heading-small {
      min-height: 26px;
      line-height: 26px;
    }
  }

  .option-tooltip {
    position: absolute;
    right: 5px;
    bottom: 5px;
    z-index: 10;
  }

  .txt-heading-small {
    margin-bottom: 0;
    word-break: break-word;
  }

  // If can be hovered have dropdown on hover

  @media (hover: hover) {

    .popover-toggle {
      margin-right: -$margin-sm;
      margin-left: $margin-sm;
      display: none;
      order: 2;

      &[aria-describedby] {
        display: inline-flex;

        + svg { display: none;}
      }
    }

    &:hover {
      .popover-toggle {
        display: inline-flex;
        + svg { display: none;}
      }
    }
  }

  // Variant muted left icon

  &.variant-secondary {
    .slot-left {
      color: $neutral-3;
    }
  }
}
.hidden {
  color: $neutral-3;
  background: $neutral-0;
  &:hover {
    background: $primary-1;
    .slot-right, svg {color: $primary-3 !important;}
  }
  .handle {
    color: $neutral-3;
  }
}
