@import "~ag-grid-community/src/styles/ag-grid.scss";

//Audience table plugin theme
.ag-overlay-no-rows-center {
  display: none;
}

.ag {
  &-layout-auto-height {
    .audience-table & { overflow: unset;}
  }
  &-root-wrapper {
    .audience-table & { overflow: unset;}
  }

  &-row {
    @include transition(.2s);
    //border-bottom: 1px solid $neutral-2;

    &:after {
      content: '';
      display: block;
      clear: both;
      height: 1px;
      position: absolute;
      background: $neutral-2;
      pointer-events: none;
      bottom: 0;
      left: 8px;
      right: 8px;
    }

    &:before {
      content:'';
      background: $neutral-0;
      border-radius: $border-radius-lg;
      pointer-events: none;
      position: absolute;
      transition: .2s;
      bottom: 1px;
      right: 0;
      left: 0;
      top: 0;
    }

    &-hover:not(.ag-row-inactive) {
      cursor: pointer;
      .ag-cell { color: $primary-3;}
      .txt-muted,.txt-dark { color: $primary-3 !important;}
      &:before { background: $primary-1;}
    }

    &-selected:not(.ag-row-inactive) {
      cursor: pointer;
      .ag-cell { color: $primary-3;}
      .txt-muted,.txt-dark { color: $primary-3 !important;}
      &:before { background: $primary-1;}

      &:has(+ .ag-row-selected){
        &:before { border-bottom-left-radius: 0; border-bottom-right-radius: 0; bottom: 0;}
      }
      .ag-row-selected + & {
        &:before { border-top-left-radius: 0; border-top-right-radius: 0;}
      }
      &.ag-row-hover:before { opacity: .5;}
    }

    &-inactive {
      .ag-cell { color: $neutral-2; }
      .txt-muted,.txt-dark { color: inherit !important;}
    }

    @at-root .ag-table:not(.row-focus-disabled) .ag-row-focus {
      .ag-cell { color: $primary-3;}
      .txt-muted,.txt-dark { color: $primary-3 !important;}
      &:before { background: $primary-1;}
    }
    @at-root .ag-table:not(.row-hover-disabled) .ag-row-hover {
      .ag-cell { color: $primary-3;}
      .txt-muted,.txt-dark { color: $primary-3 !important;}
      &:before { background: $primary-1;}
    }

    //@include hover() {
    //  background: $primary-1;

    //  .ag-cell { color: $primary-3;}
    //  .txt-muted,.txt-dark { color: inherit !important;}
    //}
  }

  &-cell {
    @include transition(.15s);
    @include flexbox(row,center,flex-start);
    padding: $padding-sm $padding-default;
    font-size: $txt-size-1;
    color: $neutral-6;
    outline: none;

    &-details {
      white-space: initial;
      padding: 20px 0;
      @include inline-flexbox(row, flex-start, initial);
      &.fx-row-center {@include inline-flexbox(row,center,initial);}
      
      &-icon {
        margin-right: 12px;
        flex: 0 0 24px;
      }
      &-text {
        @include inline-flexbox(column, flex-start, initial);
        position: relative;
        padding-right: 50px;
        //max-width: 350px;
        width: 100%;

        @include media-breakpoint-down(xl) { padding-right: 30px;}
        @include media-breakpoint-down(lg) { padding-right: 10px;}

        .txt-heading-small,
        .txt-body {
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-inline-box;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
        }

        .tooltip {
          position: absolute;
          bottom: 100%;
          left: -10px;

          .arrow { left: 10px;}

          &-inner {
            text-align: left;
            max-width: 30vw;
          }
        }
      }
    }
  }

  &-header {
    border-bottom: 1px solid $neutral-2;
    background: $neutral-0;

    .audience-table & {
      background: rgba(255, 255, 255, 1) !important;
      //backdrop-filter: blur(8px);
      position: sticky;
      z-index: 2;
      top: 148px;

      .with-topbar & { top: 208px;}
    }

    &-cell {
      &-inner {
        @include flexbox(row,center,flex-start);
        padding: 0 0 0 $padding-default;
        font-size: $txt-size-1;
        color: $neutral-4;
        min-height: 48px;
        width: 100%;

        .btn-icon {
          color: $neutral-3;
          opacity: .5;

          @include hover() {
            opacity: 1;
          }
        }
        .customSortDownLabel {
          margin-top: 5px;
        }
      }
      &-label {
        @include transition(.15s);
        &:empty { display: none;}
        opacity: .5;
        flex: initial;
        @include hover() {
          opacity: 1;
        }
      }
    }
  }

  &-center-cols-clipper {
    min-height: unset !important;
  }

  &-username {
    @include flexbox(row,center,flex-start);
    .avatar {
      margin-right: 8px;
      min-width: 46px;
      height: 46px;

      > img { border: 1px solid rgba(#7B877E, .1);}
    }
    > figcaption {
      max-width: calc(100% - 50);
    }
    .txt-heading-small {
      //@include transition(.15s);
      text-overflow: ellipsis;
      margin-bottom: 0;
      display: block;
      overflow: hidden;
      max-width: 100%;
    }
  }
}

.ag-selection-checkbox {
  position: relative;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  min-width: 18px;
  min-height: 18px;
  outline: none;

  .ag-icon {
    @include inline-flexbox(row,center,center);
    border-radius: $border-radius-sm;
    border: 1px solid transparent;
    @include transition(.15s);
    box-shadow: none;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;

    @include hover() {
      box-shadow: $shadow-hover;
      border-color: $primary-3;
    }

    &-checkbox-checked {
      border-color: $primary-3;
      background: $primary-3;
      color: $neutral-0;

      &:after {
        content: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.33334 7L5.83334 11.0833L11.6667 3.5' stroke='white' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");        
        @include transition(.15s);
        display: inline-flex;
        overflow: hidden;
        height: 14px;
        z-index: 2;
      }
    }

    &-checkbox-unchecked {
      background: rgba($neutral-0, .01);
      border-color: $neutral-2;
    }
  }

  + .ag-cell-value { display: none;}
}

.table {
  &-bulk {
    padding: $padding-sm 0;
    @include flexbox(row,center,initial);
    border-bottom: 1px solid $neutral-1;
    @include transition(.15s);
    background: $neutral-0;
    min-height: 60px;
    position: sticky;
    z-index: 2;
    top: 88px;

    .top-sticky-blur & {
      background: none;
      margin-left: -8px;
      margin-right: -8px;
      padding: $padding-sm 16px;
      border-radius: $border-radius-lg;
    }

    .audience-table-head & { border: none;}

    &.empty {
      border-bottom: none;
    }

    &.disabled {
      pointer-events: none;
    }

    &.selected {
      background: $primary-3;
      color: $neutral-0;

      .custom-checkbox .custom-control-input:indeterminate + .custom-control-label:before { background: $primary-4; border-color: $primary-4;}
      .txt-heading-small span { color: $primary-2;}
    }

    .slot-right {
      @include inline-flexbox(row,center,flex-end);
      margin-left: auto;
    }

    .search-form {
      margin-right: -48px;
      position: relative;

      input {
        padding-right: 48px;
        padding-left: 48px;
        width: 274px;
      }

      .btn {
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    &-search {
      @include flexbox(row,initial,initial);

      > .btn { z-index: 2;}
    }
  }
}
