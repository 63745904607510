.upgrade-promo {
  padding-top: 100px;
  min-height: 100vh;
  p {
    max-width: 700px;
    @at-root .audience-body .main .upgrade-promo p {max-width: 640px;}
  }
  h1 {
    position: relative;
    span {
      background: linear-gradient(103.28deg, #2681E3 -2.88%, #AEB574 108.85%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      @at-root .audience-body .main .upgrade-promo h1 span {
        background: linear-gradient(93deg, #CF5945 0.6%, #FFA844 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .stars {
      position: absolute;
      top: -37px;
      left: -58px;
      @at-root .audience-body .main .upgrade-promo h1 .stars {
        transform: scaleX(-1);
        left: 955px;
        top: -40px;
      }
    }
    .hand {
      position: absolute;
      bottom: -65px;
      right: -76px;
      @at-root .audience-body .main .upgrade-promo .hand {display: none;}
    }
  }
  &-buttons {
    position: relative;
    .arrow {
      position: absolute;
      top: 20px;
      left: -123px;
    }
    button svg {
      font-weight: 700;
    }
  }
  &-image {
    max-width: 1200px;
    width: 100%;
    // margin-top: 40px;
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    margin-top: 80px;
    @at-root .audience-body .app .main .upgrade-promo .upgrade-promo-image  {margin-top: 50px;}
    img {
      width: 100%;
      height: 100%;
    }
    .avatar-top {
      width: 23px;
      height: 23px;
      position: absolute;
      top: 40px;
      left: calc(50% - 50px);
    }
  }
}