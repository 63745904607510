.pagination {
  @include flexbox(row,center,center);
  padding: $padding-default;
  background: $neutral-0;
  white-space: nowrap;

  .account-settings & {
    @include media-breakpoint-down(xl) { padding: $padding-default 0;}
  }

  &-inner {
    @include flexbox(row,center,center);
    flex-wrap: wrap;
    margin: 0 auto;
  }

  .btn {
    min-width: 48px;
    margin: 0 $margin-sm;
    @include media-breakpoint-down(xl) { margin: 0;}

    > .page-link {
      @include flexbox(row,center,center);
      color: inherit;
      > svg { color: inherit;}
    }
  }
}
