.library {
  
  &-top {
    .wrapper-lg {
      @include flexbox(row,center,initial);  
      padding-top: $padding-default;
      padding-bottom: $padding;
    }
    
    .btn.disabled {
      background: $neutral-2 !important;
      color: $neutral-3 !important;
      border-color: transparent;
    }
    .widget-search {background: $neutral-2;}

    &:has(~.library-listing-search) { &:after { display: none;}}
  }

  &-accordion {

    &:last-child { margin-bottom: 0;}

    &-header {
      padding: $padding-lg 0;
      transition: padding .2s;

      .library-accordion.open & { padding: $padding-lg 0 $padding-sm;}

      > svg.ml-auto {
        margin: 12px 12px 12px auto;
      }
      .btn-link {
        color: $neutral-6;

        @include hover() {
          color: $primary-4;
        }
      }

      .btn.disabled {
        &.btn-primary { background: $neutral-2 !important;}
        color: $neutral-3 !important;
        border-color: transparent;
      }
    }

    &-body {
      margin-left: -$margin-default;
      margin-right: -$margin-default;
      padding-left: $padding-default;
      padding-right: $padding-default;
      max-height: calc(100svh + 54px);
      scroll-behavior: smooth;
      margin-top: -300px;
      padding-top: 300px;
      overflow-y: auto;

      .with-topbar & {
        max-height: calc(100svh - 6px);
        .top-sticky-blur { top: 0;}
      }
    }

    + .library-accordion { 
      position: relative;

      &:before {
        content: "";
        background: $neutral-2;
        pointer-events: none;
        position: absolute;
        display: block;
        z-index: 6;
        height: 1px;
        right: -16px;
        left: -16px;
        top: 0;
      }
    }
  }

  &-listing {
    border-left: 1px solid $neutral-2;
    min-height: 100vh;
    position: relative;
    .count {
      @include inline-flexbox(row,center,center);
      min-width: 32px;
      padding: 0 $padding;
      border-radius: $border-radius-md;
      background: $primary-4;
      color: $neutral-0;
      font-weight: 700;
      height: 32px;
    }

    &-search {
      .nav-tabs-search { max-width: 520px;}
      .top-sticky-blur {
        transition: top .2s;
        top: 80px;
        
        .side-panel-library-count-container:not(.slide-down-leave-active) ~ & { top: 140px;}
      }
    }
    //.library-header {padding-bottom: 0;}
    .side-panel-library-count {
      margin-top: $margin-default;

      &-container {
        padding-left: $padding-default;
        padding-right: $padding-default;
        max-width: 1432px;
        position: sticky;
        overflow: hidden;
        margin: 0 auto;
        z-index: 4;
        top: 80px;

        .with-topbar & { top: 80px;}
        &:has(~.library-listing-search) { top: 80px; padding-bottom: 8px;}

        @at-root .library-listing .top-sticky-blur + .side-panel-library-count-container { top: 140px;}
        @at-root .library-listing .top-sticky-blur + .side-panel-library-count-container .side-panel-library-count { margin-top: 0;}
        @at-root .library-listing.library-listing--search .side-panel-library-count-container { top: 80px;}
        @at-root .library-listing.library-listing--search .side-panel-library-count-container .side-panel-library-count { margin-top: 0;}
      }
    }
  }

  &-header {
    padding-bottom: 0;
    margin-top: $padding-default;

    &:has(.wrapper-lg:empty) { margin-top: 0; padding-bottom: 0; &::after { display: none;}}

    .library-top ~ & { top: 80px; .with-topbar & { top: 80px;}}
    &:after { display: none;}

    .btn.disabled {
      background: $neutral-2 !important;
      color: $neutral-3 !important;
      border-color: transparent;
    }
  }

  &-files {
    @include flexbox(row,flex-start,flex-start);
    gap: $padding-lg $padding-default;
    padding-bottom: $padding-default;
    padding-top: $padding-default;
    position: relative;
    flex-wrap: wrap;

    &:has(> .card-file-list) { gap: $padding $padding-default;}
    [item-view="list"] & { gap: $padding $padding-default;}

    .card-file {
      width: calc(25% - 12px);
      margin: 0;

      @media (max-width: 1700px) { width: calc(33.3333% - 10.67px);}
      @media (max-width: 1300px) { width: calc(50% - 8px);}

      &-list { width: 100%;}
    }
    .infinite-loading-container {
      animation: fade-load-in .3s forwards;
      &:not(:only-child) { position: absolute; top: 100%; margin-top: -100%; width: 100%;}
    }
  }

  &-search {

    .search-form {
      margin-right: -50px;
      position: relative;

      input {
        padding-right: 48px;
        padding-left: 48px;
        width: 274px;
      }

      .btn {
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    &-search {
      @include flexbox(row,initial,initial);

      > .btn { z-index: 2;}
    }
  }

  &-empty {
    min-height: calc(100vh - 220px);
    @include flexbox(column,center,center);
    text-align: center;
    padding: $padding-default;
  }

  &-tab {
    &-header {
      @include flexbox(row,center,space-between);
      margin-bottom: $margin-default;
      padding: 0 $padding-default;
    }
    &-list {
      padding: 0 $padding;
    }
  }
}

/// Library Filters Dialog

.library-filter {
  &:before { right: $side-modal-width-sm !important;}
  &-inner { padding: $padding-default 32px $padding-lg;}
  .side-panel-option { padding-bottom: 20px;}

  &-grid {
    @include flexbox(row,initial,initial);
    padding: $padding-lg 32px;
    margin: 0 -4px;
    flex-wrap: wrap;

    > .btn, > .custom-control {
      margin: 0 $margin-sm $margin-default;
      flex: calc(50% - 8px) 0 0;
    }
  }

  &-list {
    @include flexbox(column,initial,initial);
    padding: $padding-lg 32px;
    margin: 0 -4px;
    flex-wrap: wrap;

    > .btn, > .custom-control {
      margin: 0 $margin-sm $margin-default;
      flex: calc(100% - 8px) 0 0;
    }
  }

  .modal-body { padding: 0;}
}

.side-panel-file,
.side-panel-playlist {
  > .top-sticky-blur {
    min-height: 56px;
    top: 64px;
    ~ .top-sticky-blur { top: 120px;}
    &:has(.side-panel-metadata) ~ .top-sticky-blur { top: 154px;}
  }
}