//Shadow small

$shadow-small: 0px 4px 8px 0px rgba(#000,.05), 0px 1px 1px 0px rgba(#000,.1);
$shadow-big: 0 4px 8px 0 rgba(#000,.1), 0 16px 32px 0 rgba(#000,.1);
$shadow-mid: 0 8px 16px 0 rgba(#000,.1), 0 2px 1px 0 rgba(#000,.05);
$shadow-xl: 0 0 32px 0 rgba(#000,.2);

$shadow-hover: 0 0 0 4px $primary-4-transparent;
$shadow-hover-red: 0 0 0 4px rgba(#C12626,.15);
$shadow-hover-light: 0px 0px 0px 4px rgba($primary-4, .1);
$shadow-hover-dark: 0 0 0 4px rgba(#000,.15);
$shadow-hover-success: 0 0 0 4px rgba(#117C63,.2);
$shadow-hover-orange: 0 0 0 4px rgba(#F99540,.2);
$shadow-tooltip: 0 0 16px 0 rgba(#000,.1), 0 4px 8px 0 rgba(#000,.1), 0 16px 32px 0 rgba(#000,.1);
$shadow-canva:  0 0 0 4px rgba(0, 196, 204, 0.2)

