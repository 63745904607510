.uploader {

  &-progress {
    .progress {
      background: rgba($neutral-3,.5);
      display: flex;
      border-radius: $border-radius-md;
      height: 4px;
      overflow: hidden;
      perspective: 1px;
      margin-bottom: $margin;

      &-bar {
        display: inline-block;
        border-radius: $border-radius-md;
        height: 4px;
        overflow: hidden;
        background: $primary-4;
      }
    }

    &-info {
      @include flexbox(row,center,initial);
      margin-bottom: $margin-lg;
    }
  }

  &-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    background: #333;
    opacity: 0;
  }
  &-widget {
    position: relative;
    background: $neutral-0;
    height: auto;
    padding: $padding-lg;
    box-shadow: $shadow-small;
    border-radius: $border-radius-lg;
    @include flexbox(column,center,center);
    text-align: center;
    .upload-folder-select {
      &:hover {
        box-shadow: 0 0 0 4px rgba($primary-4,.2);
        border-radius: $border-radius-md;
        background: $neutral-0;
        cursor: pointer;
        transition: 0.3s;
        .btn-right {color: $primary-3;}
        .dd-search-input {
          z-index: 3;
          position: relative;
        }
        .bl-1 {
          border: 1px solid $primary-3;
        }
      }
      .open {
        >svg {transform: rotate(180deg);}
      }
    }
    .open {
      .dd-search-inner {
        position: sticky;
        z-index: 2;
        top: 0;
        background: $neutral-0;
      }
      .dd-search-input {
        &:hover {
          box-shadow: 0 0 0 4px rgba($primary-3,.2);
          border-radius: $border-radius-md;
          border: 1px solid $primary-3;
        }
      }
      .upload-folder-select {
        z-index: 1;
        position: relative;
        box-shadow: 0 0 0 4px rgba($primary-3,.2);
        border-radius: $border-radius-md;
        &:hover {
          border: none;
          .btn-right {color: initial;}
        }
      }
    }
  }
  &-drag {
    @include flexbox(column,center,flex-start);
    border-radius: $border-radius-lg;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23E4E4E4FF' stroke-width='2' stroke-dasharray='3%2c 6' stroke-dashoffset='9' stroke-linecap='round'/%3e%3c/svg%3e");
    padding: $padding-default;
    position: relative;
    min-height: 372px;
    width: 100%;
    &:hover {
      cursor: pointer;
      box-shadow: 0 0 0 4px rgba($primary-3,.2);
      border-radius: $border-radius-md;
      transition: 0.3s;
      background-image:  url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23214C47' stroke-width='2' stroke-dasharray='3%2c 6' stroke-dashoffset='9' stroke-linecap='round'/%3e%3c/svg%3e");
    }
    &.isOpened {
      box-shadow: none;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23E4E4E4FF' stroke-width='2' stroke-dasharray='3%2c 6' stroke-dashoffset='9' stroke-linecap='round'/%3e%3c/svg%3e");
    }
  }
  &-alerts {
    width: 100%;
  }
  &-files {
    margin-bottom: auto;
    width: 100%;
    .uploader-item {
      z-index: 3;
    }
  }
  &-info {
    margin: auto $margin-default;
    padding: $padding-lg 0;
    @include flexbox(column,center,initial);
  }
  &-types {
    display: flex;
    justify-content: center;
    margin-bottom: 29px;
    div {
      border-radius: $border-radius-md;
      background: $neutral-1;
      margin-right: 6px;
      font-weight: bold;
      color: $neutral-6;
      padding: 7px 8px;
    }
  }

  &-item {
    @include flexbox(row,center,flex-start);
    @include transition(.15s);
    border: 1px solid $neutral-2;
    border-radius: $border-radius-lg;
    margin-bottom: $margin;
    white-space: nowrap;
    padding: 0 6px 0 $padding-sm;
    position: relative;
    height: 48px;

    @include hover() {
      box-shadow: $shadow-hover;
      border-color: $primary-3;
      .show-pencil {display: inherit;}
    }

    &.focused {
      box-shadow: $shadow-hover;
      border-color: $primary-3;
    }

    &-percent {
      @include inline-flexbox(row,center,center);
      min-width: 36px;
      font-size: $txt-size-1;
      color: $neutral-3;
    }

    &-handle {
      @include transition(.15s);
      cursor: grab;
      color: $neutral-3;

      @include hover() {
        color: $neutral-4;
      }
    }

    &-title {
      text-align: left;
      min-width: 0;
      flex: 1 0 0;

      .side-panel-description__content {
        border-color: transparent !important;
        padding: 0 4px !important;
        line-height: 38px;
        color: $neutral-6;
        min-height: 38px;
        overflow: auto;
        margin: auto 0;
        height: 38px;

        @include hover() {
          background: transparent;
          border-color: transparent;
        }
      }

      .side-panel-description-actions {
        border-radius: 0 $border-radius-sm $border-radius-sm 0;
        padding-right: 4px;
        align-items: center;
        padding-top: 0;
        bottom: 2px;
        right: 1px;
        top: 2px;

        + .side-panel-description__content {
          padding-right: 66px !important;
          //border-color: $neutral-2;
          background: transparent;
        }

        .btn-sm {
          margin-left: 4px !important;
          width: 26px;
          height: 26px;
          min-height: 26px;
          padding: 0;
        }
      }

      &.initial {
        margin-right: 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 0;

        + .uploader-item-right {
          position: relative;
          right: auto;
          top: auto;
          bottom: auto;
          padding: 0;
        }
      }
      h5 {
        &.ProseMirror {
          border-radius: $border-radius-md;
          padding-left: 6px;
          padding-right: 72px;
          padding-top: 6px;
          line-height: 1.64;
          font-size: 1.7rem;
          color: $neutral-6;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          height: 40px;
          outline: none;

          &:focus { border-color: #D7E0D8; color: $neutral-6; margin-right: 66px; overflow: auto; text-overflow: initial; &:hover { border-color: #D7E0D8 !important;}}

          @include hover() {
            background: transparent !important;
            border-color: transparent !important;
          }
        }
      }
    }

    &-right {
      border-radius: 0 $border-radius-lg $border-radius-lg 0;
      @include flexbox(row,center,flex-start);
      background: $neutral-0;
      padding: 0 $padding-sm 0 $padding;
      position: relative;
      z-index: 4;
      bottom: 0;
      right: 0;
      top: 0;
      .show-pencil {display: none;}
    }

    > svg { min-width: 24px;}

    .side-panel-description-action { bottom: 0;}
  }

  &-toast {
    // transform: translate3d(0, 400%, 0);
    padding: $padding-default $padding-default $padding-lg;
    transition: 0.4s;
    position: fixed;
    z-index: 9999;
    /* visibility: hidden; */
    /* right: 30px; */
    /* bottom: 120px; */
    /* opacity: 0; */
    @include flexbox(row,initial,initial);
    background: $neutral-6;
    box-shadow: $shadow-tooltip;
    border-radius: $border-radius-lg;
    color: $neutral-0;
    right: 16px;
    bottom: 16px;
    width: 380px;
    height: 116px;
    min-width: 380px;
  }
  &-toast-info {
    @include flexbox(column,initial,space-between);
    flex: 1 0 0;

    &-progress {
      position: relative;
      height: 4px;
      border-radius: $border-radius-md;
      perspective: 1px;
      overflow: hidden;
      background: $neutral-0;

      &-bg {
        position: relative;
        height: 4px;
        background: #8C8C8C;
        border-radius: 5px;
        margin-top: 14px;
        margin-bottom: $margin-default;
      }
    }
    &-bottom {
      display: flex;
      justify-content: space-between;
    }
  }
}
