.color-picker {
  border-radius: $border-radius-md;
  border: 1px solid $neutral-2;
  @include transition(.15s);
  background: $neutral-0;
  box-shadow: none;

  @include hover() {
    box-shadow: $shadow-hover;
    border-color: $primary-4;

    .btn.btn-icon {
      background: $primary-1;
      color: $primary-4;
    }
  }

  &.disabled {
    border-color: $neutral-2;
    background: $neutral-1;
    pointer-events: none;
    color: $neutral-3;

    .btn-icon { color: inherit;}
  }

  .inner {
    padding: $padding;
    @include flexbox(row,center,flex-start);
  }
  .preview {
    border-radius: $border-radius-sm;
    box-shadow: inset 0px 0px 0px 2px rgba(0, 0, 0, 0.15);
    margin-right: $margin;
    min-width: 32px;
    height: 32px;
  }
}
