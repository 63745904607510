.avatar {
  @include flexbox(row,center,center);
  @include transition(.15s);
  border-radius: 50%;
  position: relative;
  box-shadow: none;
  width: 40px;
  height: 40px;
  border: 2px solid transparent;

  &-sm { width: 24px; height: 24px; min-width: 24px;}

  & > img {
    border: 2px solid transparent;
  }

  &-img,>img {
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    overflow: hidden;
    height: 100%;
    width: 100%;
  }

  &-placeholder {
    @include flexbox(row,center,center);
    background: $primary-1;
    border-radius: 50%;
    color: $neutral-4;
    overflow: hidden;
    height: 100%;
    width: 100%;

    &-light {
      background: $neutral-1;
      color: $neutral-6;
    }
    &.avatar-icon {
      border-radius: 25%;
      width: 40px;
      height: 40px;
      border: 2px solid transparent;
      @include hover() { 
        border-color: rgba($primary-4, .2);
      }
    }
  }

  &-badge {
    @include flexbox(row,center,center);
    border-radius: $border-radius-lg;
    background: $neutral-6;
    position: absolute;
    color: $neutral-0;
    height: 24px;
    right: -10px;
    width: 24px;
    top: -10px;

    .icon-sm { width: 16px;}
  }
  &:not(:has(.avatar-icon)) {
    @include hover() {
      border-color: rgba($primary-4, .2);
      & > img {
        border-color: white;
      }
    }
  }
}

// Avatar groups
.b-avatar-group {
	.b-avatar {
		border: 1px solid $neutral-0;
    background: $neutral-1;
		@include transition(.15s);

		&.rounded { border-radius: 50%;}

		.bi-person-fill {
			color: $neutral-3;
		}
	}
}
