.top-bar {
  @include flexbox(row,center,flex-start);
  padding: 0 $padding 0 $padding-default;
  background: $primary-4;
  color: $neutral-0;
  position: fixed;
  height: 60px;
  z-index: 1039;
  left: 64px;
  right: 0;
  top: 0;
}
